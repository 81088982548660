import { Colors } from "@config/colors";
import { Picker } from "@react-native-picker/picker";
import {
  CheckIcon,
  FormControl,
  Select,
  WarningOutlineIcon,
} from "native-base";
import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet } from "react-native";

type Props = {
  label: string;
  options: string[];
  onValueChange: (text: string) => void;
  selectedValue: string;
  validationFn: (value: string) => boolean;
  errorMessage: string;
  hasSubmited: boolean;
  isRequired?: boolean;
};

const DECPicker: React.FC<Props> = (props) => {
  const {
    label,
    options,
    onValueChange,
    selectedValue,
    validationFn,
    errorMessage,
    hasSubmited,
    isRequired,
  } = props;
  const [isOpened, setIsOpened] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);

  const handleValueChange = (value: string) => {
    setIsInvalid(!validationFn(value));
    onValueChange(value);
  };

  useEffect(() => {
    setIsInvalid(!validationFn(selectedValue) && (isTouched || hasSubmited));
  }, [isTouched, hasSubmited]);
  return (
    <FormControl
      w="3/4"
      maxW="300"
      isRequired={isRequired}
      isInvalid={isInvalid}
    >
      <FormControl.Label isInvalid={isInvalid}>{label}</FormControl.Label>
      <Select
        onValueChange={handleValueChange}
        selectedValue={selectedValue}
        onClose={() => setIsOpened(true)}
        onPointerLeave={() => setIsTouched(isOpened)}
        minWidth="200"
        accessibilityLabel="Categorie"
        placeholder="Categorie"
        _selectedItem={{
          bg: "teal.600",
          endIcon: <CheckIcon size={5} />,
        }}
        mt="1"
      >
        {options.map((option) => (
          <Select.Item label={option} value={option} />
        ))}
      </Select>
      <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
        {errorMessage}
      </FormControl.ErrorMessage>
    </FormControl>
  );

  return (
    <View style={styles.inputContainer}>
      <Text style={[styles.label, isInvalid && styles.labelInvalid]}>
        {label}
      </Text>
      <Picker
        onValueChange={handleValueChange}
        selectedValue={selectedValue}
        onFocus={() => setIsOpened(true)}
        onPointerLeave={() => setIsTouched(isOpened)}
      >
        <Picker.Item label="Alege o categorie" value="" />
        {options.map((option) => (
          <Picker.Item key={option} label={option} value={option} />
        ))}
      </Picker>
      {isInvalid && <Text style={styles.errorMessage}>{errorMessage}</Text>}
    </View>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    marginVertical: 8,
  },
  label: {
    color: "white",
    marginBottom: 4,
  },
  labelInvalid: {
    color: Colors.error,
  },
  errorMessage: {
    color: Colors.error,
  },
  // input: {
  //   paddingVertical: 8,
  //   paddingHorizontal: 6,
  //   borderRadius: 4,
  //   fontSize: 16,
  //   backgroundColor: Colors.backgroundPrimary,
  //   color: Colors.darkNeutral,
  // },
  // inputInvalid: {
  //   backgroundColor: Colors.error100,
  // },
});

export default DECPicker;
