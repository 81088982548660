import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import React from "react";
import { View, Text, StyleSheet, Pressable, Image } from "react-native";

type Props = {
  currentChoice: null | number;
  hasAnswered: boolean;
  isLoading: boolean;
  canSkipQuestion?: boolean;
  handleRemoveCurrentAnswerChoice: () => void;
  handleConfirmAnswerPress: () => void;
  handleNextQuestion: () => void;
  handleSkipQuestion: () => void;
};

const TestActions: React.FC<Props> = (props) => {
  const {
    currentChoice,
    hasAnswered,
    isLoading,
    canSkipQuestion = true,
  } = props;
  return (
    <View style={styles.actions}>
      {!hasAnswered && currentChoice && (
        <>
          <Pressable
            disabled={isLoading}
            style={[
              styles.actionButton,
              {
                borderRightColor: Colors.backgroundPrimary,
                borderRightWidth: 1,
              },
            ]}
            onPress={props.handleRemoveCurrentAnswerChoice}
          >
            <Image
              source={require("@assets/icons/cancel.png")}
              style={{ width: 24, height: 24 }}
            />
            <Text style={styles.actionButtonText}>Sterge</Text>
          </Pressable>
          <Pressable
            disabled={isLoading}
            style={styles.actionButton}
            onPress={props.handleConfirmAnswerPress}
          >
            <Image
              source={require("@assets/icons/checked.png")}
              style={{ width: 24, height: 24 }}
            />
            <Text style={styles.actionButtonText}>Confirma</Text>
          </Pressable>
        </>
      )}
      {!hasAnswered && !currentChoice && (
        <>
          <Pressable
            disabled={isLoading || !canSkipQuestion}
            style={[
              styles.actionButton,
              {
                borderRightColor: Colors.backgroundPrimary,
                borderRightWidth: 1,
              },
            ]}
            onPress={props.handleSkipQuestion}
          >
            <Image
              source={require("@assets/icons/skip.png")}
              style={{ width: 24, height: 24 }}
            />
            <Text style={styles.actionButtonText}>
              {canSkipQuestion ? "Raspunde mai tarziu" : "Alege un raspuns..."}
            </Text>
          </Pressable>
        </>
      )}
      {hasAnswered && (
        <Pressable
          disabled={isLoading}
          style={styles.actionButton}
          onPress={props.handleNextQuestion}
        >
          <Text style={styles.actionButtonText}>{"Urmatoarea intrebare"}</Text>
          <Image
            source={require("@assets/icons/arrow.png")}
            style={{
              width: 24,
              height: 24,
              position: "absolute",
              end: 0,
              marginRight: UI.sizes.margin,
            }}
          />
        </Pressable>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  actions: {
    flexDirection: "row",
    position: "absolute",
    bottom: 0,
    height: "10%",
    maxHeight: 90,
    width: "100%",
  },
  actionButton: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: Colors.backgroundSecondary,
    justifyContent: "center",
    alignItems: "center",
  },
  actionButtonText: {
    color: "#FFF",
    marginHorizontal: 8,
    fontWeight: UI.font.weight.bold,
  },
});

export default TestActions;
