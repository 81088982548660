import { createAsyncThunk, current } from "@reduxjs/toolkit";
import authApi from "../../services/auth/authApi";
import {
  ApiResponse,
  AuthData,
  OtpVerifyResponse,
  SignupRequestProps,
} from "../../types";
import {
  getAuthData,
  removeStoredAuthData,
  storeAuthData,
} from "../../services/auth/authLocal";
import {
  isAuthDataValid,
  isUserDataValid,
} from "../../utils/checkAuthDataValidity";
import { AppDispatch, RootState } from "@store/index";
import { api } from "@services/api";
import { transformKeysToCamelCase } from "@Core/utils/transfomObjectKeys";
import { smsOtpApi } from "@services/api/smsOtp/smsOtpApi";
import { Otp } from "@services/api/smsOtp/types";
import { server } from "../../../../../metro.config";

export const loginWithPassword = createAsyncThunk(
  "auth/loginWithPassword",
  async (credentials: { email: string; password: string }, thunkAPI) => {
    try {
      const response = await authApi.loginRequest(
        credentials.email,
        credentials.password
      );
      if (response.hasOwnProperty("errors")) {
        return thunkAPI.rejectWithValue(response.errors);
      }
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

export const signupWithPassword = createAsyncThunk<
  ApiResponse<AuthData>,
  SignupRequestProps,
  { state: RootState; dispatch: AppDispatch }
>(
  "auth/signupWithPassword",
  async (
    credentials: {
      email: string;
      password: string;
      firstName: string;
      lastName: string;
      phoneNumber: string;
    },
    thunkAPI
  ) => {
    const state = thunkAPI.getState();
    try {
      const response = await authApi.signupRequest({
        email: credentials.email,
        password: credentials.password,
        firstName: credentials.firstName,
        lastName: credentials.lastName,
        phoneNumber: credentials.phoneNumber,
        role: `pending_${state.role.role}`,
      });
      if (response.hasOwnProperty("errors")) {
        return thunkAPI.rejectWithValue(
          transformKeysToCamelCase(response.errors)
        );
      }
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

export const verifyStoredData = createAsyncThunk(
  "auth/verifyStoredData",
  async (_, thunkAPI) => {
    try {
      const storedAuthData = await getAuthData();
      const validState = isAuthDataValid(storedAuthData?.auth);
      if (validState) {
        try {
          const serverCheckTokensValid = await authApi.verifyToken({
            token: storedAuthData.auth.token,
            phoneNumber: storedAuthData.user.phoneNumber,
            email: storedAuthData.user.email,
          });
        } catch (e) {
          if (
            e instanceof TypeError &&
            e.message === "Network request failed"
          ) {
            console.log(
              "Passed if (e instanceof TypeError && e.message === 'Network request failed')"
            );
            return thunkAPI.rejectWithValue("NETWORK_ERROR");
            // thunkAPI.dispatch(verifyStoredData());
            // throw new Error("Network error");
          } else {
            await removeStoredAuthData();
            throw new Error("Token server check failed");
          }
        }

        return storedAuthData;
      } else if (!validState) {
        await removeStoredAuthData();
        throw new Error("Stored data is not present");
      } else {
        return storedAuthData;
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

export const logout = createAsyncThunk<
  boolean,
  void,
  { state: RootState; dispatch: AppDispatch }
>("auth/logout", async (_, thunkAPI) => {
  const currentState = thunkAPI.getState();
  const succesfull = await authApi.logout(currentState.auth.auth.token);

  if (succesfull) {
    await removeStoredAuthData(); // Assuming this is an async function.
    thunkAPI.dispatch(api.util.resetApiState());
  }

  return succesfull;
});

// OTP related thunks

// login with SMS OTP
export const loginWithSms = createAsyncThunk<
  ApiResponse<AuthData>,
  { otpCode: string; phoneNumber: string },
  { state: RootState; dispatch: AppDispatch; rejectValue: string[] }
>("auth/login_with_sms", async (data, thunkAPI) => {
  try {
    const currentState = await thunkAPI.getState();
    const response = await authApi.otpAuthenticationRequest(
      data.otpCode,
      data.phoneNumber.replace(/\s+/g, ""),
      currentState.role.role
    );

    if (response.errors) {
      thunkAPI.dispatch(smsOtpApi.util.invalidateTags(["SmsOtp"]));
      return thunkAPI.rejectWithValue(response.errors);
    }

    return response;
  } catch (error) {
    // return thunkAPI.rejectWithValue(error);
    console.log("Error in loginWithSMS thunk: ", error);
    throw new Error(`There was an error in loginWithSMS thunk`);
  }
});
