import { Question } from "./types";
import { questionsApi } from "./questionsApi";

export const onAddQuestionQueryStarted = async (
  question,
  { dispatch, queryFulfilled }
) => {
  const addResult = dispatch(
    questionsApi.util.updateQueryData(
      "getQuestions",
      undefined,
      (draftQuestions) => {
        draftQuestions.unshift(question as Question);
      }
    )
  );
  try {
    const result = await queryFulfilled;
    const returnedQuestionId = result?.data?.id;

    console.log(result.data.answers);
    if (returnedQuestionId) {
      dispatch(
        questionsApi.util.updateQueryData(
          "getQuestions",
          undefined,
          (draftQuestions) => {
            // Find the optimistically added question and update its ID
            const optimisticallyAdded = draftQuestions.find((q) => !q.id);
            if (optimisticallyAdded) {
              optimisticallyAdded.id = returnedQuestionId;
              optimisticallyAdded.answers = result.data.answers;
            }
          }
        )
      );
    }
  } catch (error: any) {
    console.log(error);
    addResult.undo();
  }
};

export const onUpdateQuestionQueryStarted = async (
  { id, ...patch },
  { dispatch, queryFulfilled }
) => {
  const patchResultQuestion = dispatch(
    questionsApi.util.updateQueryData("getQuestion", id, (draft) => {
      Object.assign(draft, patch);
    })
  );
  const patchResultList = dispatch(
    questionsApi.util.updateQueryData(
      "getQuestions",
      undefined,
      (draftQuestions) => {
        const questionIndex = draftQuestions.findIndex(
          (question) => question.id === id
        );
        draftQuestions[questionIndex] = { id, ...patch } as Question;
      }
    )
  );
  try {
    await queryFulfilled;
  } catch (error: any) {
    patchResultQuestion.undo();
    patchResultList.undo();
  }
};

export const onRemoveQuestionQueryStarted = async (
  id,
  { dispatch, queryFulfilled }
) => {
  const removeResult = dispatch(
    questionsApi.util.updateQueryData(
      "getQuestions",
      undefined,
      (draftQuestions) => [...draftQuestions].filter((q) => q.id !== id)
    )
  );
  try {
    await queryFulfilled;
  } catch (error: any) {
    console.log(error);
    removeResult.undo();
  }
};
