import { transformKeysToSnakeCase } from "@Core/utils/transfomObjectKeys";
import { api } from "../index";
import {
  onAddAccessCodeQueryStarted,
  onRemoveAccessCodeQueryStarted,
} from "./utils";
import { AccessCode } from "./type";

export const accessCodeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAccessCodes: build.query<AccessCode[], void>({
      query: () => "school_request_codes",
      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.map(({ id }) => ({
                type: "AccessCode" as const,
                id,
              })),
              { type: "AccessCode", id: "LIST" },
            ]
          : ["AccessCode"];
      },
    }),

    generateNewAccessCode: build.mutation<AccessCode, number>({
      query: (id) => ({
        url: `school_request_codes`,
        method: "POST",
        body: { group_id: id },
      }),
      invalidatesTags: (response, error, arg) => [
        { type: "AccessCode", id: response.id },
      ],
      onQueryStarted: onAddAccessCodeQueryStarted,
    }),

    removeAccessCode: build.mutation<AccessCode, number>({
      query: (id) => ({
        url: `school_request_codes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (response) => [
        { type: "AccessCode" as const, id: response.id },
      ],
      onQueryStarted: onRemoveAccessCodeQueryStarted,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAccessCodesQuery,
  useGenerateNewAccessCodeMutation,
  useRemoveAccessCodeMutation,
} = accessCodeApi;
