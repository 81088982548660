import { transformKeysToSnakeCase } from "@Core/utils/transfomObjectKeys";
import { api } from "../index";
import { Group } from "./types";
import {
  onAddGroupQueryStarted,
  onRemoveGroupQueryStarted,
  onUpdateGroupQueryStarted,
} from "./utils";

export const groupsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getGroups: build.query<Group[], void>({
      query: () => "groups",
      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.map(({ id }) => ({
                type: "Group" as const,
                id,
              })),
              { type: "Group", id: "LIST" },
            ]
          : ["Group"];
      },
    }),

    getGroup: build.query<Group, number>({
      query: (id) => `groups/${id}`,
      transformErrorResponse: (
        response: { status: string | number },
        meta,
        arg
      ) => response.status,
      providesTags: (result, error, id) => [{ type: "Group", id }],
      transformResponse: (response) => {
        return response as Group;
      },
    }),

    updateGroup: build.mutation<Group, Partial<Group> & Pick<Group, "id">>({
      query: ({ id, ...patch }) => ({
        url: `groups/${id}`,
        method: "PATCH",
        body: transformKeysToSnakeCase(patch),
      }),

      onQueryStarted: onUpdateGroupQueryStarted,
    }),

    addGroup: build.mutation<Group, Omit<Group, "id" | "numberOfStudents">>({
      query: (group) => ({
        url: `groups`,
        method: "POST",
        body: transformKeysToSnakeCase(group),
      }),
      invalidatesTags: (response, error, arg) => [
        { type: "Group", id: response.id },
      ],
      onQueryStarted: onAddGroupQueryStarted,
    }),

    removeGroup: build.mutation<Group, number>({
      query: (id) => ({
        url: `groups/${id}`,
        method: "DELETE",
      }),
      onQueryStarted: onRemoveGroupQueryStarted,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetGroupsQuery,
  useGetGroupQuery,
  useAddGroupMutation,
  useUpdateGroupMutation,
  useRemoveGroupMutation,
} = groupsApi;
