import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { Colors } from "@config/colors";
import { GroupsStackParamList } from "@navigation/types";
import { useGetTestsByGroupIdQuery } from "@services/api/tests/testsApi";
import ErrorNotification from "@Core/UI/components/Feedback/ErrorNotification";
import LoadingOverlay from "@Core/Auth/components/UI/LoadingOverlay";
import TestListItem from "./TestListItem";
import { RouteProp, useRoute } from "@react-navigation/native";
import { Test } from "@services/api/tests/type";
import EmptyBox from "@components/UI/EmptyBox";

type Route = RouteProp<GroupsStackParamList, "GroupsShow">;
type Props = {
  tests: Test[];
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
};
const TestsList: React.FC<Props> = (props) => {
  const route = useRoute<Route>();
  const { tests, isLoading, isFetching, isError } = props;

  if (isError) {
    return <ErrorNotification />;
  }

  if (isLoading || isFetching) {
    return <LoadingOverlay />;
  }

  return (
    <>
      {tests?.length === 0 ? (
        <EmptyBox message="Momentan nu exista teste. Creeaza unul!" />
      ) : (
        <FlatList
          data={tests}
          keyExtractor={(item) => {
            return item.id.toString();
          }}
          renderItem={({ item, index }) => (
            <TestListItem
              key={item.id}
              test={item}
              first={index === 0}
              last={index === tests.length - 1}
            />
          )}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  centered: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  emptyLabel: {
    color: Colors.darkNeutral,
  },
});

export default TestsList;
