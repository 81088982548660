import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import { EmployeeStackParamList } from "@navigation/types";
import { useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React, { useState } from "react";
import { View, Text, StyleSheet, Pressable } from "react-native";

type Props = {
  children: string;
  routeName?: any;
  onPress?: () => void;
  hoverColor?: string;
  color?: string;
};

type Navigation = NativeStackNavigationProp<EmployeeStackParamList>;

const MenuButton: React.FC<Props> = (props) => {
  const { children, routeName, onPress, hoverColor, color } = props;
  const { navigate } = useNavigation<Navigation>();
  const route = useRoute();

  const [isHovered, setIsHovered] = useState(false);
  const style = [
    styles.buttonText,
    color && { color: color },
    route.name.includes(routeName)
      ? styles.active
      : isHovered
      ? {
          fontWeight: UI.font.weight.bold,
          color: hoverColor || Colors.accent,
        }
      : {},
  ];

  const handlePress = () => {
    if (routeName) {
      navigate(routeName, { screen: `${routeName}Index` });
    } else {
      onPress();
    }
  };

  return (
    <Pressable
      style={styles.button}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
      onPress={handlePress}
    >
      <Text style={style}>{children}</Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  button: {
    flex: 2,
    paddingLeft: UI.sizes.margin,
    justifyContent: "center",
    marginVertical: (UI.sizes.margin * 1.618) / 2.618,
  },
  buttonText: {
    fontSize: 16,
    fontWeight: UI.font.weight.semiBold,
    color: Colors.darkNeutral,
  },
  active: {
    fontWeight: UI.font.weight.bold,
    color: Colors.accent,
  },
  textHovered: {
    fontWeight: UI.font.weight.bold,
    color: Colors.accent,
  },
});

export default MenuButton;
