import { studentCategoryProgressesApi } from "./studentCategoryProgressesApi";
import { Question } from "../questions/types";

export const onUpdateStudentCategoryProgressQueryStarted = async (
  { id, questionId, answerId },
  { dispatch, queryFulfilled, getState }
) => {
  // Set questions from local cache
  const questions = getState().baseApi.queries["getQuestions(undefined)"]
    .data as Question[];
  let studentCategoryProgressIndex;

  const patchResultList = dispatch(
    studentCategoryProgressesApi.util.updateQueryData(
      "getStudentCategoryProgresses",
      undefined,
      (draftStudentCategoryProgresses) => {
        // Find index of the current studentCategoryProgress
        studentCategoryProgressIndex = draftStudentCategoryProgresses.findIndex(
          (studentCategoryProgress) => studentCategoryProgress.id === id
        );

        const studentCategoryProgress =
          draftStudentCategoryProgresses[studentCategoryProgressIndex];

        // Mimic back-end logic
        // * Step 0: Set variables used in different steps

        // Set questions from the same category as studentCategoryProgress
        const categoryQuestions = questions.filter(
          (q) => q.category.id === studentCategoryProgress.questionCategoryId
        );
        // Set the count of correctly answered questions
        let correctlyAnsweredCount =
          studentCategoryProgress.answeredQuestions.filter(
            (q) => q.answeredCorrectly
          ).length;

        // Set count of all question from the same category as studentCategoryProgress
        const totalQuestionsCount = categoryQuestions.length;

        // * Step 1: Update cooldowns
        studentCategoryProgress.answeredQuestions.forEach((cpQuestion) => {
          if (cpQuestion.cooldown) {
            cpQuestion.cooldown = Math.max(cpQuestion.cooldown - 1, 0);
          }
        });

        // * Step 2: Update specific question
        const currentQuestion = questions.find((q) => q.id === questionId);
        const isAnswerCorrect =
          currentQuestion && currentQuestion.correctAnswerId === answerId;

        const cooldown = Math.min(
          categoryQuestions.length - correctlyAnsweredCount,
          5
        );

        let cpQuestion = studentCategoryProgress.answeredQuestions.find(
          (q) => q.questionId === questionId
        );

        if (cpQuestion) {
          cpQuestion.attempts += 1;
          cpQuestion.lastAttempt = new Date().toISOString();
          cpQuestion.answeredCorrectly = isAnswerCorrect;
          cpQuestion.cooldown = isAnswerCorrect ? null : cooldown;
        } else {
          // If the question is new
          cpQuestion = {
            id: 0,
            questionId,
            studentCategoryProgressId: studentCategoryProgress.id,
            attempts: 1,
            lastAttempt: new Date().toISOString(),
            answeredCorrectly: isAnswerCorrect,
            cooldown: isAnswerCorrect ? null : cooldown,
          };
          studentCategoryProgress.answeredQuestions.push(cpQuestion);
        }

        if (isAnswerCorrect) correctlyAnsweredCount += 1;
        // * Step 3: Update status

        studentCategoryProgress.status =
          correctlyAnsweredCount === totalQuestionsCount
            ? "finished"
            : "ongoing";

        // draftStudentCategoryProgresses[studentCategoryProgressIndex] =
        //   {} as StudentCategoryProgress;
      }
    )
  );
  try {
    const response = await queryFulfilled;

    const returnedCPQuestionId = response.data.answeredQuestions.find(
      (cp_question) => cp_question.questionId === questionId
    ).id;

    if (returnedCPQuestionId) {
      dispatch(
        studentCategoryProgressesApi.util.updateQueryData(
          "getStudentCategoryProgresses",
          undefined,
          (draftStudentCategoryProgresses) => {
            // Find the optimistically added question and update its ID
            const optimisticallyAddedCpQuestion =
              draftStudentCategoryProgresses[
                studentCategoryProgressIndex
              ].answeredQuestions.find((cp_question) => !cp_question.id);
            if (optimisticallyAddedCpQuestion) {
              optimisticallyAddedCpQuestion.id = returnedCPQuestionId;
            }
          }
        )
      );
    }

    // TODO if cpQuestion was new, update it's id
  } catch (error: any) {
    patchResultList.undo();
  }
};

export const onRemoveStudentCategoryProgressQueryStarted = async (
  id,
  { dispatch, queryFulfilled }
) => {
  const removeResult = dispatch(
    studentCategoryProgressesApi.util.updateQueryData(
      "getStudentCategoryProgresses",
      undefined,
      (draftStudentCategoryProgresses) =>
        [...draftStudentCategoryProgresses].filter((q) => q.id !== id)
    )
  );
  try {
    await queryFulfilled;
  } catch (error: any) {
    console.log(error);
    removeResult.undo();
  }
};
