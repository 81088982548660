import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import React from "react";
import { View, Text, StyleSheet, Pressable } from "react-native";
import TestProgressDisplay, { TestProgressLabel } from "./TestProgressDisplay";
import DECTextButton from "@Core/UI/components/Buttons/DECTextButton";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { StudentDrawerParamList } from "@navigation/types";
import { formatSecondsToTime } from "@Core/utils/dateAndTime";
import BaseCard from "@components/UI/BaseCard/BaseCard";

type Props = {
  correctAnswers: number;
  wrongAnswers: number;
  questionsCount: number;
  startedAt: string;
  endedAt: string;
  startNewTest: () => void;
};

const FailedLabel = () => {
  return (
    <>
      <Text style={[styles.text, styles.dangerText]}>Ai picat</Text>
      <Text style={[styles.encouragementText]}>
        Nu e nimic, mai incearca! Repetita e mama invataturii.
      </Text>
    </>
  );
};

const PassedLabel = () => {
  return (
    <>
      <Text style={[styles.text, styles.successText]}>Ai trecut!</Text>
      <Text style={[styles.encouragementText]}>Felicitari!</Text>
    </>
  );
};

const TestEndedPopUp: React.FC<Props> = (props) => {
  const {
    correctAnswers,
    wrongAnswers,
    questionsCount,
    startedAt,
    endedAt,
    startNewTest,
  } = props;

  const navigation = useNavigation<NavigationProp<StudentDrawerParamList>>();

  const totalTimeInSeconds = Math.round(
    (new Date(endedAt).getTime() - new Date(startedAt).getTime()) / 1000
  );

  const timeExpired = totalTimeInSeconds >= 3600;
  const isFailed = wrongAnswers >= 5 || (timeExpired && correctAnswers < 16);

  const handlePress = () => {
    startNewTest();
  };

  return (
    <View style={styles.component}>
      <BaseCard style={styles.card}>
        <View style={styles.header}>
          {isFailed ? <FailedLabel /> : <PassedLabel />}
        </View>
        <View style={styles.body}>
          {20 - correctAnswers - wrongAnswers > 0 && (
            <TestProgressLabel
              label="Ramase:"
              value={questionsCount - correctAnswers - wrongAnswers}
              color={Colors.accent}
            />
          )}
          <TestProgressLabel
            label="Corecte:"
            value={correctAnswers}
            color={Colors.success}
          />
          <TestProgressLabel
            label="Gresite:"
            value={wrongAnswers}
            color={Colors.error}
          />
          <TestProgressLabel
            label="Timp:"
            value={formatSecondsToTime(totalTimeInSeconds)}
            color={Colors.darkNeutral}
          />
        </View>
        <View style={styles.footer}>
          <DECTextButton
            title="Incepe un test nou"
            containerStyle={styles.button}
            textStyle={styles.buttonText}
            onPress={handlePress}
          />
          <DECTextButton
            title="Inapoi la pagina principala"
            containerStyle={[styles.button, styles.secondaryButton]}
            onPress={() => navigation.navigate("Home")}
          />
        </View>
      </BaseCard>
    </View>
  );
};

const styles = StyleSheet.create({
  component: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: UI.sizes.padding,
    maxWidth: 560,
  },
  card: {
    width: "100%",
    // height: "25%",
    padding: UI.sizes.padding,
    marginHorizontal: UI.sizes.margin,
    justifyContent: "center",
    backgroundColor: Colors.backgroundSecondary,
    borderRadius: UI.sizes.borderRadius,
  },
  header: {
    marginBottom: UI.sizes.margin,
  },
  body: {
    // justifyContent: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginHorizontal: "auto",
    marginBottom: UI.sizes.margin,
  },
  footer: {},
  label: {
    textAlign: "center",
    color: Colors.darkNeutral,
  },
  text: {
    fontSize: 32,
    textAlign: "center",
    fontWeight: "bold",
    color: Colors.darkNeutral,
    // marginBottom: UI.sizes.margin * 2,
  },
  successText: {
    color: Colors.success,
  },
  dangerText: {
    color: Colors.error,
  },
  encouragementText: {
    textAlign: "center",
    fontSize: 16,
    color: Colors.darkNeutral,
    marginTop: (UI.sizes.margin * 1.618) / 2.618,
  },
  button: {
    width: "100%",
    padding: UI.sizes.padding / 2,
    backgroundColor: Colors.warning,
    borderRadius: UI.sizes.borderRadius,
    marginBottom: (UI.sizes.margin * 1.618) / 2.618,
  },
  secondaryButton: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    borderWidth: 1,
    borderColor: Colors.accent,
  },
  buttonText: {
    color: Colors.lightNeutral,
    fontWeight: "bold",
  },
});

export default TestEndedPopUp;
