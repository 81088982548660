import { AuthTokens, UserData } from "../types";

export const isAuthDataValid = (data: AuthTokens) => {
  if (!data) {
    return undefined;
  }

  const { token, refreshToken, expiresAt } = data;
  if (!token || !refreshToken || !expiresAt) {
    return undefined;
  }
  return Date.now() < Date.parse(expiresAt);
};

export const isUserDataValid = (data: UserData) => {
  if (!data) {
    return undefined;
  }
  const { email, firstName, lastName } = data;
  return email && lastName && firstName;
};
