import React, { useCallback, useMemo } from "react";
import { StyleSheet } from "react-native";
import { Colors } from "@config/colors";
import StudentsList from "@components/Students/StudentsList";
import { ScrollView } from "react-native-gesture-handler";
import TitleCard from "@components/UI/TitleCard";
import ImageButton from "@components/UI/ImageButton";
import AccessCodeCard from "@components/AccessCodes/AccessCodeCard";
import StudentRequestsList from "@components/StudentRequests/StudentRequestsList";
import ErrorNotification from "@Core/UI/components/Feedback/ErrorNotification";
import LoadingOverlay from "@Core/Auth/components/UI/LoadingOverlay";
import { UI } from "@config/constants";
import {
  useGetGroupQuery,
  useRemoveGroupMutation,
} from "@services/api/groups/groupsApi";
import { useGetStudentRequestsQuery } from "@services/api/studentRequests/studentsRequestsApi";
import { NavigationProp, RouteProp } from "@react-navigation/native";
import { EmployeeStackParamList } from "@navigation/types";
import { View } from "native-base";

type Props = {
  route: RouteProp<EmployeeStackParamList, "GroupsShow">;
  navigation: NavigationProp<EmployeeStackParamList, "GroupsShow">;
};

const GroupsIndexContent: React.FC<Props> = ({ route, navigation }) => {
  const {
    data: groupData,
    isLoading: groupIsLoading,
    isError: groupIsError,
  } = useGetGroupQuery(route.params.id);

  const {
    data: studentRequests,
    isLoading: requestsIsLoading,
    isError: requestsIsError,
  } = useGetStudentRequestsQuery();

  const [
    removeGroup,
    {
      isLoading: isLoadingRemove,
      isSuccess: isSuccessRemove,
      isError: isErrorRemove,
    },
  ] = useRemoveGroupMutation();

  const activeStudentRequests = useMemo(
    () =>
      studentRequests
        .filter((sr) => sr.status === "pending")
        .filter((sr) => !route.params.id || sr.group.id === route.params.id),
    [studentRequests, route]
  );

  const handleRemove = () => {
    removeGroup(route.params.id);
    setTimeout(() => navigation.goBack(), 1000);
  };

  const renderInnerContent = useCallback(() => {
    if (groupIsError && requestsIsError) {
      return <ErrorNotification />;
    }

    if (groupIsLoading || requestsIsLoading) {
      return <LoadingOverlay />;
    }

    return (
      <>
        <AccessCodeCard />
        {activeStudentRequests.length > 0 && (
          <View style={styles.marginBottom}>
            <StudentRequestsList />
          </View>
        )}
        <StudentsList backgroundColor={Colors.backgroundPrimary} />
        {/* <View style={styles.deleteButtonContainer}>
          <DECTrashButton
            onPress={handleRemove}
            isError={isErrorRemove}
            isLoading={isLoadingRemove}
            isSuccess={isSuccessRemove}
          />
        </View> */}
      </>
    );
  }, [
    activeStudentRequests,
    groupIsLoading,
    requestsIsLoading,
    groupIsError,
    requestsIsError,
    isErrorRemove,
    isLoadingRemove,
    isSuccessRemove,
  ]);

  return (
    <ScrollView contentContainerStyle={{ height: "100%" }}>
      <TitleCard
        title={`Grupa: ${groupData?.name}`}
        rightItem={
          <ImageButton
            source={require("@assets/icons/close.png")}
            size={16}
            onPress={() => navigation.navigate("GroupsIndex")}
          />
        }
      >
        {renderInnerContent()}
      </TitleCard>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  deleteButtonContainer: {
    marginTop: UI.sizes.margin,
    width: 80,
  },
  marginBottom: {
    marginBottom: (UI.sizes.margin * 1.618) / 2.618,
  },
});

export default GroupsIndexContent;
