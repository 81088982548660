import AsyncStorage from "@react-native-async-storage/async-storage";
import { AuthData } from "../../types";
import { initialState } from "@Core/Auth/store/auth/authSlice";

export const storeAuthData = async (data: AuthData) => {
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      AsyncStorage.setItem(key, JSON.stringify(data[key]));
    }
  }
};

export const removeStoredAuthData = async () => {
  const data = {
    auth: {},
    user: {},
  };
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      AsyncStorage.removeItem(key);
    }
  }
};

export const getAuthData = async () => {
  const data: AuthData = { ...initialState };
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const rawData = await AsyncStorage.getItem(key);
      data[key] = JSON.parse(rawData);
    }
  }
  return data;
};
