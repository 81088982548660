import DECTextButton from "@Core/UI/components/Buttons/DECTextButton";
import BaseCard from "@components/UI/BaseCard/BaseCard";
import { Colors, ColorsPalette } from "@config/colors";
import { UI } from "@config/constants";
import { background } from "native-base/lib/typescript/theme/styled-system";
import React, { ReactNode } from "react";
import { View, Text, StyleSheet, Image } from "react-native";
import tinycolor from "tinycolor2";

type Props = {
  title: string;
  header?: ReactNode;
  children: ReactNode;
  buttonText: string;
  locked?: boolean;
  lockedText?: string;
  backgroundColor?: string;
  onButtonPress: () => void;
};

const HomeScreenCard: React.FC<Props> = (props) => {
  const {
    title,
    header,
    children,
    buttonText,
    locked,
    lockedText,
    backgroundColor = Colors.backgroundSecondary,
    onButtonPress,
  } = props;

  const backgroundIsDark = tinycolor(backgroundColor).isDark();

  const containerStyle = {
    backgroundColor: backgroundColor,
    // backgroundColor: locked
    //   ? tinycolor(backgroundColor).darken(40).toString()
    //   : backgroundColor,
  };

  const titleContainerStyle = {
    borderBottomColor: backgroundIsDark
      ? Colors.darkNeutral
      : Colors.lightNeutral,
  };

  const textStyle = {
    color: backgroundIsDark ? Colors.darkNeutral : Colors.lightNeutral,
  };

  const buttonContainerStyle = {
    backgroundColor: !backgroundIsDark
      ? Colors.backgroundSecondary
      : ColorsPalette.primary[500],
  };

  const buttonTextStyle = {
    color: !backgroundIsDark && Colors.darkNeutral,
  };

  return (
    <BaseCard
      style={[styles.component, backgroundColor && containerStyle]}
      locked={locked}
      lockedText={lockedText}
    >
      <View style={styles.header}>
        <View style={[styles.titleContainer, titleContainerStyle]}>
          <Text style={[styles.lightText, styles.title, textStyle]}>
            {title}
          </Text>
        </View>
        {header}
      </View>
      <View style={styles.body}>{children}</View>
      <View style={styles.footer}>
        <DECTextButton
          containerStyle={[styles.continueButton, buttonContainerStyle]}
          textStyle={[styles.continueButtonText, buttonTextStyle]}
          title={buttonText}
          onPress={onButtonPress}
        />
      </View>
    </BaseCard>
  );
};

const styles = StyleSheet.create({
  component: {
    marginBottom: UI.sizes.margin,
  },

  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 4,
    marginBottom: UI.sizes.margin,
  },
  titleContainer: {
    borderBottomWidth: 2,
    borderBottomColor: Colors.darkNeutral,
  },
  title: {
    color: Colors.darkNeutral,
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: 16,
    fontWeight: "bold",
  },
  body: {},
  lightText: {
    fontSize: 20,
    fontWeight: "bold",
    color: Colors.darkNeutral,
  },

  footer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  continueButton: {
    backgroundColor: ColorsPalette.primary[500],
    // width: "45%",
  },
  continueButtonText: {
    color: Colors.backgroundPrimary,
  },
});

export default HomeScreenCard;
