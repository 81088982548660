import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { helloMessage } from "./helpers";
import { useGetUserDetailsQuery } from "@services/api/userDetails/userDetailsApi";
import { useAppSelector } from "@store/hooks";
import { UI } from "@config/constants";
import BaseCard from "@components/UI/BaseCard/BaseCard";
import { Colors, ColorsPalette } from "@config/colors";
import { quotes } from "./quotes";

type Props = {};

const HelloCard: React.FC<Props> = (props) => {
  const isAuthenticated = useAppSelector((state) => state.auth.auth.token);
  const {
    data: userData,
    isLoading,
    isFetching,
  } = useGetUserDetailsQuery(undefined, { skip: !isAuthenticated });

  const quote = quotes[new Date().getDay() % quotes.length];

  return (
    <BaseCard style={styles.component}>
      <View style={styles.helloContainer}>
        <Text style={styles.helloText}>{helloMessage()}, </Text>
        <Text style={[styles.helloText, styles.nameText]}>
          {userData.firstName}
        </Text>
      </View>
      <View style={styles.quoteContainer}>
        <Text style={styles.quoteText}>"{quote.content}"</Text>
        <View style={styles.authorContainer}>
          <Text style={styles.authorText}>- {quote.author}</Text>
        </View>
      </View>
    </BaseCard>
  );
};

const styles = StyleSheet.create({
  component: {
    // marginHorizontal: UI.sizes.margin,
    marginBottom: UI.sizes.margin,
  },
  helloContainer: {
    flexDirection: "row",
    marginBottom: UI.sizes.margin,
  },
  quoteContainer: {},
  helloText: {
    fontWeight: UI.font.weight.semiBold,
    fontSize: 32,
    color: Colors.darkNeutral,
  },
  nameText: {
    color: Colors.accent,
  },
  quoteText: {
    fontWeight: UI.font.weight.light,
    color: ColorsPalette.primary[200],
  },
  authorContainer: {
    marginTop: 4,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  authorText: {
    fontWeight: UI.font.weight.extraLight,
    color: ColorsPalette.primary[100],
  },
});

export default HelloCard;
