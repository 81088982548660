import BaseCard from "@components/UI/BaseCard/BaseCard";
import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import { StudentDrawerParamList } from "@navigation/types";
import {
  NavigationProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { StudentRandomTest } from "@services/api/studentRandomTest/types";
import React, { useMemo } from "react";
import { View, Text, StyleSheet, Image, Pressable } from "react-native";

const generateAvarageDurationString = (tests: StudentRandomTest[]) => {
  const testsDuration = tests?.map(
    (test) =>
      new Date(test.endedAt).getTime() - new Date(test.startedAt).getTime()
  );

  let totalAddedTestsDuration = 0;

  testsDuration?.forEach((duration) => (totalAddedTestsDuration += duration));

  const average = Math.floor(totalAddedTestsDuration / 1000 / tests?.length);

  const minutesString = `${Math.floor(average / 60)}`;
  const seconds = average % 60;
  const secondsString = `${seconds}`.padStart(2, "0");
  return `${minutesString}:${secondsString}`;
};

type Props = {
  tests: StudentRandomTest[];
  navigation: NavigationProp<StudentDrawerParamList, "History" | "Statistics">;
};

const CompactProgressCard: React.FC<Props> = ({ tests, navigation }) => {
  const route = useRoute();
  const averageDurationString = useMemo(
    () => generateAvarageDurationString(tests),
    [tests?.length]
  );

  const passedTestsCount = useMemo(
    () => tests?.filter((t) => t.status === "passed").length,
    [tests?.length]
  );
  const failedTestsCount = tests?.length - passedTestsCount;

  const averagePromovability = (
    (passedTestsCount / tests?.length) *
    100
  ).toFixed(0);

  return (
    <BaseCard style={styles.component}>
      <View style={styles.mainContent}>
        <View style={styles.infoContainer}>
          <View style={styles.infoItem}>
            <Image
              source={require("@assets/icons/test/clipboard.png")}
              style={[styles.icon, { width: 29, height: 29 }]}
            />
            <Text style={styles.boldText}>Total: {tests?.length}</Text>
          </View>
          <View style={styles.infoItem}>
            <Image
              source={require("@assets/icons/test/pass.png")}
              style={styles.icon}
            />
            <Text style={[styles.boldText, styles.successText]}>
              Promovate: {passedTestsCount}
            </Text>
          </View>
          <View style={styles.infoItem}>
            <Image
              source={require("@assets/icons/test/fail.png")}
              style={styles.icon}
            />
            <Text style={[styles.boldText, styles.errorText]}>
              Nepromovate: {failedTestsCount}
            </Text>
          </View>
        </View>
        <View style={styles.infoContainer}>
          <View style={styles.infoItem}>
            <Image
              source={require("@assets/icons/test/deadline.png")}
              style={styles.icon}
            />
            <Text style={styles.boldText}>
              Durata medie: {tests?.length == 0 ? "-" : averageDurationString}
            </Text>
          </View>

          <View style={styles.infoItem}>
            <Image
              source={require("@assets/icons/test/clipboard_pencil.png")}
              style={styles.icon}
            />
            <Text style={styles.boldText}>
              Promovabilitate: {tests?.length == 0 ? "-" : averagePromovability}{" "}
              %
            </Text>
          </View>
        </View>
      </View>
      <Pressable
        style={styles.linkButton}
        onPress={() =>
          navigation.navigate(
            route.name === "Statistics" ? "History" : "Statistics"
          )
        }
      >
        <Text style={styles.linkButtonText}>
          {route.name === "Statistics"
            ? "Vezi istoricul testelor"
            : "Vezi mai multe statistici"}
        </Text>
      </Pressable>
    </BaseCard>
  );
};

const styles = StyleSheet.create({
  component: {
    // marginTop: UI.sizes.margin,
    backgroundColor: Colors.backgroundSecondary,
    marginHorizontal: UI.sizes.padding,
    padding: 0,
    height: 200,
    // flexDirection: "row",
  },
  mainContent: {
    padding: UI.sizes.padding,
    flex: 5,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  linkButton: {
    flex: 1,
    padding: 8,
    backgroundColor: Colors.accent,
    borderBottomLeftRadius: UI.sizes.borderRadius,
    borderBottomRightRadius: UI.sizes.borderRadius,
    justifyContent: "center",
    alignItems: "center",
  },
  linkButtonText: {
    fontWeight: UI.font.weight.semiBold,
  },
  testsCountContainer: {
    flexDirection: "row",
  },
  totalTestsCount: {
    // marginRight: 8
  },
  successText: {
    color: Colors.success,
  },
  errorText: {
    color: Colors.error,
  },
  boldText: {
    fontWeight: UI.font.weight.semiBold,
    color: Colors.accent,
  },
  icon: {
    width: 24,
    height: 24,
    margin: 8,
    marginRight: 2,
  },
  infoItem: {
    flexDirection: "row",
    alignItems: "center",
  },
  infoContainer: {
    justifyContent: "space-between",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 16,
    // width: "100%",
  },
});

export default CompactProgressCard;
