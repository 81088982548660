import React, { useState } from "react";
import { View, Text, StyleSheet } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { EmployeeStackParamList } from "@navigation/types";
import { Colors } from "@config/colors";
import SideMenu from "@components/UI/SideMenu/SideMenu";
import { UI } from "@config/constants";
import TitleCard from "@components/UI/TitleCard";
import TestsList from "@components/Test/Index/TestsList/TestsList";
import { useGetTestsQuery } from "@services/api/tests/testsApi";
import ImageButton from "@components/UI/ImageButton";
import NewFormLink from "@components/UI/BaseCard/NewFormLink";
import GroupForm from "@components/Groups/Index/GroupForm";
import TestForm from "@components/Test/Index/TestForm";

type Props = NativeStackScreenProps<EmployeeStackParamList, "TestsIndex">;

const IndexTestsScreen: React.FC<Props> = (props) => {
  const { navigation } = props;
  const [isFormOpen, setIsFormOpen] = useState(false);

  const { data, isLoading, isFetching, isError } = useGetTestsQuery();

  return (
    <View style={styles.screen}>
      <View style={styles.content}>
        <View style={[styles.GLDR1, styles.borderRed]}>
          <SideMenu />
        </View>
        <View style={[styles.GLDR2, styles.borderRed]}>
          <TitleCard
            title="Teste"
            rightItem={
              isFormOpen ? (
                <ImageButton
                  source={require("@assets/icons/close.png")}
                  size={16}
                  onPress={() => setIsFormOpen(false)}
                />
              ) : (
                <NewFormLink
                  onPress={() => setIsFormOpen(true)}
                  text="Adauga un test"
                />
              )
            }
          >
            {isFormOpen && <TestForm onSubmit={() => setIsFormOpen(false)} />}

            <TestsList
              tests={data}
              isLoading={isLoading}
              isFetching={isFetching}
              isError={isError}
            />
          </TitleCard>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Colors.backgroundPrimary,
  },
  content: {
    margin: UI.sizes.margin / 2,
    flex: 1,
    flexDirection: "row",
    // borderColor: "red", // TODO delete
    // borderWidth: 1, // TODO delete
  },
  borderRed: {
    // borderColor: "red", // TODO delete
    // borderWidth: 1, // TODO delete
  },
  GLDR1: { flex: 1 },
  GLDR2: { flex: 1.618 + 2.618, margin: UI.sizes.margin / 2 },
});

export default IndexTestsScreen;
