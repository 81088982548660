import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  loginWithPassword,
  loginWithSms,
  logout,
  signupWithPassword,
  verifyStoredData,
} from "./authThunks";
import { AuthTokens, RejectedPayload, UserRole } from "@Core/Auth/types";
import { storeAuthData } from "@Core/Auth/services/auth/authLocal";

export interface AuthState {
  auth: {
    token: string;
    refreshToken: string;
    expiresAt: string;
  };
  user: {
    email?: string;
    firstName?: string;
    lastName?: string;
    role: UserRole;
    phoneNumber: string;
    phoneVerified: boolean;
    otpExpiry?: string;
    otpFailedAttempts?: number;
    otpLastResend?: string;
    otpSendAttempts?: number;
  };
  loading: "idle" | "pending" | "succeeded" | "failed";
  errors: string[];
}

export const initialState: AuthState = {
  auth: {
    token: "",
    refreshToken: "",
    expiresAt: "",
  },
  user: {
    email: "",
    firstName: "",
    lastName: "",
    role: "pending_student",
    phoneNumber: "",
    phoneVerified: false,
    otpExpiry: null,
    otpFailedAttempts: 0,
    otpLastResend: null,
    otpSendAttempts: 0,
  },
  loading: "idle",
  errors: [],
};

// Helper functions
const handlePending = (state) => {
  state.loading = "pending";
  // state.errors = [];
};

const handleReject = (state, action) => {
  state.loading = "failed";
  state.errors = action.payload;
};

const resetAuthState = (state) => {
  state.auth = initialState.auth;
  state.user = initialState.user;
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateTokens: (state, action: PayloadAction<AuthTokens>) => {
      state.auth = action.payload;
    },
    updateUserRole: (state, action: PayloadAction<UserRole>) => {
      state.user.role = action.payload;
      storeAuthData({
        auth: state.auth,
        user: { ...state.user, role: action.payload },
      });
    },
    clearAuthErrors: (state) => {
      state.errors = [];
    },
  },
  extraReducers: (builder) => {
    // loginWithPassword
    builder
      .addCase(loginWithPassword.pending, handlePending)
      .addCase(loginWithPassword.rejected, handleReject)
      .addCase(loginWithPassword.fulfilled, (state, action) => {
        state.errors = [];
        state.auth = action.payload.data.auth;
        state.user = action.payload.data.user;
        state.loading = "idle";
      })

      // loginWithSms
      .addCase(loginWithSms.pending, handlePending)
      .addCase(loginWithSms.rejected, handleReject)
      .addCase(loginWithSms.fulfilled, (state, action) => {
        state.errors = [];
        state.auth = action.payload.data.auth;
        state.user = action.payload.data.user;
        state.loading = "idle";
      })

      // signupWithPassword
      .addCase(signupWithPassword.pending, handlePending)
      .addCase(signupWithPassword.rejected, handleReject)
      .addCase(signupWithPassword.fulfilled, (state, action) => {
        state.errors = [];
        state.auth = action.payload.data.auth;
        state.user = action.payload.data.user;
        state.loading = "idle";
      })

      // verifyStoredData
      .addCase(verifyStoredData.pending, handlePending)
      .addCase(verifyStoredData.rejected, (state, action) => {
        console.log("action in verifyStoredData.rejected: ", action);
        const error = action;
        state.errors = [action.payload as string];
        state.loading = "failed";
      })
      .addCase(verifyStoredData.fulfilled, (state, action) => {
        state.errors = [];
        state.auth = action.payload.auth;
        state.user = action.payload.user;
        state.loading = "idle";
      })

      // logout
      .addCase(logout.pending, handlePending)
      .addCase(logout.rejected, (state, action) => {
        resetAuthState(state);
        handleReject(state, action);
      })
      .addCase(logout.fulfilled, (state, action) => {
        resetAuthState(state);
        state.loading = "idle";
      });
  },
});

export const { updateTokens, updateUserRole, clearAuthErrors } =
  authSlice.actions;
export default authSlice;
