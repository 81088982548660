import { api } from "../index";
import { QuestionCategory } from "./types";

export const questionCategoriesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getQuestionCategories: build.query<QuestionCategory[], void>({
      query: () => "question_categories",
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "QuestionCategory" as const,
                id,
              })),
              { type: "QuestionCategory", id: "LIST" },
            ]
          : ["QuestionCategory"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetQuestionCategoriesQuery } = questionCategoriesApi;
