import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from "react";
import { View, Text, StyleSheet, Image, FlatList } from "react-native";
import QuestionListItem from "./QuestionListItem";
import LoadingOverlay from "@Core/Auth/components/UI/LoadingOverlay";
import ErrorNotification from "@Core/UI/components/Feedback/ErrorNotification";
import QuestionsListFilters from "./QuestionsListFilters";
import EmptyBox from "@components/UI/EmptyBox";
import { useGetQuestionsQuery } from "@services/api/questions/questionsApi";
import Fuse from "fuse.js";
import DECInput from "@Core/UI/components/Inputs/DECInput";
import { Question } from "@services/api/questions/types";

type Props = {
  isSelectableList?: boolean;
  onSelect?: (number) => void;
  selectedIds?: number[];
  questionsListRef?: MutableRefObject<FlatList>;
};

const setChosenQuestionLabelColor = (length) => {
  if (length === 0 || length > 20) {
    return Colors.error;
  } else if (length === 20) {
    return Colors.success;
  }
  return Colors.warning;
};

const QuestionsList: React.FC<Props> = (props) => {
  const { isSelectableList, onSelect, selectedIds, questionsListRef } = props;
  const { data, isFetching, isLoading, isError } = useGetQuestionsQuery();

  const [questions, setQuestions] = useState<Question[]>([]);
  const [filteredCategories, setFilteredCategories] = useState<number[]>([]);
  const [expandedQuestionId, setExpandedQuestionId] = useState(0);
  const [searchBarValue, setSearchBarValue] = useState("");

  const fuse = new Fuse(data, {
    includeScore: true,
    includeMatches: true,
    keys: ["prompt"],
  });

  const handleFilterCardPress = useCallback(
    (categoryId: number) => {
      if (categoryId === null) {
        setFilteredCategories([]);
      } else if (filteredCategories.includes(categoryId)) {
        setFilteredCategories((prev) =>
          prev.filter((cat) => cat != categoryId)
        );
      } else {
        setFilteredCategories((prev) => [...prev, categoryId]);
      }
    },
    [filteredCategories]
  );

  const handleSearchValueUpdate = (value: string) => {
    setSearchBarValue(value);
    const result = fuse.search(value);
    const filteredResult = result.filter((item) => item.score < 0.525);
    const mappedResult = filteredResult.map((resultItem) => ({
      ...resultItem.item,
      matches: resultItem.matches,
    }));

    if (value === "") {
      setQuestions(data);
    } else {
      setQuestions(mappedResult);
    }
  };

  const handleQuestionExpand = (id) => {
    setExpandedQuestionId(id);
  };

  // Sort to show selected questions first
  const sortQuestions = () => {
    setQuestions((prevValue) =>
      prevValue.sort((a, b) => {
        if (selectedIds.includes(a.id) && !selectedIds.includes(b.id)) {
          return -1; // a is selected, b is not selected, a should come first.
        }
        if (!selectedIds.includes(a.id) && selectedIds.includes(b.id)) {
          return 1; // a is not selected, b is selected, b should come first.
        }
        return 0; // a and b are both selected or both not selected, keep their original order.
      })
    );
  };

  // After the first render, set firstRenderDone to true.
  useEffect(() => {
    if (!isLoading || !isFetching) {
      setQuestions(
        data?.filter(
          (question) =>
            filteredCategories.length === 0 ||
            filteredCategories.includes(question.category.id)
        )
      );

      isSelectableList && sortQuestions();
    }
  }, [data, filteredCategories, isLoading, isFetching]);

  const emptyBoxText = `Momentan nu exista intrebari. ${
    filteredCategories?.length === 0
      ? "Adauga una!"
      : `Sterge din filtre sau adauga una pentru categori${
          filteredCategories?.length === 1 ? "a" : "ile"
        } selectate!`
  }`;

  return (
    <>
      <DECInput
        onUpdateValue={handleSearchValueUpdate}
        placeholder="Cauta o intrebare"
        value={searchBarValue}
      />
      <QuestionsListFilters
        questions={data}
        filteredQuestions={questions}
        filteredCategories={filteredCategories}
        onFilterChange={handleFilterCardPress}
      />
      {isError ? (
        <ErrorNotification />
      ) : isLoading || isFetching ? (
        <LoadingOverlay />
      ) : (
        <>
          {questions?.length === 0 && <EmptyBox message={emptyBoxText} />}
          {isSelectableList && (
            <View style={styles.selectCount}>
              <Text
                style={{
                  color: setChosenQuestionLabelColor(selectedIds?.length),
                }}
              >
                {selectedIds?.length} intrebari selectate
              </Text>
            </View>
          )}
          <FlatList
            ref={questionsListRef}
            data={questions}
            keyExtractor={(item) => item.id?.toString()}
            renderItem={({ item: question, index }) => {
              const isSelected = selectedIds?.includes(question.id);
              return (
                <QuestionListItem
                  key={question?.id}
                  question={question}
                  first={index === 0}
                  last={index === questions?.length - 1}
                  isSelectable={isSelectableList}
                  onSelect={onSelect}
                  onPress={handleQuestionExpand}
                  isSelected={isSelected}
                  isExpanded={expandedQuestionId === question.id}
                  // isTop={isSelected}
                />
              );
            }}
            contentContainerStyle={{
              paddingRight: 10,
            }}
            style={{
              borderRadius: UI.sizes.borderRadius,
            }}
          />
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  showAllButton: {
    marginTop: UI.sizes.margin * (1.618 / 2.618),
    borderRadius: UI.sizes.borderRadius,
    padding: UI.sizes.padding / 2,
    backgroundColor: Colors.darkNeutral,
  },
  buttonText: {
    width: "100%",
    textAlign: "center",
    fontSize: 16,
    fontWeight: UI.font.weight.medium,
  },
  dotsCard: {
    height: 12,
    marginTop: UI.sizes.margin / 4,
    padding: UI.sizes.margin / 4,
    justifyContent: "center",
    alignItems: "center",
  },
  dotsText: {
    fontSize: 32,
  },
  centered: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  selectCount: {
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginBottom: 12,
  },
  successSelectCountLabel: {
    color: Colors.success,
  },
  errorSelectCountLabel: {
    color: Colors.error,
  },
  warningSelectCountLabel: {
    color: Colors.warning,
  },
});

export default QuestionsList;
