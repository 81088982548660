import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@Core/utils/transfomObjectKeys";
import { api } from "../index";
import { StudentCategoryProgress } from "./types";
import {
  onRemoveStudentCategoryProgressQueryStarted,
  onUpdateStudentCategoryProgressQueryStarted,
} from "./utils";

export const studentCategoryProgressesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStudentCategoryProgresses: build.query<StudentCategoryProgress[], void>({
      query: () => "student_category_progresses",
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "StudentCategoryProgress" as const,
                id,
              })),
              { type: "StudentCategoryProgress", id: "LIST" },
            ]
          : ["StudentCategoryProgress"],
    }),

    getStudentCategoryProgress: build.query<StudentCategoryProgress, number>({
      query: (id) => `student_category_progresses/${id}`,
      transformErrorResponse: (
        response: { status: string | number },
        meta,
        arg
      ) => response.status,
      providesTags: (result, error, id) => [
        { type: "StudentCategoryProgress", id },
      ],
    }),

    updateStudentCategoryProgress: build.mutation<
      StudentCategoryProgress,
      { id: number; questionId: number; answerId: number }
    >({
      query: ({ id, ...questionAnswerIds }) => ({
        url: `student_category_progresses/${id}`,
        method: "PATCH",
        body: transformKeysToSnakeCase(questionAnswerIds),
      }),

      onQueryStarted: onUpdateStudentCategoryProgressQueryStarted,
    }),

    // removeStudentCategoryProgress: build.mutation<
    //   StudentCategoryProgress,
    //   number
    // >({
    //   query: (id) => ({
    //     url: `studentCategoryProgresses/${id}`,
    //     method: "DELETE",
    //   }),
    //   onQueryStarted: onRemoveStudentCategoryProgressQueryStarted,
    // }),
  }),
  overrideExisting: false,
});

export const {
  useGetStudentCategoryProgressesQuery,
  useGetStudentCategoryProgressQuery,
  useUpdateStudentCategoryProgressMutation,
  // useRemoveStudentCategoryProgressMutation,
} = studentCategoryProgressesApi;
