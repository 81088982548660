import { useContext, useState } from "react";
import { Alert, Platform, StyleSheet, Text, View } from "react-native";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { NoRoleStackParamList } from "@navigation/types";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useCreateSchoolMutation } from "@services/api";
import Input from "@Core/Auth/components/Auth/Input";
import DECButton from "@Core/UI/components/Buttons/DECButton";
import { Colors } from "@config/colors";
import { UI } from "@config/constants";

export type CreateSchoolScreenNavigationProp = NativeStackScreenProps<
  NoRoleStackParamList,
  "CreateSchool"
>;

const CreateSchoolScreen: React.FC<CreateSchoolScreenNavigationProp> = (
  props
) => {
  const [createSchool, { isLoading, isSuccess, isError, error }] =
    useCreateSchoolMutation();
  const isBigScreen = useAppSelector(
    (state) => state.ui.dimensions.window.width > 400
  );
  const [enteredName, setEnteredName] = useState(__DEV__ ? "TestSchool" : "");

  // Type '"form"' is not assignable to type 'AccessibilityRole'.ts(2769)
  // This is a known issue from React-Native/Typescript
  return (
    <View style={[styles.screen, isBigScreen ? { alignItems: "center" } : {}]}>
      <View
        style={[
          styles.formContainer,
          isBigScreen ? { width: 320, alignItems: "center" } : {},
        ]}
      >
        <View
          style={styles.form}
          {...Platform.select({ ios: { accessibilityRole: "form" } as {} })}
        >
          <Text style={styles.mainLabel}>Adauga o scoala in platforma</Text>
          <Input
            label="Numele scolii"
            onUpdateValue={(value) => setEnteredName(value)}
            value={enteredName}
            keyboardType="email-address"
            isInvalid={enteredName.length < 2}
          />
          {isError && (
            <Text style={styles.errorText}>
              {(error as number) === 422 &&
                "Acest nume este deja folosit de alta scoala."}
            </Text>
          )}
          <View style={styles.buttons}>
            <DECButton
              onPress={() => createSchool({ name: enteredName })}
              isLoading={isLoading}
              isSuccess={isSuccess}
            >
              Creeaza scoala
            </DECButton>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Colors.backgroundPrimary,
  },
  formContainer: {
    marginTop: 64,
    marginHorizontal: 32,
    padding: 16,
    borderRadius: 8,
    backgroundColor: Colors.backgroundSecondary,
    elevation: 4,
    shadowColor: "black",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.35,
    shadowRadius: 4,
  },
  form: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
  },
  buttons: {
    marginTop: 12,
  },
  mainLabel: {
    color: Colors.darkNeutral,
    fontSize: 16,
    fontWeight: UI.font.weight.extraBold,
    textAlign: "center",
    marginBottom: 16,
  },
  errorText: {
    color: Colors.error,
  },
});

export default CreateSchoolScreen;
