import { transformKeysToSnakeCase } from "@Core/utils/transfomObjectKeys";
import { api } from "../index";
import {
  onAddStudentQueryStarted,
  onRemoveStudentQueryStarted,
  onUpdateStudentQueryStarted,
} from "./utils";
import { Student } from "./type";

export const studentsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStudents: build.query<Student[], void>({
      query: () => "students",
      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.map(({ id }) => ({
                type: "Student" as const,
                id,
              })),
              { type: "Student", id: "LIST" },
            ]
          : ["Student"];
      },
    }),
    getStudentsByGroupId: build.query<Student[], number>({
      query: (groupId) => `students?group_id=${groupId}`,
      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.map(({ id }) => ({
                type: "Student" as const,
                id,
              })),
              { type: "Student", id: `LIST_GROUP${arg}` },
            ]
          : ["Student"];
      },
    }),
    getStudent: build.query<Student, number>({
      query: (id) => `students/${id}`,
      transformErrorResponse: (
        response: { status: string | number },
        meta,
        arg
      ) => response.status,
      providesTags: (result, error, id) => [{ type: "Student", id }],
      transformResponse: (response) => {
        return response as Student;
      },
    }),

    updateStudent: build.mutation<
      Student,
      Partial<Student> & Pick<Student, "id">
    >({
      query: ({ id, ...patch }) => ({
        url: `students/${id}`,
        method: "PATCH",
        body: transformKeysToSnakeCase(patch),
      }),

      onQueryStarted: onUpdateStudentQueryStarted,
    }),

    // NOT USED
    // addStudent: build.mutation<
    //   Student,
    //   Omit<Student, "id" | "numberOfStudents">
    // >({
    //   query: (student) => ({
    //     url: `students`,
    //     method: "POST",
    //     body: transformKeysToSnakeCase(student),
    //   }),
    //   invalidatesTags: (response, error, arg) => [
    //     { type: "Student", id: response.id },
    //   ],
    //   onQueryStarted: onAddStudentQueryStarted,
    // }),

    removeStudent: build.mutation<Student, Student>({
      query: ({ id }) => {
        return {
          url: `students/${id}`,
          method: "DELETE",
        };
      },
      // invalidatesTags: (response, error, arg) => [
      //   { type: "Student", id: response.id },
      // ],
      onQueryStarted: onRemoveStudentQueryStarted,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetStudentsQuery,
  useGetStudentsByGroupIdQuery,
  useGetStudentQuery,
  // useAddStudentMutation,
  useUpdateStudentMutation,
  useRemoveStudentMutation,
} = studentsApi;
