import { StyleSheet, Text, View } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { UnauthenticatedStackParamList } from "@navigation/types";
import { useAppDispatch } from "@store/hooks";
import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import PhoneNumberInput from "../../UI/components/Inputs/PhoneNumberInput";
import DECTextButton from "@Core/UI/components/Buttons/DECTextButton";
import FlatButton from "../components/UI/FlatButton";
import { changeToEmployeeRole } from "@store/slices/roleSlice";
import { Controller, useForm } from "react-hook-form";

export type OTPLoginScreenNavigationProp = NativeStackScreenProps<
  UnauthenticatedStackParamList,
  "OTPLogin"
>;

const OTPLoginScreen: React.FC<OTPLoginScreenNavigationProp> = ({
  navigation,
}) => {
  const dispatch = useAppDispatch();

  const { control, handleSubmit, formState } = useForm<{ phoneNumber: string }>(
    {
      defaultValues: {
        phoneNumber: "",
      },
    }
  );

  const handleSubmitPhoneNumber = (data: { phoneNumber: string }) => {
    if (formState.errors.phoneNumber) {
      return;
    }
    navigation.navigate("PhoneVerification", {
      phoneNumber: data.phoneNumber.replaceAll(" ", ""),
    });
  };

  return (
    <View style={styles.screen}>
      <View style={styles.container}>
        <Text style={styles.title}>Acces student</Text>
        <Controller
          control={control}
          rules={{
            required: true,
            pattern: /[\d|\s]{9}/,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <PhoneNumberInput
              label="Telefon"
              onUpdateValue={onChange}
              value={value}
              isInvalid={false}
            />
          )}
          name="phoneNumber"
        />
        {formState.errors.phoneNumber && (
          <Text style={styles.errorLabel}>Numarul nu este valid</Text>
        )}
        <DECTextButton
          title="Continua"
          onPress={handleSubmit(handleSubmitPhoneNumber)}
        />

        <View style={styles.actions}>
          <FlatButton onPress={() => dispatch(changeToEmployeeRole())}>
            Esti profesor? Acceseaza platforma de administrare
          </FlatButton>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Colors.backgroundPrimary,
    alignItems: "center",
  },
  container: {
    marginTop: 64,
    marginHorizontal: 32,
    padding: 16,
    borderRadius: 8,
    backgroundColor: Colors.backgroundSecondary,
    elevation: 4,
    shadowColor: "black",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.35,
    shadowRadius: 4,
    maxWidth: 360,
  },
  title: {
    color: Colors.accent,
    fontSize: 24,
    marginBottom: (UI.sizes.margin * 1.618) / 2.618,
    fontWeight: UI.font.weight.bold,
  },
  verifyButton: {
    marginTop: 24,
    paddingVertical: 12,
    paddingHorizontal: 32,
    borderRadius: 8,
    backgroundColor: Colors.primary500, // or any other color
    elevation: 4,
    shadowColor: "black",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.35,
    shadowRadius: 4,
  },
  actions: {
    width: "100%",
    marginTop: UI.sizes.margin,
    borderTopWidth: 1,
    borderTopColor: Colors.secondary,
    paddingTop: UI.sizes.padding,
  },
  errorLabel: {
    color: Colors.error,
    marginBottom: 4,
  },
});
export default OTPLoginScreen;
