import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { UI } from "@config/constants";
import { StudentRequest } from "@services/api/studentRequests/type";

const RequestDetails: React.FC<{ data: StudentRequest }> = ({ data }) => {
  return (
    <View style={styles.details}>
      <Text style={styles.name}>
        {data.student.firstName} {data.student.lastName}
      </Text>
      <Text style={styles.phoneNumber}>{data.student.phoneNumber}</Text>
      <Text style={styles.group}>
        Grupa: <Text style={styles.groupName}>{data.group.name}</Text>
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  details: {
    flex: 1.618,
  },
  name: {
    fontWeight: UI.font.weight.semiBold,
    fontSize: 16,
  },
  phoneNumber: {
    fontSize: 12,
    marginVertical: 4,
  },
  group: { fontSize: 12 },
  groupName: { fontSize: 16 },
});

export default RequestDetails;
