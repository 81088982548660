import { transformKeysToSnakeCase } from "@Core/utils/transfomObjectKeys";
import { api } from "../index";
import {
  onAddTestQueryStarted,
  onAddTestQuestionQueryStarted,
  onRemoveTestQueryStarted,
  onRemoveTestQuestionQueryStarted,
  onUpdateTestQueryStarted,
} from "./utils";
import { Test } from "./type";

export const testsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTests: build.query<Test[], void>({
      query: () => "tests",
      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.map(({ id }) => ({
                type: "Test" as const,
                id,
              })),
              { type: "Test", id: "LIST" },
            ]
          : ["Test"];
      },
    }),
    getTestsByGroupId: build.query<Test[], number>({
      query: (groupId) => `tests?group_id=${groupId}`,
      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.map(({ id }) => ({
                type: "Test" as const,
                id,
              })),
              { type: "Test", id: "LIST" },
            ]
          : ["Test"];
      },
    }),
    getTest: build.query<Test, number>({
      query: (id) => `tests/${id}`,
      transformErrorResponse: (
        response: { status: string | number },
        meta,
        arg
      ) => response.status,
      providesTags: (result, error, id) => [{ type: "Test", id }],
      transformResponse: (response) => {
        return response as Test;
      },
    }),

    updateTest: build.mutation<Test, Partial<Test> & Pick<Test, "id">>({
      query: ({ id, ...patch }) => ({
        url: `tests/${id}`,
        method: "PATCH",
        body: transformKeysToSnakeCase(patch),
      }),

      onQueryStarted: onUpdateTestQueryStarted,
    }),

    addTest: build.mutation<Test, { name: string }>({
      query: (test) => ({
        url: `tests`,
        method: "POST",
        body: transformKeysToSnakeCase(test),
      }),
      invalidatesTags: (response, error, arg) => [
        { type: "Test", id: response.id },
      ],
      onQueryStarted: onAddTestQueryStarted,
    }),

    removeTest: build.mutation<Test, number>({
      query: (id) => ({
        url: `tests/${id}`,
        method: "DELETE",
      }),
      onQueryStarted: onRemoveTestQueryStarted,
    }),
    addTestQuestion: build.mutation<Test, { id: number; questionId: number }>({
      query: ({ id, questionId }) => ({
        url: `tests/${id}/questions/${questionId}`,
        method: "POST",
      }),

      onQueryStarted: onAddTestQuestionQueryStarted,
    }),
    removeTestQuestion: build.mutation<
      Test,
      { id: number; questionId: number }
    >({
      query: ({ id, questionId }) => ({
        url: `tests/${id}/questions/${questionId}`,
        method: "DELETE",
      }),

      onQueryStarted: onRemoveTestQuestionQueryStarted,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTestsQuery,
  useGetTestsByGroupIdQuery,
  useGetTestQuery,
  useAddTestMutation,
  useUpdateTestMutation,
  useRemoveTestMutation,
  useAddTestQuestionMutation,
  useRemoveTestQuestionMutation,
} = testsApi;
