import LoadingOverlay from "@Core/Auth/components/UI/LoadingOverlay";
import DECTextButton from "@Core/UI/components/Buttons/DECTextButton";
import BaseCard from "@components/UI/BaseCard/BaseCard";
import { Colors, ColorsPalette } from "@config/colors";
import { UI } from "@config/constants";
import { StudentDrawerParamList } from "@navigation/types";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { useGetQuestionCategoriesQuery } from "@services/api/questionCategories/questionCategoriesApi";
import { useGetQuestionsQuery } from "@services/api/questions/questionsApi";
import { useGetStudentCategoryProgressesQuery } from "@services/api/studentCategoryProgresses/studentCategoryProgressesApi";
import React, { useMemo } from "react";
import { View, Text, StyleSheet, Image } from "react-native";
import ProgressItem from "./ProgressItem";
import HomeScreenCard from "../HomeScreenCard";

type Navigation = NavigationProp<StudentDrawerParamList, "Home">;
type Props = {};

const LearningMediumsCard: React.FC<Props> = (props) => {
  const navigation = useNavigation<Navigation>();

  const {
    data: studentCategoryProgresses,
    isLoading: isLoadingStudentCategoryProgresses,
    isError: isErrorStudentCategoryProgresses,
  } = useGetStudentCategoryProgressesQuery();

  const {
    data: questionCategories,
    isLoading: isLoadingQuestionCategories,
    isError: isErrorQuestionCategories,
  } = useGetQuestionCategoriesQuery();

  const {
    data: questions,
    isLoading: isLoadingQuestions,
    isError: isErrorQuestions,
  } = useGetQuestionsQuery();

  const isLoading =
    isLoadingStudentCategoryProgresses ||
    isLoadingQuestions ||
    isLoadingQuestionCategories;
  const isError =
    isErrorStudentCategoryProgresses ||
    isErrorQuestions ||
    isErrorQuestionCategories;

  // Memoize processed data
  const processedData = useMemo(() => {
    if (isLoading || isError) return [];

    return studentCategoryProgresses.map((studentCategoryProgress) => {
      const questionsFromCategory = questions.filter(
        (q) => q.category.id === studentCategoryProgress.questionCategoryId
      );

      const correctlyAnsweredQuestions =
        studentCategoryProgress.answeredQuestions.filter(
          (q) => q.answeredCorrectly
        );

      let progress;
      if (questionsFromCategory.length === 0) {
        progress = 0;
      } else {
        progress =
          correctlyAnsweredQuestions.length / questionsFromCategory.length;
      }

      const label = questionCategories.find(
        (questionCategory) =>
          questionCategory.id === studentCategoryProgress.questionCategoryId
      ).name;

      return {
        ...studentCategoryProgress,
        progress,
        label,
        totalQuestionsCount: questionsFromCategory.length,
        correctlyAnsweredQuestionsCount: correctlyAnsweredQuestions.length,
      };
    });
  }, [studentCategoryProgresses, questions, isLoading, isError]);

  const handleButtonPress = () => {
    navigation.navigate("LearningMediums");
  };

  if (isLoading) {
    return <LoadingOverlay />;
  }

  if (isError) {
    return <Text style={styles.errorText}>Failed to load data.</Text>;
  }

  console.log("processedData: ", processedData);

  let avgProgress = 0;
  processedData.forEach((item) => (avgProgress += item.progress));
  avgProgress = (avgProgress / processedData.length) * 100;

  const header = (
    <View>
      <Text style={styles.progress}>{avgProgress.toFixed(0)}%</Text>
    </View>
  );

  return (
    <HomeScreenCard
      title="Mediu de invatare"
      header={header}
      buttonText="Învață"
      onButtonPress={handleButtonPress}
    >
      {processedData.map((item) => (
        <ProgressItem
          key={item.id}
          studentCategoryProgressId={item.id}
          progress={item.progress}
          label={item.label}
          totalQuestionsCount={item.totalQuestionsCount}
          correctlyAnsweredQuestionsCount={item.correctlyAnsweredQuestionsCount}
        />
      ))}
    </HomeScreenCard>
  );

  // return (
  //   <BaseCard style={styles.component}>
  //     <View style={styles.header}>
  //       <View style={styles.titleContainer}>
  //         <Text style={[styles.lightText, styles.title]}>
  //           Mediu de invatare
  //         </Text>
  //       </View>

  //       <View>
  //         <Text style={styles.progress}>{avgProgress.toFixed(0)}%</Text>
  //       </View>
  //     </View>
  //     <View style={styles.body}>
  //       {processedData.map((item) => (
  //         <ProgressItem
  //           key={item.id}
  //           studentCategoryProgressId={item.id}
  //           progress={item.progress}
  //           label={item.label}
  //           totalQuestionsCount={item.totalQuestionsCount}
  //           correctlyAnsweredQuestionsCount={
  //             item.correctlyAnsweredQuestionsCount
  //           }
  //         />
  //       ))}
  //     </View>
  //     <View style={styles.footer}>
  //       <DECTextButton
  //         containerStyle={styles.continueButton}
  //         textStyle={styles.continueButtonText}
  //         title="Învață"
  //         onPress={handleButtonPress}
  //       />
  //     </View>
  //   </BaseCard>
  // );
};

const styles = StyleSheet.create({
  errorText: {
    color: Colors.error,
  },
  component: {
    marginBottom: UI.sizes.margin,
  },
  content: {
    flex: 1,
    padding: UI.sizes.padding,
    borderColor: "red",
    borderWidth: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 4,
    marginBottom: UI.sizes.margin,
  },
  titleContainer: {
    borderBottomWidth: 2,
    borderBottomColor: Colors.darkNeutral,
    // borderBottomColor: ColorsPalette.primary[500],
  },
  title: {
    color: Colors.darkNeutral,
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: 16,
    fontWeight: "bold",
  },
  progress: {
    fontSize: 20,
    fontWeight: UI.font.weight.light,
    color: ColorsPalette.primary[500],
  },

  body: {
    flex: 1,
    marginBottom: UI.sizes.margin,
  },
  lightText: {
    fontSize: 20,
    fontWeight: "bold",
    color: Colors.darkNeutral,
  },
  footer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  continueButton: {
    backgroundColor: ColorsPalette.primary[500],
    // width: "45%",
  },
  continueButtonText: {
    color: Colors.backgroundPrimary,
  },
});

export default LearningMediumsCard;
