import BaseCard from "@components/UI/BaseCard/BaseCard";
import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import { StudentRandomTest } from "@services/api/studentRandomTest/types";
import React from "react";
import { View, Text, StyleSheet, Image } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";
import { formatDateTimeStr } from "@Core/Auth/utils/time";

const defaultTest = {
  correctAnswersCount: 20,
  endedAt: new Date().toISOString(),
  id: 1231312122,
  questions: [
    [438, 1377, 0],
    [77, 229, 1],
    [123, 367, 2],
    [26, 76, 3],
    [14, 40, 4],
    [453, 1424, 5],
    [85, 253, 6],
    [135, 469, 7],
    [101, 301, 8],
    [446, 1403, 9],
    [40, 118, 10],
    [33, 97, 11],
    [139, 482, 12],
    [448, 1408, 13],
    [66, 196, 14],
    [32, 94, 15],
    [107, 319, 16],
    [445, 1398, 17],
    [106, 316, 18],
    [94, 280, 19],
  ],

  startedAt: new Date(new Date().getTime() - 3600 * 1000).toISOString(),
  status: "passed",
  wrongAnswersCount: 0,
};

type Props = {
  test: StudentRandomTest;
  isFirst: boolean;
};

const TestHistoryResultCard: React.FC<Props> = ({
  test = defaultTest,
  isFirst,
}) => {
  const startedAtDate = new Date(test?.startedAt);
  const endedAtDate = new Date(test?.endedAt);
  const duration = test
    ? Math.floor((endedAtDate.getTime() - startedAtDate.getTime()) / 1000)
    : 3600;
  const minutesString = `${Math.floor(duration / 60)}`;
  const seconds = duration % 60;
  const secondsString = `${seconds}`.padStart(2, "0");
  const durationString = `${minutesString}:${secondsString}`;

  const dynamicContainerStyle = [
    styles.container,
    isFirst && { marginTop: UI.sizes.margin },
  ];

  const dynamicStatusBarStyle = [
    styles.statusBar,
    {
      backgroundColor:
        test?.status === "failed" ? Colors.error : Colors.success,
    },
  ];

  const dynamicTestStatusStyle = [
    test?.status === "failed" ? styles.errorText : styles.successText,
    styles.testStatusTextStyle,
  ];

  return (
    <View style={[styles.container, dynamicContainerStyle]}>
      <BaseCard style={styles.card}>
        <View style={styles.header}>
          <View style={styles.textStatusContainer}>
            {test?.status === "failed" ? (
              <Image
                source={require("@assets/icons/test/fail.png")}
                style={styles.icon}
              />
            ) : (
              <Image
                source={require("@assets/icons/test/pass.png")}
                style={styles.icon}
              />
            )}

            <Text style={dynamicTestStatusStyle}>
              {test?.status === "failed" ? "Nepromovat" : "Promovat"}
            </Text>
          </View>
          {(test?.status === "passed" || !test) && (
            <View style={styles.gradeContainer}>
              <Text style={styles.gradeText}>
                {test ? test.correctAnswersCount * 0.5 : 9.5}
              </Text>
            </View>
          )}
        </View>
        <View style={styles.details}>
          <View style={styles.detailRow}>
            <Ionicons name="timer" size={26} color={Colors.accent} />
            <Text style={styles.detailText}>Durata: {durationString}</Text>
          </View>
          <View style={styles.detailRow}>
            <AntDesign name="checkcircle" size={24} color={Colors.success} />
            <Text style={styles.detailText}>
              Raspunsuri corecte: {test ? test.correctAnswersCount : 19}
            </Text>
          </View>
          <View style={styles.detailRow}>
            <AntDesign name="closecircle" size={24} color={Colors.error} />
            <Text style={styles.detailText}>
              Raspunsuri gresite: {test ? test.wrongAnswersCount : 1}
            </Text>
          </View>
          {test?.status === "failed" && (
            <View style={styles.detailRow}>
              <AntDesign
                name={"questioncircle"}
                size={24}
                color={Colors.disabledBackground}
              />
              <Text style={styles.detailText}>
                Intrebari ramase:{" "}
                {test.questions.length -
                  test?.correctAnswersCount -
                  test?.wrongAnswersCount}
              </Text>
            </View>
          )}
        </View>
      </BaseCard>
      <View style={dynamicStatusBarStyle} />
      <View style={styles.dateContainer}>
        <Text style={styles.dateText}>
          {formatDateTimeStr(test ? test?.startedAt : new Date().toISOString())}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    marginBottom: UI.sizes.margin,
    marginHorizontal: UI.sizes.margin,
  },
  card: {
    width: "97%",
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
  },
  header: {
    marginBottom: 16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textStatusContainer: {
    flexDirection: "row",
  },
  details: {
    flexDirection: "column",
  },
  detailRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
  },
  detailText: {
    marginLeft: 8,
    color: Colors.darkNeutral,
  },
  successText: {
    color: Colors.success,
  },
  errorText: {
    color: Colors.error,
  },
  testStatusTextStyle: {
    fontWeight: UI.font.weight.bold,
    fontSize: 18,
  },
  statusBar: {
    borderTopRightRadius: UI.sizes.borderRadius,
    borderBottomRightRadius: UI.sizes.borderRadius,
    width: "3%",
  },
  icon: {
    height: 24,
    width: 24,
    marginRight: 4,
  },
  lightText: {
    color: Colors.darkNeutral,
  },
  gradeText: {
    fontSize: 24,
    fontWeight: UI.font.weight.bold,
    color: Colors.darkNeutral,
  },
  gradeContainer: {
    // borderRadius: 24,
    borderBottomColor: Colors.accent,
    borderBottomWidth: 2,
    padding: 3,
  },
  dateContainer: {
    position: "absolute",
    bottom: UI.sizes.margin,
    right: UI.sizes.margin * 2,
  },
  dateText: {
    color: "rgba(255, 255, 255, 0.8)",
    fontWeight: UI.font.weight.extraLight,
    fontSize: 12,
  },
});

export default TestHistoryResultCard;
