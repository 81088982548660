import { StudentRandomTest } from "@services/api/studentRandomTest/types";
import React from "react";
import { View, Text, StyleSheet, Image } from "react-native";
import TestHistoryResultCard from "../TestHistoryScreen/TestHistoryResultCard";
import HomeScreenCard from "./HomeScreenCard";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { StudentDrawerParamList } from "@navigation/types";
import { ColorsPalette } from "@config/colors";
import { UI } from "@config/constants";

type Props = {
  test: StudentRandomTest;
  locked: boolean;
};

type Navigation = NavigationProp<StudentDrawerParamList, "Home">;

const LastTestCard: React.FC<Props> = ({ test, locked }) => {
  const navigation = useNavigation<Navigation>();

  return (
    <HomeScreenCard
      title={"Ultimul test"}
      locked={locked}
      lockedText="Trebuie sa faci cel putin 5 teste pentru a vedea istoricul"
      onButtonPress={() => navigation.navigate("History")}
      buttonText="Vezi istoricul testelor"
      backgroundColor={ColorsPalette.primary[500]}
    >
      <TestHistoryResultCard test={test} isFirst={false} key={test?.id} />
    </HomeScreenCard>
  );
};

const styles = StyleSheet.create({
  component: {},
});

export default LastTestCard;
