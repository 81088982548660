import React, { useEffect, useMemo, useState } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { Colors } from "@config/colors";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { loginWithSms } from "../../store/auth/authThunks";
import LoadingOverlay from "../../components/UI/LoadingOverlay";
import { UI } from "@config/constants";
import { useRoute, RouteProp } from "@react-navigation/native";
import { UnauthenticatedStackParamList } from "@navigation/types";
import SixDigitsInput from "@Core/UI/components/Inputs/SixDigitsInput";
import { useGetUserDetailsQuery } from "@services/api/userDetails/userDetailsApi";
import {
  useCreateOtpMutation,
  useGetOtpQuery,
  useResendOtpMutation,
  useVerifyOtpMutation,
} from "@services/api/smsOtp/smsOtpApi";
import { formatRomanianNumber } from "../../utils/phoneNumber";
import ErrorsContainer from "./ErrorsContainer";
import { isAuthDataValid } from "@Core/Auth/utils/checkAuthDataValidity";
import { clearAuthErrors } from "@Core/Auth/store/auth/authSlice";
import ResendButton from "./ResendButton";
import DECTextButton from "@Core/UI/components/Buttons/DECTextButton";
import BaseCard from "@components/UI/BaseCard/BaseCard";

type Route = RouteProp<UnauthenticatedStackParamList, "PhoneVerification">;

const VerifyPhoneNumberScreen: React.FC<{}> = () => {
  const route = useRoute<Route>();
  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector((state) =>
    isAuthDataValid(state.auth.auth)
  );
  const loginWithSmsErrors = useAppSelector((state) => state.auth.errors);

  const {
    data: otp,
    isLoading: isLoadingOtp,
    isError: isErrorOtp,
    refetch: refetchOtp,
  } = useGetOtpQuery(route.params?.phoneNumber);
  const [createOtp, { isLoading: isLoadingCreateOtp }] = useCreateOtpMutation();
  const [
    verifyOTP,
    {
      isLoading: isLoadingVerify,
      isError: isErrorVerifyOtp,
      error: errorVerifyOtp,
      reset: resetVerifyOtp,
    },
  ] = useVerifyOtpMutation();
  const { data: userDetails, isLoading: isLoadingUserDetails } =
    useGetUserDetailsQuery(undefined, {
      skip: !isAuthenticated,
    });

  const [OTPValue, setOTPValue] = useState("");

  const handleOTPSubmit = () => {
    const parameters = {
      otpCode: OTPValue,
      phoneNumber: route.params?.phoneNumber || userDetails?.phoneNumber,
    };
    if (route.params?.phoneNumber) {
      dispatch(loginWithSms(parameters));
    } else {
      verifyOTP(parameters);
    }
  };

  const hasErrorsVerifyOTP = useMemo(
    () => (errorVerifyOtp as any)?.data?.errors || loginWithSmsErrors,
    [errorVerifyOtp, loginWithSmsErrors]
  );

  const handleRequestNewOTP = async () => {
    await createOtp(route.params?.phoneNumber || userDetails?.phoneNumber);
    resetVerifyOtp();
    dispatch(clearAuthErrors());
  };

  useEffect(() => {
    if (otp) {
      const expiresAt = new Date(otp.expiresAt).getTime();
      const now = new Date().getTime();
      const secondsLeft = expiresAt - now;

      let expirationTimeoutId = undefined;
      if (secondsLeft > 0) {
        expirationTimeoutId = setInterval(() => refetchOtp(), secondsLeft);
      }

      return () => {
        if (expirationTimeoutId !== undefined)
          clearTimeout(expirationTimeoutId);
      };
    }
  }, [otp]);

  const buttonTitle =
    otp?.expired || otp?.attemptsLeft === 0 ? "Trimite alt cod" : "Verifica";
  const buttonOnPress =
    otp?.expired || otp?.attemptsLeft === 0
      ? handleRequestNewOTP
      : handleOTPSubmit;
  const disableButton =
    isLoadingCreateOtp ||
    isLoadingVerify ||
    (OTPValue.length < 6 && !(otp?.expired || otp?.attemptsLeft === 0));

  return (
    <View style={styles.screen}>
      <BaseCard style={styles.container}>
        <View style={styles.header}>
          <Text style={styles.title}>Cod de verificare</Text>
          <Text style={styles.subtitle}>
            Ti-am trimis un cod de verificare prin SMS pe numarul{" "}
            <Text style={styles.accentText}>
              {!isLoadingUserDetails &&
                formatRomanianNumber(
                  route.params?.phoneNumber || userDetails?.phoneNumber
                )}
            </Text>
          </Text>
        </View>
        {(isLoadingOtp || isLoadingUserDetails || otp?.expiresAt === null) &&
        route.params === undefined ? (
          <LoadingOverlay />
        ) : (
          <>
            {!otp?.expired && (
              <>
                <SixDigitsInput
                  handleValueChange={(value) => setOTPValue(value)}
                />
                {isErrorVerifyOtp ||
                  (loginWithSmsErrors && (
                    <ErrorsContainer
                      errors={
                        (errorVerifyOtp as any)?.data?.errors ||
                        loginWithSmsErrors
                      }
                    />
                  ))}
              </>
            )}
            {otp?.expired && (
              <View style={styles.OTPExpirationContainer}>
                <Text style={styles.OTPExpirationWarningLabel}>
                  Codul a expirat
                </Text>
              </View>
            )}
            <View style={styles.buttonContainer}>
              <DECTextButton
                title={buttonTitle}
                disabled={disableButton}
                onPress={buttonOnPress}
              />
            </View>
            {!otp?.expired && otp?.attemptsLeft > 0 && (
              <ResendButton
                otpLastResend={otp?.lastResend}
                phoneNumber={
                  route.params?.phoneNumber || userDetails?.phoneNumber
                }
              />
            )}
          </>
        )}
      </BaseCard>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Colors.backgroundPrimary,
    alignItems: "center",
    paddingHorizontal: UI.sizes.margin,
  },
  container: {
    marginTop: 64,
    marginHorizontal: 32,
    width: "100%",
    maxWidth: 480,
    padding: 16,
    borderRadius: 8,
    backgroundColor: Colors.backgroundSecondary,
    elevation: 4,
    alignItems: "center",
  },
  header: {
    marginBottom: (UI.sizes.margin * 1.618) / 2.618,
  },
  title: {
    color: Colors.accent,
    fontSize: 24,
    marginBottom: (UI.sizes.margin * 1.618) / 2.618,
    fontWeight: UI.font.weight.bold,
  },
  subtitle: {
    color: Colors.darkNeutral,
    fontSize: 14,
  },
  accentText: {
    color: Colors.accent,
  },
  buttonContainer: {
    marginTop: UI.sizes.margin,
    width: "100%",
  },
  verifyButton: {
    marginTop: 32,
    paddingVertical: 12,
    paddingHorizontal: 32,
    borderRadius: 8,
    backgroundColor: Colors.primary500, // or any other color
    elevation: 4,
    shadowColor: "black",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.35,
    shadowRadius: 4,
  },
  buttonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },

  OTPExpirationContainer: {
    marginTop: UI.sizes.margin,
  },
  OTPExpirationLabel: {
    color: Colors.darkNeutral,
  },
  OTPExpirationWarningLabel: {
    color: Colors.warning,
    fontWeight: UI.font.weight.bold,
    fontSize: 16,
  },
});

export default VerifyPhoneNumberScreen;
