import React, { useMemo } from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { Colors } from "@config/colors";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import {
  EmployeeStackParamList,
  GroupsStackParamList,
  QuestionsStackParamList,
} from "@navigation/types";
import {
  useGetStudentsByGroupIdQuery,
  useGetStudentsQuery,
  useRemoveStudentMutation,
} from "@services/api/students/studentsApi";
import ErrorNotification from "@Core/UI/components/Feedback/ErrorNotification";
import LoadingOverlay from "@Core/Auth/components/UI/LoadingOverlay";
import { RouteProp, useRoute } from "@react-navigation/native";
import EmptyBox from "@components/UI/EmptyBox";
import TitleCard from "@components/UI/TitleCard";
import SortableList from "@Core/UI/components/Lists/SortableList/SortableList";
import { formatRomanianNumber } from "@Core/Auth/utils/phoneNumber";
import StudentTestsCount from "./StudentTestsCount";
import { UI } from "@config/constants";
import SortableListHeader from "@Core/UI/components/Lists/SortableList/SortableListHeader";
import StudentListItem from "./StudentListItem";
import StudentListHeader from "./StudentListHeader";

type Route = RouteProp<GroupsStackParamList, "GroupsShow">;
type Props = { backgroundColor?: string };

const StudentsList: React.FC<Props> = ({
  backgroundColor = Colors.backgroundSecondary,
}) => {
  const route = useRoute<Route>();
  const { data, isLoading, isFetching, isError, isSuccess } = route.params?.id
    ? useGetStudentsByGroupIdQuery(route.params.id)
    : useGetStudentsQuery();

  if (isError) {
    return <ErrorNotification />;
  }

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <TitleCard title={`Studenti`} backgroundColor={backgroundColor}>
      <StudentListHeader groupId={route.params?.id} />
      {data.length > 0 && (
        <FlatList
          data={data}
          keyExtractor={(item) => item.id?.toString()}
          renderItem={({ item, index }) => (
            <StudentListItem
              student={item}
              first={index === 0}
              last={index === data.length - 1}
              groupId={route.params?.id}
            />
          )}
        />
      )}
      {data?.length === 0 && <EmptyBox message="Aceasta lista este goala..." />}
    </TitleCard>
  );
};

const styles = StyleSheet.create({
  centered: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  emptyLabel: {
    color: Colors.darkNeutral,
  },
});

export default StudentsList;
