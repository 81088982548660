import React from "react";
import { View, StyleSheet } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import { StudentDrawerParamList } from "@navigation/types";
import HelloCard from "./HelloCard";
import TestCard from "./TestCard";
import { ScrollView } from "react-native-gesture-handler";
import {
  useGetOngoingStudentRandomTestQuery,
  useGetStudentRandomTestsQuery,
} from "@services/api/studentRandomTest/studentRandomTestsApi";
import { useGetQuestionsQuery } from "@services/api/questions/questionsApi";
import LoadingOverlay from "@Core/Auth/components/UI/LoadingOverlay";
import ErrorNotification from "@Core/UI/components/Feedback/ErrorNotification";
import LearningMediumsCard from "./LearningMediumsCard";
import LastTestCard from "./LastTestCard";
// import { AuthContext } from "../../Core/Auth/store/AuthContext";

type Props = NativeStackScreenProps<StudentDrawerParamList, "Home">;

const HomeScreen: React.FC<Props> = (props) => {
  const { navigation } = props;

  const {
    data: test,
    isLoading: isLoadingTest,
    isError: isErrorTest,
    isSuccess: isSuccessTest,
  } = useGetOngoingStudentRandomTestQuery();

  const {
    data: tests,
    isLoading: isLoadingTests,
    isError: isErrorTests,
    isSuccess: isSuccessTests,
  } = useGetStudentRandomTestsQuery();

  const {
    data: questions,
    isLoading: isLoadingQuestions,
    isError: isErrorQuestions,
  } = useGetQuestionsQuery();

  const isLoading = isLoadingQuestions || isLoadingTest || isLoadingTests;
  const isError = isErrorQuestions;

  return (
    <View style={styles.screen}>
      {isLoading ? (
        <LoadingOverlay />
      ) : isError ? (
        <ErrorNotification />
      ) : (
        <ScrollView contentContainerStyle={styles.container}>
          <HelloCard />
          <TestCard test={test} questions={questions} />
          <LearningMediumsCard />
          <LastTestCard test={tests[0]} locked={tests?.length < 5} />
        </ScrollView>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    // justifyContent: "center",
    // alignItems: "center",
    backgroundColor: Colors.backgroundPrimary,
  },
  button: {
    backgroundColor: Colors.backgroundSecondary,
    width: "100%",
    height: 64,
    marginBottom: UI.sizes.margin,
  },
  container: {
    paddingTop: UI.sizes.padding,
    paddingBottom: UI.sizes.padding * 2,
    width: "100%",
    maxWidth: 560,
    paddingHorizontal: UI.sizes.padding,
    // marginTop: UI.sizes.margin * 1.618 ** 4,
  },
});

export default HomeScreen;
