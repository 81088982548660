import React from "react";
import { View, StyleSheet } from "react-native";

import ImageButton from "@components/UI/ImageButton";
import RequestDetails from "./RequestDetails";

import { UI } from "@config/constants";
import { Colors } from "@config/colors";
import { StudentRequest } from "@services/api/studentRequests/type";
import { useUpdateStudentRequestMutation } from "@services/api/studentRequests/studentsRequestsApi";

type Props = {
  data: StudentRequest;
  first: boolean;
  last: boolean;
};

const RequesListItem: React.FC<Props> = (props) => {
  const { data, first, last } = props;

  const [updateStudentRequest, { isLoading }] =
    useUpdateStudentRequestMutation();

  const handleAccept = () => {
    if (!isLoading) {
      updateStudentRequest({ ...data, status: "accepted" });
    }
  };

  const handleReject = () => {
    if (!isLoading) {
      updateStudentRequest({ ...data, status: "rejected" });
    }
  };

  return (
    <View
      style={[
        styles.component,
        first && styles.firstItem,
        last && styles.lastItem,
      ]}
    >
      <View style={[styles.content, last ? { borderBottomWidth: 0 } : {}]}>
        <RequestDetails data={data} />
        <View style={styles.actions}>
          <ImageButton
            source={require("@assets/icons/remove.png")}
            size={32}
            onPress={handleReject}
          />
          <ImageButton
            source={require("@assets/icons/checked.png")}
            size={32}
            onPress={handleAccept}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  component: {
    width: "100%",
    minHeight: 62,
    backgroundColor: Colors.darkNeutral,
  },
  firstItem: {
    borderTopRightRadius: UI.sizes.borderRadius,
    borderTopLeftRadius: UI.sizes.borderRadius,
  },
  lastItem: {
    borderBottomRightRadius: UI.sizes.borderRadius,
    borderBottomLeftRadius: UI.sizes.borderRadius,
  },
  content: {
    flex: 1,
    flexDirection: "row",
    paddingVertical: UI.sizes.padding,
    marginHorizontal: UI.sizes.margin,
    borderBottomWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.3)",
  },
  actions: {
    flex: 1,
    flexDirection: "row",
    maxWidth: 240,
  },
});

export default RequesListItem;
