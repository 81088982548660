import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { StudentStackParamList } from "@navigation/types";
import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import UserDetailsForm from "@components/UserDetails/UserDetailsForm";
import { useAppSelector } from "@store/hooks";

type Props = NativeStackScreenProps<StudentStackParamList, "UserDetails">;

const UserDetailsScreen: React.FC<Props> = (props) => {
  const { navigation } = props;

  const isBigScreen = useAppSelector(
    (state) => state.ui.dimensions.window.width > 400
  );

  return (
    <View
      style={[styles.screen, isBigScreen && { alignItems: "center" }]}
      testID="firstView"
    >
      <View style={[styles.content, isBigScreen && { width: 320 }]}>
        <UserDetailsForm />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Colors.backgroundPrimary,
    // alignItems: "center",
  },
  content: {
    marginTop: 64,
    marginHorizontal: 32,
    padding: 16,
    borderRadius: 8,
    backgroundColor: Colors.backgroundSecondary,
    elevation: 4,
    shadowColor: "black",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.35,
    shadowRadius: 4,
  },
});

export default UserDetailsScreen;
