import React, { useEffect, useRef, useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  Pressable,
  TextInput,
  FlatList,
} from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { TestsStackParamList } from "@navigation/types";
import { Colors } from "@config/colors";
import SideMenu from "@components/UI/SideMenu/SideMenu";
import { UI } from "@config/constants";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import {
  useAddTestQuestionMutation,
  useGetTestQuery,
  useGetTestsQuery,
  useRemoveTestMutation,
  useRemoveTestQuestionMutation,
  useUpdateTestMutation,
} from "@services/api/tests/testsApi";
import TitleCard from "@components/UI/TitleCard";
import ImageButton from "@components/UI/ImageButton";
import DECTrashButton from "@Core/UI/components/Buttons/DECTrashButton";
import QuestionsList from "@components/Questions/Index/QuestionsList";
import LoadingOverlay from "@Core/Auth/components/UI/LoadingOverlay";
import DECInput from "@Core/UI/components/Inputs/DECInput";

type Props = NativeStackScreenProps<TestsStackParamList, "TestsEdit">;

const EditTestScreen: React.FC<Props> = (props) => {
  const { navigation } = props;
  const route = useRoute<RouteProp<TestsStackParamList>>();
  const questionsListRef = useRef<FlatList>();

  const {
    data: test,
    isLoading,
    isFetching,
    isError,
  } = useGetTestQuery(route.params.id);
  const [testName, setTestName] = useState("");
  // const test = data?.find((t) => t?.id === route.params.id);
  const [
    updateTest,
    {
      isLoading: isLoadingSubmit,
      isSuccess: isSuccessSubmit,
      isError: isErrorSubmit,
    },
  ] = useUpdateTestMutation();
  const [
    removeTest,
    {
      isLoading: isLoadingRemove,
      isSuccess: isSuccessRemove,
      isError: isErrorRemove,
    },
  ] = useRemoveTestMutation();

  const [
    removeTestQuestion,
    // {
    //   isLoading: isLoadingRemoveQuestion,
    //   isSuccess: isSuccessRemoveQuestion,
    //   isError: isErrorRemoveQuestion,
    // },
  ] = useRemoveTestQuestionMutation();

  const [
    addTestQuestion,
    // {
    //   isLoading: isLoadingRemoveQuestion,
    //   isSuccess: isSuccessRemoveQuestion,
    //   isError: isErrorRemoveQuestion,
    // },
  ] = useAddTestQuestionMutation();

  const handleRemove = () => {
    removeTest(route.params.id);
  };

  const handleNameInputBlur = (value) => {
    if (value.length > 3) updateTest({ id: test.id, name: value });
  };

  const handleQuestionSelect = (id: number) => {
    if (test.questionIds.includes(id)) {
      removeTestQuestion({ id: test.id, questionId: id });
    } else {
      addTestQuestion({ id: test.id, questionId: id });
    }
  };

  useEffect(() => {
    if (isSuccessRemove) {
      setTimeout(() => {
        navigation.navigate("TestsIndex");
      }, 500);
    }
  }, [isSuccessRemove]);

  useEffect(() => {
    setTestName(test?.name);
  }, [test]);

  return (
    <View style={styles.screen}>
      <View style={styles.content}>
        <View style={styles.GLDR1}>
          <SideMenu />
        </View>
        <View style={styles.GLDR2}>
          <TitleCard
            title={"Editare test"}
            rightItem={
              <ImageButton
                source={require("@assets/icons/close.png")}
                size={16}
                onPress={() => navigation.navigate("TestsIndex")}
              />
            }
          >
            {isLoading || isFetching ? (
              <LoadingOverlay />
            ) : (
              <>
                <DECInput
                  value={testName}
                  validationFn={(value) => value.length > 3}
                  errorMessage="Trebuie sa aiba cel putin 3 caractere. Recomandam sa fie numite in ordine cronologica desfasurarii cursului (Ex.: 1 - Sectiunea introductiva)"
                  onUpdateValue={(value) => setTestName(value)}
                  onBlur={(value) => handleNameInputBlur(value)}
                />
                <QuestionsList
                  isSelectableList={true}
                  selectedIds={test.questionIds}
                  onSelect={handleQuestionSelect}
                  questionsListRef={questionsListRef}
                />
              </>
            )}
            <DECTrashButton
              isLoading={isLoadingRemove}
              isSuccess={isSuccessRemove}
              isError={isErrorRemove}
              onPress={handleRemove}
            />
          </TitleCard>
          {/* </TitleCard> */}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Colors.backgroundPrimary,
  },
  content: {
    margin: UI.sizes.margin / 2,
    flex: 1,
    flexDirection: "row",
  },

  GLDR1: { flex: 1 },
  GLDR2: { flex: 1.618 + 2.618, margin: UI.sizes.margin / 2 },
  nameForm: {
    flexDirection: "row",
  },
});

export default EditTestScreen;
