export function getCurrentTimeISOWithRomanianOffset(): string {
  const date = new Date();

  // Format the date and time
  const YYYY = date.getUTCFullYear();
  const MM = String(date.getUTCMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const DD = String(date.getUTCDate()).padStart(2, "0");
  const HH = String(date.getUTCHours()).padStart(2, "0");
  const mm = String(date.getUTCMinutes()).padStart(2, "0");
  const ss = String(date.getUTCSeconds()).padStart(2, "0");
  const ms = String(date.getUTCMilliseconds()).padStart(3, "0");

  // Calculate the timezone offset
  const offset = date.getTimezoneOffset();
  const offsetSign = offset > 0 ? "-" : "+";
  const offsetHours = String(Math.floor(Math.abs(offset) / 60)).padStart(
    2,
    "0"
  );
  const offsetMinutes = String(Math.abs(offset) % 60).padStart(2, "0");

  return `${YYYY}-${MM}-${DD}T${HH}:${mm}:${ss}.${ms}${offsetSign}${offsetHours}:${offsetMinutes}`;
}

export const formatSecondsToTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
    .toString()
    .padStart(2, "0")}`;
};
