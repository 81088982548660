import { UserDetails } from "./types";
import { userDetailsApi } from "./userDetailsApi";

export const onUpdateUserDetailsQueryStarted = async (
  details: UserDetails,
  { dispatch, queryFulfilled }
) => {
  const patchResult = dispatch(
    userDetailsApi.util.updateQueryData(
      "getUserDetails",
      undefined,
      (detailsDraft) => {
        Object.assign(detailsDraft, { ...detailsDraft, ...details });
      }
    )
  );

  try {
    await queryFulfilled;
  } catch (error: any) {
    patchResult.undo();
  }
};
