import { clearAuthErrors } from "@Core/Auth/store/auth/authSlice";
import { userDetailsApi } from "../userDetails/userDetailsApi";
import { smsOtpApi } from "./smsOtpApi";

export const onVerifyOtpQueryStarted = async (
  _,
  { dispatch, queryFulfilled }
) => {
  try {
    const success = await queryFulfilled;

    if (success) {
      dispatch(
        userDetailsApi.util.updateQueryData(
          "getUserDetails",
          undefined,
          (draft) => {
            Object.assign(draft, { ...draft, phoneVerified: true });
          }
        )
      );
    }
  } catch (error: any) {
    console.log("Error in onVerifyOtpQueryStarted");
  }
};

export const onRequestNewOtpStarted = async (
  _,
  { dispatch, queryFulfilled }
) => {
  try {
    const success = await queryFulfilled;

    if (success) {
      dispatch(
        smsOtpApi.util.updateQueryData("getOtp", undefined, (draft) => {
          Object.assign(draft, { ...draft, ...success.data });
        })
      );
    }
  } catch (error: any) {
    console.log("Error in onVerifyOtpQueryStarted");
  }
};
