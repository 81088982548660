import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import { UI } from "@config/constants";

import QuestionsFiltersHeader from "./QuestionsFiltersHeader";
import QuestionsFiltersBody from "./QuestionsFiltersBody";
import { Question, QuestionCategory } from "@services/api/questions/types";
import { useGetQuestionCategoriesQuery } from "@services/api/questionCategories/questionCategoriesApi";

type Props = {
  questions: Question[];
  filteredQuestions: Question[];
  filteredCategories: number[];
  onFilterChange: (categoryId: number) => void;
};

const QuestionsListFilters: React.FC<Props> = (props) => {
  const { questions, filteredQuestions, filteredCategories, onFilterChange } =
    props;

  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const questionsCount =
    filteredQuestions?.filter(
      (question) =>
        filteredCategories.length === 0 ||
        filteredCategories.map((c) => c).includes(question.category.id)
    ).length || 0;

  return (
    <View style={styles.filtersContainer}>
      <QuestionsFiltersHeader
        // questionsCount={questionCount}
        questionsCount={`${questionsCount} / ${
          questions ? questions.length : "-"
        }`}
        filtersExpanded={filtersExpanded}
        onPress={() => setFiltersExpanded((prevValue) => !prevValue)}
      />
      {filtersExpanded && (
        <QuestionsFiltersBody
          filteredCategories={filteredCategories}
          onFilterChange={onFilterChange}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  filtersContainer: {
    marginVertical: UI.sizes.margin,
    borderBottomColor: "rgba(255, 255, 255, 0.1)",
    borderBottomWidth: 1,
  },
});

export default QuestionsListFilters;
