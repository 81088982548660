import React, { useEffect, useMemo, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { LearningMediumsStackParamList } from "@navigation/types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  useGetStudentCategoryProgressesQuery,
  useUpdateStudentCategoryProgressMutation,
} from "@services/api/studentCategoryProgresses/studentCategoryProgressesApi";
import { useGetQuestionsQuery } from "@services/api/questions/questionsApi";
import LoadingOverlay from "@Core/Auth/components/UI/LoadingOverlay";
import { Colors } from "@config/colors";
import QuestionAnswersForm from "@components/TestQuestions/QuestionAnswersForm";
import TestActions from "../TestScreen/TestActions";
import { UI } from "@config/constants";
import ProgressBar from "@Core/UI/components/ProgressBar/ProgressBar";
import LearningMediumFinishModal from "./LearningMediumFinishModal";

type Props = NativeStackScreenProps<
  LearningMediumsStackParamList,
  "LearningMediumsShow"
>;

const LearningMediumShowScreen: React.FC<Props> = (props) => {
  const { route, navigation } = props;

  const { data: questions, isLoading: isLoadingQuestions } =
    useGetQuestionsQuery();

  const {
    data: studentCateogryProgresses,
    isLoading: isLoadingStudentCateogryProgresses,
  } = useGetStudentCategoryProgressesQuery();

  const [updateStudentCategoryProgress, { isLoading: isLoadingUpdate }] =
    useUpdateStudentCategoryProgressMutation();

  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentAnswerChoice, setCurrentAnswerChoice] = useState(null);
  const [hasConfirmedAnswer, setHasConfirmedAnswer] = useState(false);

  const categoryProgress = studentCateogryProgresses.find(
    (cp) => cp.id === route.params?.id
  );

  const categoryQuestions = useMemo(
    () =>
      questions.filter(
        (q) => q.category.id === categoryProgress.questionCategoryId
      ),
    [questions, categoryProgress]
  );

  const correctlyAnsweredQuestions = useMemo(() => {
    const correctlyAnsweredQuestionsIds = categoryProgress.answeredQuestions
      .filter((cp_question) => cp_question.answeredCorrectly)
      .map((cp_question) => cp_question.questionId);

    return categoryQuestions.filter((q) =>
      correctlyAnsweredQuestionsIds.includes(q.id)
    );
  }, [questions, categoryProgress]);

  const unansweredQuestions = useMemo(() => {
    if (!categoryProgress || !questions) return [];

    const answeredQuestionIds = categoryProgress.answeredQuestions.map(
      (cp_question) => cp_question.questionId
    );

    // Questions that have never been answered
    const neverAnsweredQuestions = questions.filter(
      (q) =>
        q.category.id === categoryProgress.questionCategoryId &&
        !answeredQuestionIds.includes(q.id)
    );

    // Questions due for a retry
    const dueForRetryQuestions = categoryProgress.answeredQuestions
      .filter(
        (cp_question) =>
          cp_question.cooldown !== null && cp_question.cooldown >= 0
      )
      .map((cp_question) => {
        return {
          ...questions.find(
            (question) => question.id === cp_question.questionId
          ),
          cooldown: cp_question.cooldown,
        };
      });

    // Combine and order questions
    let combinedQuestions = [...neverAnsweredQuestions];
    dueForRetryQuestions.forEach((q) => {
      if (q.cooldown <= combinedQuestions.length) {
        combinedQuestions.splice(q.cooldown - 1, 0, q);
      } else {
        combinedQuestions.push(q);
      }
    });

    return combinedQuestions;
  }, [questions, categoryProgress]);

  useEffect(() => {
    if (!currentQuestion && unansweredQuestions.length > 0) {
      setCurrentQuestion(unansweredQuestions[0]);
    }
  }, [unansweredQuestions]);

  const handleAnswerPress = (id: number) => {
    setCurrentAnswerChoice(id);
  };

  const handleRemoveCurrentAnswerChoice = () => {
    setCurrentAnswerChoice(null);
  };

  const handleConfirmAnswerPress = () => {
    setHasConfirmedAnswer(true);

    updateStudentCategoryProgress({
      id: categoryProgress.id,
      questionId: currentQuestion.id,
      answerId: currentAnswerChoice,
    });
  };

  const handleNextQuestion = () => {
    setCurrentQuestion(unansweredQuestions[0]);
    setHasConfirmedAnswer(false);
    setCurrentAnswerChoice(null);
  };

  const handleSkipQuestion = () => {};

  if (
    isLoadingStudentCateogryProgresses ||
    isLoadingQuestions ||
    (!currentQuestion && categoryProgress?.status !== "finished")
  ) {
    return <LoadingOverlay />;
  }

  if (!categoryProgress || categoryQuestions.length === 0) {
    return <Text>No questions available.</Text>;
  }
  if (categoryProgress.status === "finished" && !currentQuestion) {
    // TODO create a nice informational modal
    return <LearningMediumFinishModal visible={true} />;
  }

  return (
    <View style={styles.screen}>
      <LearningMediumFinishModal
        visible={categoryProgress.status === "finished" && !!currentQuestion}
      />
      {categoryProgress.status === "finished" && !!currentQuestion && (
        <View style={styles.overlay} />
      )}
      <ProgressBar
        progress={correctlyAnsweredQuestions.length / categoryQuestions.length}
        displayProgressAsFraction
        minValue={0}
        currentValue={correctlyAnsweredQuestions.length}
        maxValue={categoryQuestions.length}
        style={styles.progressBar}
      />
      <QuestionAnswersForm
        question={currentQuestion}
        currentAnswerChoice={currentAnswerChoice}
        questionIndex={null}
        onAnswerPress={handleAnswerPress}
        showCurrentAnswer={hasConfirmedAnswer}
      />
      <TestActions
        currentChoice={currentAnswerChoice}
        hasAnswered={hasConfirmedAnswer}
        handleConfirmAnswerPress={handleConfirmAnswerPress}
        handleNextQuestion={handleNextQuestion}
        handleRemoveCurrentAnswerChoice={handleRemoveCurrentAnswerChoice}
        handleSkipQuestion={handleSkipQuestion}
        canSkipQuestion={false}
        isLoading={isLoadingUpdate}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Colors.backgroundPrimary,
  },
  centered: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  progressBar: {
    marginHorizontal: UI.sizes.margin,
    marginTop: UI.sizes.margin,
  },
  overlay: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, .7)",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2,
  },
});

export default LearningMediumShowScreen;
