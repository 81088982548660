import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { View, StyleSheet, ActivityIndicator } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import { Colors } from "@config/colors";
import TestProgressDisplay from "@components/Test/TestProgressDisplay";
import TestEndedPopUp from "@components/Test/TestEndedPopUp";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { StudentDrawerParamList } from "@navigation/types";

import ErrorNotification from "@Core/UI/components/Feedback/ErrorNotification";
import { useAppDispatch } from "@store/hooks";
import { api } from "@services/api";
import TestActions from "./TestActions";
import {
  useGetOngoingStudentRandomTestQuery,
  useAnswerStudentRandomTestQuestionMutation,
  useGenerateStudentRandomTestMutation,
} from "@services/api/studentRandomTest/studentRandomTestsApi";
import { useGetQuestionsQuery } from "@services/api/questions/questionsApi";
import { StudentRandomTest } from "@services/api/studentRandomTest/types";
import QuestionAnswersForm from "@components/TestQuestions/QuestionAnswersForm";
import LoadingOverlay from "@Core/Auth/components/UI/LoadingOverlay";

const isTestFinished = (test: StudentRandomTest) => test?.status !== "ongoing";
const isTestExpired = (test: StudentRandomTest) =>
  new Date().getTime() - new Date(test?.startedAt).getTime() >= 3600 * 1000;

type Props = NativeStackScreenProps<StudentDrawerParamList, "Test">;

const TestScreen: React.FC<Props> = (props) => {
  const { navigation } = props;
  const isFocused = useIsFocused();
  const dispatch = useAppDispatch();

  const {
    data: questions,
    isLoading: isLoadingQuestions,
    isError: isErrorQuestions,
  } = useGetQuestionsQuery();

  const {
    data: test,
    isLoading: isLoadingTest,
    isError: isErrorTest,
    isSuccess: isSuccessTest,
  } = useGetOngoingStudentRandomTestQuery();

  const [generateTest, { isLoading: isLoadingGenerateTest }] =
    useGenerateStudentRandomTestMutation();

  const [
    answerQuestion,
    { isLoading: isLoadingUpdateTest, isError: isErrorUpdateTest },
  ] = useAnswerStudentRandomTestQuestionMutation();

  const [currentChoice, setCurrentChoice] = useState<number | null>(null);
  const [hasAnswered, setHasAnswered] = useState(false);

  const [unansweredQuestions, setUnansweredQuestions] = useState<
    [number, number | null, number][]
  >([]);

  // TODO build a modal that ask the user if he wants to continue ongoing test
  // const [hasOngoingTest, setHasOngoingTest] = useState(false);

  const isLoading = isLoadingQuestions || isLoadingTest;
  const isError = isErrorQuestions || isErrorTest || isErrorUpdateTest;

  const resetLocalState = () => {
    setCurrentChoice(null);
    setHasAnswered(false);
  };

  const currentQuestion = useMemo(() => {
    return isLoading || !questions || unansweredQuestions.length === 0
      ? null
      : questions.find((q) => q.id === unansweredQuestions[0][0]);
  }, [isLoading, unansweredQuestions, questions]);

  const handleConfirmAnswerPress = () => {
    setHasAnswered(true);

    answerQuestion({
      questionId: currentQuestion.id,
      answerId: currentChoice,
    });
  };

  const handleAnswerPress = (id: number) => {
    !hasAnswered && setCurrentChoice(id);
  };
  const handleRemoveCurrentAnswerChoice = () => {
    setCurrentChoice(null);
  };

  const handleNextQuestion = () => {
    setUnansweredQuestions((prev) => prev.slice(1));
    resetLocalState();
  };

  const handleSkipQuestion = () => {
    setUnansweredQuestions((prev) => [...prev.slice(1), prev[0]]);
  };

  const handleStartNewTest = () => {
    generateTest();
    dispatch(api.util.invalidateTags(["StudentRandomTest"]));
    resetLocalState();
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerStyle: !isTestFinished(test) && ({ height: 92 } as {}),
      headerRight: () =>
        !(
          !test ||
          isLoadingTest ||
          test.wrongAnswersCount >= 5 ||
          isTestFinished(test)
        ) && (
          <TestProgressDisplay
            correct={test.correctAnswersCount}
            wrong={test.wrongAnswersCount}
            remaining={
              test.questions?.length -
              test.correctAnswersCount -
              test.wrongAnswersCount
            }
            startedAt={test.startedAt}
          />
        ),
    });
  }, [navigation, test, isLoadingTest]);

  useEffect(() => {
    if (!test) return;
    setUnansweredQuestions(test.questions.filter((q) => q[1] === null));
  }, [test?.id]);

  // useEffect(() => {
  //   if (!isFocused && isTestFinished(test)) {
  //     dispatch(api.util.invalidateTags(["RandomlyGeneratedTest"]));
  //     resetLocalState();
  //   }
  // }, [isFocused]);

  if (isLoading || !currentQuestion) {
    return <LoadingOverlay />;
  }

  if (isError || isErrorTest) {
    return (
      <View style={styles.screen}>
        <View style={styles.centered}>
          <ErrorNotification />
        </View>
      </View>
    );
  }

  if (isTestFinished(test) || isTestExpired(test)) {
    return (
      <View style={styles.centered}>
        <TestEndedPopUp
          wrongAnswers={test.wrongAnswersCount}
          correctAnswers={test.correctAnswersCount}
          questionsCount={test.questions.length}
          startedAt={test.startedAt}
          endedAt={test.endedAt}
          startNewTest={handleStartNewTest}
        />
      </View>
    );
  }

  return (
    <View style={styles.screen}>
      <QuestionAnswersForm
        question={currentQuestion}
        questionIndex={unansweredQuestions[0] && unansweredQuestions[0][2]}
        currentAnswerChoice={currentChoice}
        showCurrentAnswer={hasAnswered}
        onAnswerPress={handleAnswerPress}
      />
      <TestActions
        currentChoice={currentChoice}
        hasAnswered={hasAnswered}
        handleConfirmAnswerPress={handleConfirmAnswerPress}
        handleNextQuestion={handleNextQuestion}
        handleRemoveCurrentAnswerChoice={handleRemoveCurrentAnswerChoice}
        handleSkipQuestion={handleSkipQuestion}
        isLoading={isLoadingUpdateTest}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    backgroundColor: Colors.backgroundPrimary,
  },
  centered: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: Colors.primary,
  },
});

export default TestScreen;
