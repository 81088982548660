import { Group } from "./types";
import { groupsApi } from "./groupsApi";
import { accessCodeApi } from "../accessCodes/accessCodesApi";
import { studentsApi } from "../students/studentsApi";

export const onAddGroupQueryStarted = async (
  group,
  { dispatch, queryFulfilled }
) => {
  try {
    const { data } = await queryFulfilled;
    const addResult = dispatch(
      groupsApi.util.updateQueryData("getGroups", undefined, (draftGroups) => {
        draftGroups.unshift(data as Group);
      })
    );
  } catch (error: any) {
    console.log(error);
  }
};

export const onUpdateGroupQueryStarted = async (
  { id, ...patch },
  { dispatch, queryFulfilled }
) => {
  const patchResultGroup = dispatch(
    groupsApi.util.updateQueryData("getGroup", id, (draft) => {
      Object.assign(draft, patch);
    })
  );
  const patchResultList = dispatch(
    groupsApi.util.updateQueryData("getGroups", undefined, (draftGroups) => {
      const groupIndex = draftGroups.findIndex((group) => group.id === id);
      draftGroups[groupIndex] = { id, ...patch } as Group;
    })
  );
  try {
    await queryFulfilled;
  } catch (error: any) {
    patchResultGroup.undo();
    patchResultList.undo();
  }
};

export const onRemoveGroupQueryStarted = async (
  id,
  { dispatch, queryFulfilled }
) => {
  const removeGroup = dispatch(
    groupsApi.util.updateQueryData("getGroups", undefined, (draftGroups) =>
      [...draftGroups].filter((q) => q.id !== id)
    )
  );
  const removeGroupAccessCode = dispatch(
    accessCodeApi.util.updateQueryData(
      "getAccessCodes",
      undefined,
      (draftStudents) =>
        [...draftStudents].filter((accessCode) => accessCode.groupId !== id)
    )
  );
  const removeGroupStudents = dispatch(
    studentsApi.util.updateQueryData(
      "getStudents",
      undefined,
      (draftStudents) =>
        [...draftStudents].filter((student) => student.groupId !== id)
    )
  );
  try {
    await queryFulfilled;
  } catch (error: any) {
    console.log(error);
    removeGroup.undo();
    removeGroupAccessCode.undo();
    removeGroupStudents.undo();
  }
};
