import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { GroupsStackParamList } from "../types";
import LogoTitle from "@components/UI/LogoTitle";
import { Colors } from "@config/colors";
import IndexGroupsScreen from "@screens/employee/groups/IndexGroupsScreen";
import ShowGroupScreen from "@screens/employee/groups/ShowGroupScreen";

const GroupsMainStack = createNativeStackNavigator<GroupsStackParamList>();

const GroupsNavigator: React.FC<{}> = (props) => {
  return (
    <GroupsMainStack.Navigator
      initialRouteName="GroupsShow"
      screenOptions={{
        headerShown: false,
      }}
    >
      <GroupsMainStack.Screen
        name="GroupsIndex"
        component={IndexGroupsScreen}
        // options={{ headerShown: false }}
      />
      <GroupsMainStack.Screen
        name="GroupsShow"
        component={ShowGroupScreen}
        // options={{ headerShown: false }}
      />
    </GroupsMainStack.Navigator>
  );
};

export default GroupsNavigator;
