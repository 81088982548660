import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { MaterialIcons } from "@expo/vector-icons"; // Assuming you're using Expo's vector icons. Adjust if you're using a different library.
import { Colors } from "@config/colors";
import { UserDetails } from "@services/api/userDetails/types";
import { UI } from "@config/constants";
import { formatRomanianNumber } from "@Core/Auth/utils/phoneNumber";
import { School } from "@services/api/schools/types";

type Props = { userDetails: UserDetails; school: School };

const CustomDrawerHeader = (props: Props) => {
  const { userDetails, school } = props;

  return (
    <View style={styles.container}>
      <Text style={styles.nameText}>
        {userDetails.firstName} {userDetails.lastName}
      </Text>
      <View style={styles.phoneContainer}>
        <Text style={styles.phoneText}>
          {formatRomanianNumber(userDetails.phoneNumber)}
        </Text>
        <MaterialIcons name="verified" size={20} color={Colors.darkNeutral} />
      </View>
      <View style={styles.schoolContainer}>
        <Text style={styles.schoolNameText}>Scoala: {school?.name}</Text>
      </View>
      {/* <Text style={styles.accessText}>
        Access available until to be completed
      </Text> */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: UI.sizes.padding,
    borderBottomWidth: 1,
    borderBottomColor: Colors.accent,
    backgroundColor: Colors.backgroundSecondary,
    marginBottom: UI.sizes.margin,
  },
  nameText: {
    fontSize: 18,
    fontWeight: "bold",
    color: Colors.darkNeutral,
    marginBottom: 5,
  },
  phoneContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: (UI.sizes.margin * 1.618) / 2.618,
  },
  phoneText: {
    marginRight: (UI.sizes.margin * 1.618) / 2.618,
    color: Colors.darkNeutral,
  },
  studentText: {
    fontSize: 16,
    color: Colors.secondary500,
  },
  accessText: {
    fontSize: 16,
    color: Colors.secondary500,
  },
  schoolContainer: {},
  schoolNameText: {
    color: Colors.darkNeutral,
    fontWeight: UI.font.weight.light,
  },
});

export default CustomDrawerHeader;
