import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import React from "react";
import { View, Text, StyleSheet, Pressable } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";

type Props = {
  questionsCount: string;
  filtersExpanded: boolean;
  onPress: () => void;
};
const QuestionsFiltersHeader: React.FC<Props> = ({
  questionsCount,
  filtersExpanded,
  onPress,
}) => {
  return (
    <View style={styles.filtersContainerHead}>
      <Text style={styles.label}>Nr. intrebari: {questionsCount}</Text>
      <Pressable style={styles.filterLabelContainer} onPress={onPress}>
        <Text style={styles.label}>Filtre</Text>
        {filtersExpanded ? (
          <MaterialIcons
            name="arrow-drop-up"
            size={18}
            color={Colors.darkNeutral}
          />
        ) : (
          <MaterialIcons
            name="arrow-drop-down"
            size={18}
            color={Colors.darkNeutral}
          />
        )}
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  filtersContainerHead: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: UI.sizes.padding / 2,
  },
  filterLabelContainer: {
    flexDirection: "row",
  },
  label: { color: Colors.darkNeutral },
  categoryFilterCard: {
    borderRadius: UI.sizes.borderRadius,
    justifyContent: "center",
    backgroundColor: Colors.backgroundPrimary,
    padding: UI.sizes.padding / 2,
    marginRight: UI.sizes.margin / 2,
    marginBottom: UI.sizes.margin / 2,
    width: "23%",
  },
  highlightedCategoryFilterCard: {
    borderWidth: 1,
    borderColor: Colors.darkNeutral,
  },
  categoryFilterLabel: {
    // flex: 1,
    flexWrap: "wrap",
    color: Colors.darkNeutral,
    textAlignVertical: "center",
    // textAlign: "center",
    // justiContent,
  },
});

export default QuestionsFiltersHeader;
