export const formatRomanianNumber = (rawNumber) => {
  // Remove all non-numeric characters
  const sanitizedNumber = rawNumber?.replace(/\D/g, "");
  // Ensure the number is of valid length (10 digits including country code "40")
  if (
    sanitizedNumber.length !== 11 ||
    sanitizedNumber.substring(0, 2) !== "40"
  ) {
    // throw new Error("Invalid Romanian phone number");
  }

  // Format the number
  const formattedNumber = `+${sanitizedNumber.substring(
    0,
    2
  )} (${sanitizedNumber.substring(2, 5)}) ${sanitizedNumber.substring(
    5,
    8
  )} ${sanitizedNumber.substring(8, 11)}`;

  return formattedNumber;
};
