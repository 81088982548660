import React, { useState } from "react";
import { StyleSheet, Pressable, Image } from "react-native";

type Props = { source: any; size: number; onPress: () => void };
const ImageButton: React.FC<Props> = (props) => {
  const { source, size, onPress } = props;
  const [isHovered, setIsHovered] = useState(false);

  const sizes = {
    width: size,
    height: size,
  };

  const hoveredSizes = {
    width: size + 2,
    height: size + 2,
  };

  return (
    <Pressable
      style={styles.component}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
      onPress={onPress}
    >
      <Image source={source} style={[isHovered ? hoveredSizes : sizes]} />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  component: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default ImageButton;
