import React, { useEffect } from "react";
import { View, Text, StyleSheet, Dimensions } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { EmployeeStackParamList } from "../../navigation/types";
import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import SideMenu from "@components/UI/SideMenu/SideMenu";
import StudentRequestsList from "@components/StudentRequests/StudentRequestsList";
import { dummyData } from "@store/dummyData";
import GroupsContent from "@components/Groups/Index/GroupsIndexContent";
import { useGetStudentRequestsQuery } from "@services/api/studentRequests/studentsRequestsApi";
import AccessCodesList from "@components/AccessCodes/AccessCodesList";
import { useGetAccessCodesQuery } from "@services/api/accessCodes/accessCodesApi";
import StudentsList from "@components/Students/StudentsList";

type Props = NativeStackScreenProps<EmployeeStackParamList, "Home">;

const DashboardScreen = (props: Props) => {
  const { navigation } = props;

  // const {
  //   data: studentRequests,
  //   isLoading,
  //   isFetching,
  //   isError,
  // } = useGetStudentRequestsQuery();
  const {
    data: accessCodes,
    isLoading: isLoadingAccessCodes,
    isError: isErrorAccessCodes,
  } = useGetAccessCodesQuery();

  return (
    <View style={styles.screen}>
      <View style={styles.content}>
        <View style={[styles.GLDR1, styles.borderRed]}>
          <SideMenu />
        </View>
        <View style={[styles.GLDR2, styles.borderRed]}>
          <View style={styles.topSection}>
            <StudentRequestsList />
          </View>
          <View style={styles.bottomSection}>
            <AccessCodesList
              accessCodes={accessCodes}
              isLoading={isLoadingAccessCodes}
            />
          </View>
        </View>
        <View
          style={[
            dummyData.studentRequests.length > 0
              ? styles.GLDR3
              : styles.GLDR2plus3,
            styles.borderRed,
          ]}
        >
          <View style={styles.topSection}>
            <GroupsContent />
          </View>
          <View style={styles.bottomSection}>
            <StudentsList />
          </View>
        </View>
      </View>
    </View>
  );
};

// const { width, height } = Dimensions.get("window");

// const widthRatio = width / (width + height);
// const heightRatio = height / (width + height);

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Colors.backgroundPrimary,
  },
  content: {
    margin: UI.sizes.margin / 2,
    flex: 1,
    flexDirection: "row",
    // borderColor: "red", // TODO delete
    // borderWidth: 1, // TODO delete
  },
  borderRed: {
    // borderColor: "red", // TODO delete
    // borderWidth: 1, // TODO delete
  },
  topSection: { flex: 1, marginBottom: UI.sizes.margin / 2 },
  bottomSection: { flex: 1, marginTop: UI.sizes.margin / 2 },
  GLDR1: { flex: 1 },
  GLDR11: { flex: 1, flexDirection: "row" },
  GLDR111: { flex: 1 },
  GLDR112: { flex: 1.618 },
  GLDR12: { flex: 1.618, margin: UI.sizes.margin / 2 },
  GLDR2: { flex: 1.618, margin: UI.sizes.margin / 2 },
  GLDR3: { flex: 2.618, margin: UI.sizes.margin / 2 },
  GLDR2plus3: { flex: 1.618 + 2.618, margin: UI.sizes.margin / 2 },
});

export default DashboardScreen;
