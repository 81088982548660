import React from "react";
import { Text, StyleSheet } from "react-native";
import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import { Question } from "@services/api/questions/types";
import { FuseResultMatch } from "fuse.js";

type Props = {
  question: Question & { matches: FuseResultMatch[] };
  isExpanded: boolean;
};

const QuestionListItemPrompt: React.FC<Props> = ({ question, isExpanded }) => {
  // Function to construct the highlighted prompt
  const renderHighlightedPrompt = () => {
    if (!question.matches?.length) {
      return <Text style={styles.prompt}>{question.prompt}</Text>;
    }

    const matches = question.matches[0].indices;
    let lastIndex = 0;
    const parts = matches.reduce((acc, [start, end]) => {
      // Text before match
      if (start > lastIndex) {
        acc.push(
          <Text key={lastIndex}>{question.prompt.slice(lastIndex, start)}</Text>
        );
      }
      // Matched text
      acc.push(
        <Text key={start} style={styles.highlightedText}>
          {question.prompt.slice(start, end + 1)}
        </Text>
      );
      lastIndex = end + 1;
      return acc;
    }, []);

    // Text after the last match
    if (lastIndex < question.prompt.length) {
      parts.push(
        <Text key={lastIndex}>{question.prompt.slice(lastIndex)}</Text>
      );
    }

    return parts;
  };

  return (
    <Text style={styles.prompt} numberOfLines={isExpanded ? undefined : 1}>
      {renderHighlightedPrompt()}
    </Text>
  );
};

const styles = StyleSheet.create({
  prompt: {
    fontWeight: UI.font.weight.medium,
    fontSize: 14,
    color: Colors.darkNeutral,
    textAlignVertical: "center",
  },
  highlightedText: {
    color: Colors.accent,
    fontWeight: "bold", // Optional: makes it easier to see the highlighted text
  },
});

export default QuestionListItemPrompt;
