import React, { useEffect } from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import LogoTitle from "@components/UI/LogoTitle";
import { Colors } from "@config/colors";
import { StudentStackParamList } from "@navigation/types";
import UserDetailsScreen from "@screens/student/UserDetailsScreen";
import { useGetUserDetailsQuery } from "@services/api/userDetails/userDetailsApi";
import LoadingOverlay from "@Core/Auth/components/UI/LoadingOverlay";
import StudentDrawerNavigator from "./StudentDrawerNavigator";
import { useGetUserStudentRequestQuery } from "@services/api/studentRequests/studentsRequestsApi";
import PendingRequestScreen from "@screens/student/PendingRequestScreen";
import JoinSchoolScreen from "@screens/none/JoinSchoolScreen";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import MenuButton from "@components/UI/SideMenu/MenuButton";
import { useAppDispatch } from "@store/hooks";
import { logout } from "@Core/Auth/store/auth/authThunks";
import { UI } from "@config/constants";
import { StyleProp, View } from "react-native";

const StudentMainStack = createNativeStackNavigator<StudentStackParamList>();

const StudentNavigator: React.FC<{}> = (props) => {
  const navigation = useNavigation<NavigationProp<StudentStackParamList>>();
  const dispatch = useAppDispatch();

  const { data: userDetails, isLoading: isLoadingUserDetails } =
    useGetUserDetailsQuery();
  const { data: studentRequest, isLoading: isLoadingStudentRequest } =
    useGetUserStudentRequestQuery();

  const isDetailsComplete = userDetails?.firstName && userDetails?.lastName;
  const isStudentPending =
    studentRequest?.status === "pending" || userDetails?.role !== "student";
  const isStudentWithDetails =
    userDetails?.role === "student" && isDetailsComplete;

  useEffect(() => {
    if (isLoadingUserDetails || isLoadingStudentRequest) return;

    if (!isDetailsComplete) {
      navigation.reset({ index: 0, routes: [{ name: "UserDetails" }] });
    } else if (isStudentPending) {
      navigation.reset({
        index: 0,
        routes: [{ name: "PendingSchoolRequest" }],
      });
    } else if (isStudentWithDetails) {
      navigation.reset({
        index: 0,
        routes: [{ name: "MainDrawer", params: { screen: "Home" } }],
      });
    } else if (!studentRequest) {
      navigation.reset({
        index: 0,
        routes: [{ name: "JoinSchool" }],
      });
    }
  }, [navigation, userDetails, studentRequest, isLoadingUserDetails]);

  if (isLoadingUserDetails || isLoadingStudentRequest) {
    return <LoadingOverlay />;
  }

  return (
    <StudentMainStack.Navigator
      initialRouteName="Loading"
      screenOptions={{
        headerStyle: {
          backgroundColor: Colors.backgroundSecondary,
          borderBottomWidth: 0,
        } as StyleProp<{ backgroundColor?: string }>,
        headerTintColor: "#fff",
        headerTitleStyle: {
          fontWeight: "bold",
        },
        headerTitle: (navigation) => <LogoTitle />,
      }}
    >
      {!isDetailsComplete && (
        <StudentMainStack.Screen
          name="UserDetails"
          component={UserDetailsScreen}
        />
      )}
      {isStudentWithDetails && (
        <StudentMainStack.Screen
          name="MainDrawer"
          component={StudentDrawerNavigator}
          options={{ headerShown: false }}
        />
      )}
      {!studentRequest ? (
        <StudentMainStack.Screen
          name="JoinSchool"
          component={JoinSchoolScreen}
          options={{
            headerShown: true,
            headerRight: () => (
              <View style={{ marginRight: UI.sizes.margin }}>
                <MenuButton
                  onPress={() => dispatch(logout())}
                  hoverColor={Colors.error}
                >
                  Logout
                </MenuButton>
              </View>
            ),
          }}
        />
      ) : (
        isStudentPending && (
          <StudentMainStack.Screen
            name="PendingSchoolRequest"
            component={PendingRequestScreen}
          />
        )
      )}
    </StudentMainStack.Navigator>
  );
};

export default StudentNavigator;
