import React, { useMemo, useState } from "react";
import { View, Text, StyleSheet, Dimensions, Pressable } from "react-native";
import ChartLegend from "./ChartLegend";
import { Colors } from "@config/colors";
import { StudentRandomTest } from "@services/api/studentRandomTest/types";
import { UI } from "@config/constants";
import { ContributionGraph, LineChart } from "react-native-chart-kit";
import {
  getLast28DaysNames,
  getLast7DaysNames,
  getNumberOfTestsForEachDay,
  getNumberOfTestsPerDay,
  getNumberOfTestsPerWeek,
  totalTestsChartLegend,
} from "./utils";
import { useAppSelector } from "@store/hooks";
import BaseCard from "@components/UI/BaseCard/BaseCard";

type Props = { tests: StudentRandomTest[] };

const ActivityChart: React.FC<Props> = ({ tests }) => {
  const isSmallScreen = useAppSelector(
    (state) => state.ui.dimensions.window.width < 850
  );
  const [isShowingLongRange, setIsShowingLongRange] = useState(false);

  const numberOfTestsPerDay = useMemo(
    () =>
      getNumberOfTestsPerDay(tests, isShowingLongRange).map((value, index) => {
        const currentTime = new Date();
        const currentDate = currentTime.getDate();
        currentTime.setDate(
          currentDate - getNumberOfTestsPerDay.length + index
        );

        return {
          date: `${currentTime.getFullYear()}-${currentTime.getMonth()}-${currentTime.getDate()}`,
          count: value[0],
        };
      }),
    [tests?.length, isShowingLongRange]
  );

  const numberOfTestsPerWeek = useMemo(
    () => getNumberOfTestsPerWeek(tests),
    [tests?.length]
  );

  const handlePeriodChange = () => {
    setIsShowingLongRange((prev) => !prev);
  };

  const dataToShow = !isShowingLongRange
    ? numberOfTestsPerDay
    : isSmallScreen
    ? numberOfTestsPerWeek
    : numberOfTestsPerDay;

  const labelsToShow = !isShowingLongRange
    ? getLast7DaysNames()
    : isSmallScreen
    ? ["1", "2", "3", "4"]
    : getLast28DaysNames().map((value, index) =>
        (index + 1) % 2 === 0 ? value : ""
      );

  const currentDate = new Date();
  const startOfTodayDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    0,
    0,
    0
  );

  const endDateForChart = new Date(
    startOfTodayDate.getTime() - 28 * 24 * 3600 * 1000
  );

  const commitsData = getNumberOfTestsForEachDay(tests).map((n, index) => {
    const dateTime = new Date(new Date().getTime() - index * 24 * 3600 * 1000);

    return { date: dateTime.toDateString(), count: n };
  });

  const chartConfig = {
    backgroundGradientFrom: Colors.backgroundSecondary,
    backgroundGradientTo: Colors.backgroundSecondary,
    // backgroundGradientFromOpacity: 1,
    // backgroundGradientToOpacity: 1,
    color: (opacity = 1) => `rgba(255, 229, 114, ${opacity})`,
    strokeWidth: 2, // optional, default 3
    barPercentage: 0.5,
    // useShadowColorFromDataset: false, // optional
  };

  return (
    <BaseCard style={styles.chartCard}>
      <View style={styles.chartHeader}>
        <Text style={[styles.lightText, styles.boldText]}>
          Activitate zilnica (chestionare)
        </Text>
      </View>
      <ContributionGraph
        values={commitsData}
        numDays={91}
        tooltipDataAttrs={null}
        endDate={new Date()}
        // endDate={endDateForChart}
        // horizontal
        width={Dimensions.get("window").width - 64}
        height={Dimensions.get("window").height / 4}
        chartConfig={chartConfig}
      />
    </BaseCard>
  );
};

const styles = StyleSheet.create({
  chartCard: {
    marginTop: UI.sizes.margin,
    marginHorizontal: UI.sizes.margin,
    borderRadius: UI.sizes.borderRadius,
    padding: UI.sizes.padding,
    backgroundColor: Colors.backgroundSecondary,
  },
  chartHeader: {
    backgroundColor: Colors.backgroundSecondary,
    paddingHorizontal: UI.sizes.padding,
    paddingBottom: UI.sizes.padding,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  chartFooter: {
    backgroundColor: Colors.backgroundSecondary,
    paddingHorizontal: UI.sizes.padding,
    paddingVertical: UI.sizes.padding / 2,
    flexDirection: "row",
  },
  boldText: {
    fontWeight: UI.font.weight.bold,
  },
  lightText: {
    // fontWeight: UI.font.weight.light,
    color: Colors.darkNeutral,
  },

  darkText: {
    color: Colors.backgroundSecondary,
  },
});

export default ActivityChart;
