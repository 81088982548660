interface GenericObject {
  [key: string]: any;
}

type CamelCase<T extends Record<string, unknown>> = {
  [K in keyof T as SnakeToCamel<K & string>]: T[K];
};

type SnakeToCamel<S extends string> = S extends `${infer T}_${infer U}`
  ? `${Lowercase<T>}${Capitalize<SnakeToCamel<U>>}`
  : S;

const transformKeysTo = <T extends GenericObject>(
  obj: T,
  matcher: RegExp,
  replaceFn: (match: string) => string
) => {
  if (Array.isArray(obj)) {
    return obj.map((item) => {
      if (typeof item === "object") {
        return transformKeysTo(item, matcher, replaceFn);
      }
      return item;
    });
  } else if (obj === null) {
    return null;
  } else {
    const newObj: any = {};
    for (const key in obj) {
      const newKey = key.replace(matcher, replaceFn);
      if (Array.isArray(obj[key])) {
        newObj[newKey] = transformKeysTo(obj[key], matcher, replaceFn);
      } else if (obj[key] === null) {
        newObj[newKey] = null;
      } else if (typeof obj[key] === "object") {
        newObj[newKey] = transformKeysTo(obj[key], matcher, replaceFn);
      } else {
        newObj[newKey] = obj[key];
      }
    }
    return newObj as unknown; //CamelCase<T>;
  }
};

// Converts object with a snake_case to camelCase
export const transformKeysToCamelCase = <T extends GenericObject>(obj: T) => {
  return transformKeysTo(obj, /(_\w)/g, (match) => match[1].toUpperCase());
};

export const transformKeysToSnakeCase = <T extends GenericObject>(obj: T) => {
  return transformKeysTo(
    obj,
    /[A-Z]{1}/g,
    (match) => `_${match[0].toLowerCase()}`
  );
};
