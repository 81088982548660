import DECTrashButton from "@Core/UI/components/Buttons/DECTrashButton";
import ErrorNotification from "@Core/UI/components/Feedback/ErrorNotification";
import BaseCard from "@components/UI/BaseCard/BaseCard";
import NewFormLink from "@components/UI/BaseCard/NewFormLink";
import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import { GroupsStackParamList } from "@navigation/types";
import { RouteProp, useRoute } from "@react-navigation/native";
import {
  useGenerateNewAccessCodeMutation,
  // useGetAccessCodeByGroupIdQuery,
  useGetAccessCodesQuery,
  useRemoveAccessCodeMutation,
} from "@services/api/accessCodes/accessCodesApi";
import { Spinner } from "native-base";
import React, { useMemo } from "react";
import { View, Text, StyleSheet } from "react-native";

type Route = RouteProp<GroupsStackParamList, "GroupsShow">;

const AccessCodeCard: React.FC<{}> = () => {
  const route = useRoute<Route>();
  const [
    generateNewAccessCode,
    { isLoading: isLoadingGenerate, isError: isErrorGenerate },
  ] = useGenerateNewAccessCodeMutation();

  const { data: accessCodes, isLoading: isLoadingAccessCodes } =
    useGetAccessCodesQuery();

  const [
    removeCode,
    {
      isLoading: isLoadingRemove,
      isError: isErrorRemove,
      isSuccess: isSuccessRemove,
      reset: resetRemove,
    },
  ] = useRemoveAccessCodeMutation();

  const handleGenerate = () => {
    generateNewAccessCode(route.params.id);
    resetRemove();
  };

  const accessCode = useMemo(
    () => accessCodes?.find((a) => a.groupId === route.params.id),
    [accessCodes, route]
  );

  if (isErrorGenerate) {
    return <ErrorNotification />;
  }

  // if (isLoadingGenerate || isLoadingAccessCodes) {
  //   return <LoadingOverlay />;
  // }

  return (
    <BaseCard
      style={styles.component}
      backgroundColor={Colors.backgroundPrimary}
    >
      <View style={styles.content}>
        {isLoadingAccessCodes || isLoadingGenerate ? (
          <Spinner />
        ) : accessCode ? (
          <>
            <Text style={styles.code}>
              {accessCode.code.slice(0, 3) + " " + accessCode.code.slice(3)}
            </Text>
            <View style={styles.trashButtonContainer}>
              <DECTrashButton
                isLoading={isLoadingRemove}
                isError={isErrorRemove}
                isSuccess={isSuccessRemove}
                onPress={() => removeCode(accessCode.id)}
              />
            </View>
          </>
        ) : (
          <NewFormLink onPress={handleGenerate} text="Generaza cod de acces" />
        )}
      </View>
    </BaseCard>
  );
};

const styles = StyleSheet.create({
  component: {
    marginBottom: (UI.sizes.margin * 1.618) / 2.618,
  },
  content: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  code: {
    color: Colors.darkNeutral,
    fontSize: 32,
  },
  trashButtonContainer: {
    position: "absolute",
    right: 0,
  },
});

export default AccessCodeCard;
