import { useEffect, useState } from "react";
import { Alert, StyleSheet, Text, View } from "react-native";
import FlatButton from "../UI/FlatButton";
import AuthForm from "./AuthForm";
import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import DECTextButton from "@Core/UI/components/Buttons/DECTextButton";
import { changeToStudentRole } from "@store/slices/roleSlice";

const AuthContent = ({ onAuthenticate, navigation, isLogin = false }) => {
  const dispatch = useAppDispatch();
  const errors = useAppSelector((state) => state.auth.errors);
  const isBigScreen = useAppSelector(
    (state) => state.ui.dimensions.window.width > 400
  );

  const [credentialsInvalid, setCredentialsInvalid] = useState({
    email: false,
    password: false,
    phoneNumber: false,
    firstName: false,
    lastName: false,
  });

  const switchAuthModeHandler = () => {
    if (isLogin) {
      navigation.replace("Signup");
    } else {
      navigation.replace("Login");
    }
  };

  const submitHandler = (credentials: {
    email: string;
    password: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
  }) => {
    let { email, password, phoneNumber, firstName, lastName } = credentials;

    email = email.trim();
    password = password.trim();
    phoneNumber = phoneNumber.replace(" ", "");

    const emailIsValid = email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
    const passwordIsValid = password.length >= 5;
    const phoneNumberIsValid = phoneNumber.length > 10;
    const firstNameIsValid = firstName.length > 2;
    const lastNameIsValid = lastName.length > 2;

    if (
      !emailIsValid ||
      !passwordIsValid ||
      (!isLogin && !phoneNumberIsValid)
    ) {
      Alert.alert("Date incorecte", "Te rugam sa verific datele introduse.");
      setCredentialsInvalid({
        email: !emailIsValid,
        password: !passwordIsValid,
        phoneNumber: !phoneNumberIsValid,
        firstName: !firstNameIsValid,
        lastName: !lastNameIsValid,
      });
      return;
    }
    onAuthenticate({ email, password, lastName, firstName, phoneNumber });
  };

  return (
    <View
      style={[styles.container, isBigScreen && { alignItems: "center" }]}
      testID="firstView"
    >
      <View style={[styles.authContent, isBigScreen && { width: 320 }]}>
        <Text style={styles.title}>Acces profesor</Text>
        <AuthForm
          isLogin={isLogin}
          onSubmit={submitHandler}
          credentialsInvalid={credentialsInvalid}
        />
        {errors?.form && (
          <View style={styles.errorContainer}>
            {errors.form.map((error) => (
              <Text style={styles.errorLabel}>{error}</Text>
            ))}
          </View>
        )}
        <View style={styles.actions}>
          <FlatButton onPress={switchAuthModeHandler}>
            {isLogin
              ? "Nu ai cont? Inregistreaza-te"
              : "Ai cont deja? Logheaza-te in cont"}
          </FlatButton>
          <View style={styles.breakLine} />
          <FlatButton onPress={() => dispatch(changeToStudentRole())}>
            Esti cursant? Du-te la platforma studentilor
          </FlatButton>
        </View>
      </View>
    </View>
  );
};

export default AuthContent;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.backgroundPrimary,
  },
  authContent: {
    marginTop: 64,
    marginHorizontal: 32,
    padding: 16,
    borderRadius: 8,
    backgroundColor: Colors.backgroundSecondary,
    elevation: 4,
    shadowColor: "black",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.35,
    shadowRadius: 4,
  },
  title: {
    color: Colors.accent,
    fontSize: 24,
    marginBottom: (UI.sizes.margin * 1.618) / 2.618,
    fontWeight: UI.font.weight.bold,
  },
  errorContainer: {
    marginTop: UI.sizes.margin,
  },
  errorLabel: {
    width: "100%",
    textAlign: "center",
    color: Colors.error,
  },
  actions: {
    width: "100%",
    marginTop: UI.sizes.margin,
  },
  breakLine: {
    borderBottomColor: Colors.accent,
    borderBottomWidth: 1,
    marginVertical: 8,
  },
});
