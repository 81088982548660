import { Colors } from "@config/colors";
import { UnauthenticatedStackParamList } from "@navigation/types";
import { RouteProp, useRoute } from "@react-navigation/native";
import { useResendOtpMutation } from "@services/api/smsOtp/smsOtpApi";
import React, { useEffect, useMemo, useState } from "react";
import { View, Text, StyleSheet, Pressable } from "react-native";

type Route = RouteProp<UnauthenticatedStackParamList, "PhoneVerification">;

type Props = { phoneNumber: string; otpLastResend: string };

const ResendButton: React.FC<Props> = ({ phoneNumber, otpLastResend }) => {
  const [OTPResendTimer, setOTPResendTimer] = useState(
    120 - ~~((new Date().getTime() - new Date(otpLastResend).getTime()) / 1000)
  );
  const [resendOTP, { isLoading: isLoadingResend }] = useResendOtpMutation();

  const timerFormatted = `${~~(OTPResendTimer / 60)}:${String(
    OTPResendTimer % 60
  ).padStart(2, "0")}`;

  const handleResendOTP = () => {
    resendOTP(phoneNumber);
  };

  useEffect(() => {
    const resendIntervalID = setInterval(
      () => setOTPResendTimer((prev) => (prev - 1 >= 0 ? prev - 1 : 0)),
      1000
    );

    return () => {
      clearInterval(resendIntervalID);
    };
  }, [otpLastResend]);

  return (
    <Pressable
      onPress={handleResendOTP}
      disabled={OTPResendTimer > 0 || isLoadingResend}
    >
      <Text style={styles.resendText}>
        Trimite codul din nou
        {OTPResendTimer > 0 && ` in ${timerFormatted}`}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  resendText: {
    marginTop: 12,
    color: Colors.secondary500,
    textDecorationLine: "underline",
  },
});

export default ResendButton;
