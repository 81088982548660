import { Colors } from "@config/colors";
import { EmployeeStackParamList } from "@navigation/types";
import { useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useAppSelector } from "@store/hooks";
import React from "react";
import { Pressable, Text, StyleSheet } from "react-native";

// type Props = { children: string; tintColor?: string };

const LogoTitle = () => {
  const route = useRoute();
  const navigation =
    useNavigation<NativeStackNavigationProp<EmployeeStackParamList>>();

  const screenWidth = useAppSelector(
    (state) => state.ui.dimensions.window.width
  );

  // const dynamicTextSize = Math.min(11 + screenWidth / 100, 28); // 36 is the upper limit
  let dynamicTextSize;
  if (screenWidth <= 320) {
    dynamicTextSize = Math.min(10 + screenWidth / 115, 20); // More aggressive scaling for small screens
  } else {
    dynamicTextSize = Math.min(11 + screenWidth / 100, 28); // Existing scaling logic
  }

  const dynamicStyles = StyleSheet.create({
    mainText: {
      fontSize: dynamicTextSize + 9,
    },
    connectionText: {
      fontSize: dynamicTextSize + 5,
    },
    dotText: {
      fontSize: dynamicTextSize,
    },
  });

  return (
    <Pressable
      style={styles.component}
      onPress={() => route.name != "Home" && navigation.navigate("Home")}
    >
      <Text style={[styles.text, styles.mainText, dynamicStyles.mainText]}>
        Arme
      </Text>
      <Text
        style={[
          styles.text,
          styles.connectionText,
          dynamicStyles.connectionText,
        ]}
      >
        si
      </Text>
      <Text style={[styles.text, styles.mainText, dynamicStyles.mainText]}>
        Munitii
      </Text>
      <Text style={[styles.text, styles.dotText, , dynamicStyles.dotText]}>
        .ro
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  component: {
    flexDirection: "row",
    alignItems: "flex-end",
  },
  text: {
    color: Colors.darkNeutral,
    fontSize: 28,
    fontWeight: "800",
    textAlignVertical: "bottom",
  },
  mainText: {
    color: Colors.darkNeutral,
  },
  connectionText: {
    color: Colors.accent,
    fontSize: 20,
    marginBottom: 1,
  },
  dotText: {
    color: Colors.accent,
    fontSize: 14,
    marginBottom: 3,
  },
});

export default LogoTitle;
