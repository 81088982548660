import { Colors } from "@config/colors";
import React from "react";
import { View, Text, StyleSheet } from "react-native";

type Props = { questionsCount: number };

const TestErrors: React.FC<Props> = ({ questionsCount }) => {
  if (questionsCount == 20) return;
  return (
    <Text style={styles.text}>
      Sunt prea {questionsCount < 20 ? "putine" : "multe"} intrebari
    </Text>
  );
};

const styles = StyleSheet.create({
  text: {
    color: Colors.error,
  },
});

export default TestErrors;
