import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { QuestionsStackParamList } from "@navigation/types";
import { Colors } from "@config/colors";
import SideMenu from "@components/UI/SideMenu/SideMenu";
import { UI } from "@config/constants";
import QuestionForm from "@components/Questions/Form/QuestionForm";
import TitleCard from "@components/UI/TitleCard";
import ImageButton from "@components/UI/ImageButton";
import { useAddQuestionMutation } from "@services/api/questions/questionsApi";
import { Question } from "@services/api/questions/types";

type Props = NativeStackScreenProps<QuestionsStackParamList, "QuestionsNew">;

const NewQuestionScreen: React.FC<Props> = (props) => {
  const { navigation } = props;
  const [addQuestion, { isLoading, isSuccess, isError }] =
    useAddQuestionMutation();

  const handleSubmit = (data: Question) => {
    addQuestion(data);
  };

  return (
    <View style={styles.screen}>
      <View style={styles.content}>
        <View style={styles.GLDR1}>
          <SideMenu />
        </View>
        <View style={styles.GLDR2}>
          <TitleCard
            title={"Intrebare noua"}
            rightItem={
              <ImageButton
                source={require("@assets/icons/close.png")}
                size={16}
                onPress={() => navigation.navigate("QuestionsIndex")}
              />
            }
          >
            <QuestionForm
              onSubmit={handleSubmit}
              isLoading={isLoading}
              isSuccess={isSuccess}
              isError={isError}
            />
          </TitleCard>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Colors.backgroundPrimary,
  },
  content: {
    margin: UI.sizes.margin / 2,
    flex: 1,
    flexDirection: "row",
  },

  GLDR1: { flex: 1 },
  GLDR2: { flex: 1.618 + 2.618, margin: UI.sizes.margin / 2 },
});

export default NewQuestionScreen;
