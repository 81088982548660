import { Colors } from "@config/colors";
import { questionsCategories } from "@config/constants";
import { useGetQuestionsQuery } from "@services/api/questions/questionsApi";
// import { useGetQuestionsQuery } from "@services/api/smsOtp/smsOtpApi";
import React from "react";
import { View, Text, StyleSheet } from "react-native";

type Props = { questionIds: number[] };

const QuestionsCounterPerCategory: React.FC<Props> = ({ questionIds }) => {
  const { data } = useGetQuestionsQuery();
  const questions = data?.filter((q) => questionIds?.includes(q.id));

  return (
    <View style={styles.component}>
      {questionsCategories.map((category) => {
        const count = questions?.filter((q) => q.category === category).length;
        return (
          <View style={styles.categoryContainer} key={category}>
            <Text
              style={[styles.categoryLabel, count === 0 && styles.warningText]}
            >
              {count}
            </Text>
            <Text style={styles.categoryLabel}>
              {" "}
              -{">"} {category.slice(0, 50)}...
            </Text>
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  component: {
    flex: 1,
    // flexDirection: "row",
    // flexWrap: "wrap",
  },
  categoryContainer: {
    flexDirection: "row",
  },
  categoryLabel: {
    color: Colors.darkNeutral,
  },
  warningText: {
    color: Colors.warning,
  },
});

export default QuestionsCounterPerCategory;
