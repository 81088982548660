import { studentsApi } from "./studentsApi";
import { Student } from "./type";

export const onAddStudentQueryStarted = async (
  student,
  { dispatch, queryFulfilled }
) => {
  try {
    const { data } = await queryFulfilled;
    const addResult = dispatch(
      studentsApi.util.updateQueryData(
        "getStudents",
        undefined,
        (draftStudents) => {
          draftStudents.unshift(data as Student);
        }
      )
    );
  } catch (error: any) {
    console.log(error);
  }
};

export const onUpdateStudentQueryStarted = async (
  { id, ...patch },
  { dispatch, queryFulfilled }
) => {
  const patchResultStudent = dispatch(
    studentsApi.util.updateQueryData("getStudent", id, (draft) => {
      Object.assign(draft, patch);
    })
  );
  const patchResultList = dispatch(
    studentsApi.util.updateQueryData(
      "getStudents",
      undefined,
      (draftStudents) => {
        const studentIndex = draftStudents.findIndex(
          (student) => student.id === id
        );
        draftStudents[studentIndex] = { id, ...patch } as Student;
      }
    )
  );
  try {
    await queryFulfilled;
  } catch (error: any) {
    patchResultStudent.undo();
    patchResultList.undo();
  }
};

export const onRemoveStudentQueryStarted = async (
  student,
  { dispatch, queryFulfilled }
) => {
  const removeStudentFromEntireList = dispatch(
    studentsApi.util.updateQueryData(
      "getStudents",
      undefined,
      (draftStudents) => [...draftStudents].filter((q) => q.id !== student.id)
    )
  );

  const removeStudentFromGroupList = dispatch(
    studentsApi.util.updateQueryData(
      "getStudentsByGroupId",
      student.groupId,
      (draftStudents) => [...draftStudents].filter((q) => q.id !== student.id)
    )
  );
  try {
    await queryFulfilled;
  } catch (error: any) {
    console.log(error);
    removeStudentFromEntireList.undo();
    removeStudentFromGroupList.undo();
  }
};
