import React, { useMemo } from "react";
import { View, Text, StyleSheet } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { StudentDrawerParamList } from "@navigation/types";
import { Colors } from "@config/colors";
import BaseCard from "@components/UI/BaseCard/BaseCard";
import { useGetStudentRandomTestsQuery } from "@services/api/studentRandomTest/studentRandomTestsApi";
import LoadingOverlay from "@Core/Auth/components/UI/LoadingOverlay";
import { UI } from "@config/constants";
import TestResultCard from "./TestHistoryResultCard";
import { FlashList } from "@shopify/flash-list";
import CompactHistoryDetails from "../../../components/Statistics/CompactProgressCard";
import CompactProgressCard from "../../../components/Statistics/CompactProgressCard";

type Props = NativeStackScreenProps<StudentDrawerParamList, "History">;

const TestHistoryScreen: React.FC<Props> = (props) => {
  const { navigation } = props;
  const { data: studentRandomTests, isLoading: isLoadingStudentRandomTests } =
    useGetStudentRandomTestsQuery();

  const filteredTests = useMemo(
    () => studentRandomTests?.filter((t) => t.status !== "ongoing"),
    [studentRandomTests?.length]
  );

  if (isLoadingStudentRandomTests) {
    return <LoadingOverlay />;
  }

  return (
    <View style={styles.screen}>
      <FlashList
        data={filteredTests}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item: test, index }) => (
          <TestResultCard test={test} isFirst={index === 0} />
        )}
        estimatedItemSize={50}
        contentOffset={{ x: 0, y: -32 }}
        showsVerticalScrollIndicator={false}
        ListHeaderComponent={() => (
          <CompactProgressCard tests={filteredTests} navigation={navigation} />
        )}
        contentContainerStyle={{ paddingTop: UI.sizes.padding }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Colors.backgroundPrimary,
  },
});

export default TestHistoryScreen;
