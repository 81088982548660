import React, { useState } from "react";
import { View, Text, StyleSheet, Pressable, Image } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { AntDesign, MaterialIcons } from "@expo/vector-icons";

import {
  EmployeeStackParamList,
  GroupsStackParamList,
  QuestionsStackParamList,
} from "@navigation/types";
import { UI } from "@config/constants";
import { Colors } from "@config/colors";
import { Group } from "@services/api/groups/types";
import ImageButton from "@components/UI/ImageButton";
import IndexGroupsScreen from "@screens/employee/groups/IndexGroupsScreen";
import DashboardScreen from "@screens/employee/DashboardScreen";

// type DashboardScreenNavigationProp = NativeStackNavigationProp<
//   EmployeeStackParamList,
//   "Dashboard"
// >;

type IndexGroupsScreenNavigationProp = NativeStackNavigationProp<
  EmployeeStackParamList,
  "GroupsIndex"
>;

type Navigation = IndexGroupsScreenNavigationProp;

type Props = {
  group?: Group;
  header?: boolean;
  last?: boolean;
};

const GroupListItem: React.FC<Props> = (props) => {
  const { group, header, last } = props;
  const [isHovered, setIsHovered] = useState(false);
  const route = useRoute();
  const { navigate } = useNavigation<Navigation>();

  const handleOnPress = () => {
    if (!header) {
      if (route.name === "Home") {
        navigate("Groups", { screen: "GroupsShow", params: { id: group.id } });
      } else {
        navigate("GroupsShow", { id: group.id });
      }
    }
  };

  return (
    <Pressable
      style={[
        styles.component,
        header ? styles.headerItem : last ? styles.lastItem : {},
      ]}
      onPress={handleOnPress}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
    >
      <View style={[styles.content, last ? { borderBottomWidth: 0 } : {}]}>
        <Text
          style={[
            styles.text,
            !header && styles.nameText,
            !header && isHovered && styles.highlightedTextL,
          ]}
        >
          {header ? "Nume" : "Grupa: " + group.name}
        </Text>

        <View style={styles.generalDetails}>
          <View style={styles.centeredContainer}>
            <Text style={styles.text}>
              {header ? "Nr. studenti" : `${group.numberOfStudents} studenti`}
            </Text>
          </View>
          <View>
            {!header && (
              <Image
                source={require("@assets/icons/magnifier.png")}
                style={{ width: 20, height: 20 }}
              />
            )}
          </View>
        </View>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  component: {
    width: "100%",
    backgroundColor: Colors.backgroundPrimary,
  },
  headerItem: {
    borderTopRightRadius: UI.sizes.borderRadius,
    borderTopLeftRadius: UI.sizes.borderRadius,
    borderColor: "rgba(255, 255, 255, 0.3)",
    borderBottomWidth: 1,
  },
  lastItem: {
    borderBottomRightRadius: UI.sizes.borderRadius,
    borderBottomLeftRadius: UI.sizes.borderRadius,
  },
  content: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: UI.sizes.padding,
    marginHorizontal: UI.sizes.margin,
    borderBottomWidth: 1,
    borderColor: "rgba(255, 255, 255, 0.1)",
  },
  centeredContainer: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  generalDetails: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  nameText: {
    flex: 1,
    fontSize: 18,
  },
  text: {
    flex: 1,
    color: Colors.darkNeutral,
    textAlignVertical: "center",
    marginRight: UI.sizes.margin,
  },
  highlightedTextL: {
    color: Colors.accent,
  },
});

export default GroupListItem;
