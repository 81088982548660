import DECButton from "@Core/UI/components/Buttons/DECButton";
import DECInput from "@Core/UI/components/Inputs/DECInput";
import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import { EmployeeStackParamList } from "@navigation/types";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { useAddTestMutation } from "@services/api/tests/testsApi";
import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";

type Navigation = NavigationProp<EmployeeStackParamList, "Tests">;

type Props = { onSubmit: () => void };

const isNameValid = (name) => name.length >= 3;

const TestForm: React.FC<Props> = ({ onSubmit }) => {
  const [enteredName, setEnteredName] = useState("");
  const [addTest, { isLoading, isSuccess, isError }] = useAddTestMutation();
  const navigation = useNavigation<Navigation>();

  const handleSubmit = async () => {
    if (isNameValid(enteredName)) {
      const payload = await addTest({ name: enteredName });
      if ("data" in payload) {
        navigation.navigate("Tests", {
          screen: "TestsEdit",
          params: { id: payload.data.id },
        });
      }
    } else {
      console.log("isNotValid");
    }
  };

  useEffect(() => {
    if (isSuccess) onSubmit();
  }, [isSuccess]);

  return (
    <View style={styles.component}>
      <View style={styles.form}>
        <DECInput
          autoFocus
          isRequired
          label="Nume test"
          onUpdateValue={(value) => setEnteredName(value)}
          value={enteredName}
          validationFn={(value) => value.length >= 3}
          errorMessage="Trebuie sa aiba cel putin 3 caractere."
        />
        <DECButton
          onPress={handleSubmit}
          isLoading={isLoading}
          isSuccess={isSuccess}
        >
          Adauga test
        </DECButton>
        {/* <FormControl isRequired>
          <Stack mx="4">
            <FormControl.Label>Numele testului</FormControl.Label>
            <Input
              defaultValue="12345"
              placeholder="1 - Sectiunea introductiva"
            />
            <FormControl.HelperText>
              Trebuie sa aiba cel putin 3 caractere.
            </FormControl.HelperText>
            <FormControl.ErrorMessage
              leftIcon={<WarningOutlineIcon size="xs" />}
            >
              Atleast 6 characters are required.
            </FormControl.ErrorMessage>
          </Stack>
        </FormControl> */}
        {/* <DECInput
          onUpdateValue={(value) => setEnteredName(value)}
          value={enteredName}
          placeholder="Nume  (ex.: 1 - Sectiunea introductiva)"
          keyboardType="default"
          validationFn={isNameValid} // Example validation
          errorMessage="Trebuie sa aiba cel putin 3 caractere. Recomandam sa fie numite in ordine cronologica desfasurarii cursului"
          autoFocus
          multiline={false}
        />
        {isError && (
          <Text style={styles.errorText}>
            A aparut o eroare, incearca din nou...
          </Text>
        )} */}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  component: {
    marginBottom: (UI.sizes.margin * 1.618) / 2.618,
    // flexDirection: "row",
    width: "100%",
    // justifyContent: "flex-end",
    alignItems: "center",
    // verticalAlign: "center",
  },
  form: {
    minWidth: 420,
    maxWidth: 800,
  },
  submitButton: {
    height: 40,
    marginTop: (UI.sizes.margin * 1.618) / 2.618,
    justifyContent: "center",
    backgroundColor: Colors.success,
  },
  errorText: {
    marginTop: 10,
    color: Colors.error,
  },
});

export default TestForm;
