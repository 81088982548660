import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import React from "react";
import { View, Text, Image, StyleSheet } from "react-native";

const ErrorNotification: React.FC<{}> = (props) => {
  return (
    <View style={styles.component}>
      <Image source={require("@assets/icons/error.png")} style={styles.image} />
      <Text style={styles.label}>Ooops! A aparut o eroare...</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  component: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  image: { height: 128, width: 128 },
  label: {
    color: Colors.darkNeutral,
    fontSize: 24,
    fontWeight: UI.font.weight.semiBold,
  },
});

export default ErrorNotification;
