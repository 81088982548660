import { Colors } from "@config/colors";
import { StudentRandomTest } from "@services/api/studentRandomTest/types";

export const getLast28DaysNames = () => {
  const today = new Date();
  const last30DaysNames = [];

  for (let i = 0; i <= 28; i++) {
    const currentDate = new Date(today);
    currentDate.setDate(today.getDate() - i);
    // debugger;
    if (i < 7) {
      // For the last 7 days, get the day name
      const dayIndex = currentDate.getDay();
      last30DaysNames.push(weekDaysInRomanian[dayIndex]);
    } else {
      // For days older than a week, get the date
      const day = String(currentDate.getDate()).padStart(2, "0");
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const year = currentDate.getFullYear();
      last30DaysNames.push(`${day}-${month}`);
    }
  }

  return [...last30DaysNames.reverse().slice(0, 26), "Ieri", "Azi"];
};

export const getLast7DaysNames = () => {
  const todayIndex = new Date().getDay();
  const last7DaysNames = [];

  for (let i = 1; i <= 7; i++) {
    const dayIndex = (todayIndex - i + 7) % 7; // Ensure the index is within the range [0, 6]
    last7DaysNames.push(weekDaysInRomanian[dayIndex]);
  }

  return [
    ...last7DaysNames.reverse().slice(0, last7DaysNames.length - 2),
    "Ieri",
    "Azi",
  ];
};

export const totalTestsChartLegend = [
  { label: "Total", color: Colors.backgroundSecondary },
  { label: "Promovate", color: Colors.success },
  { label: "Nepromovate", color: Colors.error },
];

export const weekDaysInRomanian = ["Du", "Lu", "Ma", "Mi", "Jo", "Vi", "Sa"];

export const getNumberOfTestsPerDay = (
  tests: StudentRandomTest[],
  is28DaysRange: boolean
) => {
  if (!tests) {
    return [];
  }

  const currentDate = new Date();
  const daysAgo = Array.from(
    { length: is28DaysRange ? 28 : 7 },
    (_, index) => index //+ 1
  );

  return daysAgo
    .map((day) => {
      const refrenceDateTime = new Date(
        currentDate.getTime() - day * 3600 * 24 * 1000
      );

      // Filter tests based on referenceDateTime's date and month
      const testsInDay = tests.filter((test) => {
        const testDateTime = new Date(test.startedAt);
        const testDate = testDateTime.getDate();
        const testMonth = testDateTime.getMonth();

        return (
          testDate === refrenceDateTime.getDate() &&
          testMonth === refrenceDateTime.getMonth()
        );
      });

      const passedTestsInDayCount = testsInDay.filter(
        (test) => test.status === "passed"
      ).length;
      const failedTestsInDayCount = testsInDay.length - passedTestsInDayCount;

      return [testsInDay.length, passedTestsInDayCount, failedTestsInDayCount];
    })
    .reverse();
};

export const getNumberOfTestsPerWeek = (tests) => {
  if (!tests) {
    return [];
  }

  const currentDate = new Date();
  const weeksAgo = Array.from({ length: 4 }, (_, index) => index);

  return weeksAgo
    .map((week) => {
      const currentDayOfWeek = new Date().getDay();

      const startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - week * 7 - currentDayOfWeek,
        0,
        0,
        0
      );

      const endDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + 7,
        0,
        0,
        0
      );

      const testsInWeek = tests.filter(
        (test) =>
          new Date(test.startedAt) >= startDate &&
          new Date(test.startedAt) <= endDate
      );

      const passedTestsInWeekCount = testsInWeek.filter(
        (test) => test.status === "passed"
      ).length;
      const failedTestsInWeekCount =
        testsInWeek.length - passedTestsInWeekCount;

      return [
        testsInWeek.length,
        passedTestsInWeekCount,
        failedTestsInWeekCount,
      ];
    })
    .reverse();
};

export const getNumberOfTestsForEachDay = (tests: StudentRandomTest[]) => {
  if (!tests) {
    return [];
  }

  const currentDate = new Date();
  let referenceDateTime = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  let firstTestDateTime = new Date(referenceDateTime);

  tests.forEach((test) => {
    const testDateTime = new Date(test.startedAt);
    if (firstTestDateTime.getTime() > testDateTime.getTime()) {
      firstTestDateTime = testDateTime;
    }
  });

  let testsCountPerDay = [];

  do {
    const testsInDay = tests.filter((test) => {
      const testDateTime = new Date(test.startedAt);
      return (
        testDateTime.getDate() === referenceDateTime.getDate() &&
        testDateTime.getMonth() === referenceDateTime.getMonth() &&
        testDateTime.getFullYear() === referenceDateTime.getFullYear()
      );
    });

    testsCountPerDay.push(testsInDay.length);

    // Decrement by one day
    referenceDateTime.setDate(referenceDateTime.getDate() - 1);
  } while (referenceDateTime.getTime() >= firstTestDateTime.getTime());

  return testsCountPerDay;
};
