import React, { ReactNode, useEffect, useLayoutEffect, useState } from "react";
import { View, Text, StyleSheet, Image } from "react-native";
import { UI } from "../../../config/constants";
import { Colors } from "@config/colors";
import { useSetTestAsExpiredMutation } from "@services/api/studentRandomTest/studentRandomTestsApi";
import { formatSecondsToTime } from "@Core/utils/dateAndTime";

const iconMap = {
  timer: require("@assets/icons/timer.png"),
  question: require("@assets/icons/question.png"),
};

export const TestProgressLabel = ({
  icon,
  label,
  value,
  color,
  children,
}: {
  icon?: string;
  label?: string;
  value?: number | string;
  color?: string;
  children?: ReactNode;
}) => {
  const iconLocation = `@assets/icons/${icon}.png`;
  return (
    <View style={styles.textContainer}>
      {icon ? (
        <Image
          source={iconMap[icon]}
          style={{ width: 16, height: 16, marginRight: 4 }}
        />
      ) : (
        <Text style={styles.text}>{label}</Text>
      )}
      <View style={styles.scoreLabel}>
        {children ? (
          children
        ) : (
          <Text style={[styles.valueText, { color: color }]}>{value}</Text>
        )}
      </View>
    </View>
  );
};

const TestProgressDisplay: React.FC<{
  correct: number;
  wrong: number;
  remaining: number;
  startedAt: string;
}> = (props) => {
  const { correct, wrong, remaining, startedAt } = props;

  const [
    setTestAsExpired,
    { isLoading: isLoadingSetTestAsExpired, isError: isErrorSetTestAsExpired },
  ] = useSetTestAsExpiredMutation();

  const [secondsLeft, setSecondsLeft] = useState(() => {
    const totalSeconds = 3600;
    const elapsedSeconds = Math.floor(
      (new Date().getTime() - new Date(startedAt).getTime()) / 1000
    );
    return totalSeconds - elapsedSeconds;
  });

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      setSecondsLeft((prevSeconds) => {
        if (prevSeconds <= 0) {
          clearInterval(interval);
          setTestAsExpired();
          return 0;
        } else {
          return prevSeconds - 1;
        }
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <View style={styles.component}>
      <TestProgressLabel icon="question">
        <View style={styles.questionValuesContainer}>
          <Text style={[styles.valueText, { color: Colors.success }]}>
            {correct}
          </Text>
          <Text style={[styles.slashText, { color: Colors.darkNeutral }]}>
            /
          </Text>
          <Text style={[styles.valueText, { color: Colors.error }]}>
            {wrong}
          </Text>
          <Text style={[styles.slashText, { color: Colors.darkNeutral }]}>
            /
          </Text>
          <Text style={[styles.valueText, { color: Colors.accent }]}>
            {remaining}
          </Text>
        </View>
      </TestProgressLabel>
      <TestProgressLabel
        icon="timer"
        value={formatSecondsToTime(secondsLeft)}
        color={Colors.neutral}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  component: {
    // height: "100%",
    marginBottom: 10,
  },
  textContainer: {
    // flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: Colors.backgroundPrimary,
    paddingVertical: 2,
    paddingHorizontal: 4,
    borderRadius: UI.sizes.borderRadius,
    marginBottom: 2,
    marginHorizontal: 2,
  },
  scoreLabel: {
    alignItems: "center",
    paddingHorizontal: 4,
  },
  text: {
    fontSize: 12,
    color: Colors.darkNeutral,
  },
  dashText: {
    color: Colors.darkNeutral,
    textAlignVertical: "center",
  },
  valueText: {
    fontWeight: "bold",
    fontSize: 12,
  },
  slashText: {
    fontWeight: UI.font.weight.light,
    fontSize: 12,
    marginHorizontal: 1,
  },
  questionValuesContainer: {
    flexDirection: "row",
  },
});

export default TestProgressDisplay;
