import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import IndexTestsScreen from "@screens/employee/tests/IndexTestsScreen";
import EditTestScreen from "@screens/employee/tests/EditTestScreen";
import { TestsStackParamList } from "@navigation/types";

const TestsMainStack = createNativeStackNavigator<TestsStackParamList>();

const TestsNavigator: React.FC<{}> = (props) => {
  return (
    <TestsMainStack.Navigator
      initialRouteName="TestsIndex"
      screenOptions={{
        headerShown: false,
      }}
    >
      <TestsMainStack.Screen
        name="TestsIndex"
        component={IndexTestsScreen}
        // options={{ headerShown: false }}
      />
      <TestsMainStack.Screen
        name="TestsEdit"
        component={EditTestScreen}
        // initialParams={{ id: 5 }}
        // options={{ headerShown: false }}
      />
    </TestsMainStack.Navigator>
  );
};

export default TestsNavigator;
