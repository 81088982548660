import React, { useEffect, useMemo, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  KeyboardTypeOptions,
  TextInput,
  Image,
} from "react-native";
import { PhoneInput, ICountry } from "react-native-international-phone-number";

import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import DECInput from "./DECInput";
import { formatRomanianNumber } from "@Core/Auth/utils/phoneNumber";

type Props = {
  label: string;
  onUpdateValue: (text: string) => void;
  value: string;
  isInvalid: boolean;
  keyboardType?: KeyboardTypeOptions;
  secure?: boolean;
};

const defaultCountry = {
  name: "Romania",
  cca2: "RO",
  flag: "flag-ro",
  callingCode: "+40",
};

const PhoneNumberInput = (props: Props) => {
  const { label, onUpdateValue, value, isInvalid } = props;

  function handleInputValue(phoneNumber: string) {
    const unformattedValue = phoneNumber.replaceAll(" ", "");

    onUpdateValue("+40" + unformattedValue);
  }

  const formattedValue = useMemo(() => {
    const numericInput = value.replace(/\D/g, "").slice(2);

    const formatted = `${numericInput.slice(0, 3)} ${numericInput.slice(
      3,
      6
    )} ${numericInput.slice(6)}`;
    return formatted.trim();
  }, [value]);

  return (
    <View style={styles.inputContainer}>
      <Text style={[styles.label, isInvalid && styles.labelInvalid]}>
        {label}
      </Text>
      <View style={styles.phoneNumberContainer}>
        <View style={styles.countryContainer}>
          <Image
            source={require("@assets/icons/romania.png")}
            style={{ width: 24, height: 24 }}
          />
          <Text style={styles.countryCode}>+40</Text>
        </View>

        <TextInput
          value={formattedValue}
          maxLength={11}
          onChangeText={handleInputValue}
          style={styles.input}
          keyboardType="number-pad"
          placeholder="712 345 678"
          placeholderTextColor="rgba(255, 255, 255, 0.3)"
        />
        {/* <PhoneInput
          value={value?.substring(3)}
          onChangePhoneNumber={handleInputValue}
          selectedCountry={selectedCountry}
          onChangeSelectedCountry={handleSelectedCountry}
          withDarkTheme
          modalDisabled={true}
          placeholder="712 345 678"
          inputStyle={{
            color: Colors.darkNeutral,
          }}
          containerStyle={{
            backgroundColor: Colors.backgroundPrimary,
            borderWidth: 0,
          }}
          flagContainerStyle={{
            borderTopLeftRadius: UI.sizes.borderRadius,
            borderBottomLeftRadius: UI.sizes.borderRadius,
            backgroundColor: Colors.backgroundPrimary,
            justifyContent: "center",
          }}
        /> */}
      </View>
    </View>
  );
};

export default PhoneNumberInput;

const styles = StyleSheet.create({
  inputContainer: {
    marginVertical: 16,
  },
  label: {
    color: "white",
    marginBottom: 4,
  },
  labelInvalid: {
    color: Colors.error500,
  },
  phoneNumberContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Colors.backgroundPrimary,
    borderRadius: UI.sizes.borderRadius,
  },
  input: {
    flex: 1,
    width: "100%",
    paddingVertical: 8,
    paddingHorizontal: 6,
    backgroundColor: Colors.backgroundPrimary,
    borderBottomRightRadius: UI.sizes.borderRadius,
    borderTopRightRadius: UI.sizes.borderRadius,
    borderWidth: 0,
    fontSize: 16,
    color: Colors.darkNeutral,
  },
  inputInvalid: {
    backgroundColor: Colors.error100,
  },
  countryContainer: {
    height: "100%",
    borderTopLeftRadius: UI.sizes.borderRadius,
    borderBottomLeftRadius: UI.sizes.borderRadius,
    backgroundColor: Colors.backgroundPrimary,
    paddingHorizontal: UI.sizes.padding,
    flexDirection: "row",
    alignItems: "center",
  },
  countryCode: {
    fontSize: 16,
    color: "white",
    marginLeft: 8,
    backgroundColor: Colors.backgroundPrimary,
  },
});
