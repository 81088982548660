import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  PressableStateCallbackType,
  Image,
  ActivityIndicator,
} from "react-native";

type Props = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  onPress: () => void;
  size?: "sm" | "md" | "lg";
};

const sizeInPixels = {
  sm: 18,
  md: 24,
  lg: 32,
};

const DECTrashButton: React.FC<Props> = ({ size = "lg", ...props }) => {
  const { onPress, isLoading, isSuccess } = props;

  const style = { width: sizeInPixels[size], height: sizeInPixels[size] };

  return (
    <Pressable
      style={({
        hovered,
      }: PressableStateCallbackType & { hovered?: boolean }) => [
        styles.component,
        isSuccess && styles.success,
      ]}
      onPress={onPress}
      disabled={isLoading}
    >
      {({ hovered }: PressableStateCallbackType & { hovered?: boolean }) => {
        return isSuccess || !hovered ? (
          <Image source={require("@assets/icons/trash.png")} style={style} />
        ) : (
          <Image
            source={require("@assets/icons/trash_error.png")}
            style={style}
          />
        );
      }}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  component: {
    alignItems: "center",
    paddingVertical: 6,
    borderRadius: UI.sizes.borderRadius,
  },
  success: {
    backgroundColor: Colors.error,
  },
});

export default DECTrashButton;
