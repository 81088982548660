import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  PressableStateCallbackType,
  Image,
} from "react-native";

type Props = { onPress: () => void };

const DECRefreshButton: React.FC<Props> = ({ onPress }) => {
  return (
    <Pressable
      style={({
        hovered,
      }: PressableStateCallbackType & { hovered?: boolean }) => [
        styles.component,
        // hovered && styles.hovered,
        // isSuccess && styles.success,
      ]}
      onPress={onPress}
      // disabled={isLoading}
    >
      {({ hovered }: PressableStateCallbackType & { hovered?: boolean }) => {
        // return isSuccess || !hovered ? (
        // <Image
        //   source={require(`@assets/icons/trash.png`)}
        //   style={{ width: 32, height: 32 }}
        // />
        // ) : (
        return (
          <Image
            source={require("@assets/icons/refresh.png")}
            style={{ width: 24, height: 24 }}
          />
        );
        // );
      }}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  component: {},
});

export default DECRefreshButton;
