import BaseCard from "@components/UI/BaseCard/BaseCard";
import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import { LearningMediumsStackParamList } from "@navigation/types";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import React from "react";
import { Pressable, Text, StyleSheet, Image, View } from "react-native";

const uninitializedIcons = {
  I: require("@assets/icons/learning_medium/uninitialized/law-book.png"),
  II: require("@assets/icons/learning_medium/uninitialized/lethal-gun.png"),
  III: require("@assets/icons/learning_medium/uninitialized/non-lethal-gun.png"),
  IV: require("@assets/icons/learning_medium/uninitialized/storage-gun.png"),
  V: require("@assets/icons/learning_medium/uninitialized/travell-gun.png"),
  VI: require("@assets/icons/learning_medium/uninitialized/fine.png"),
  VII: require("@assets/icons/learning_medium/uninitialized/cpr.png"),
};

const ongoingIcons = {
  I: require("@assets/icons/learning_medium/ongoing/law-book.png"),
  II: require("@assets/icons/learning_medium/ongoing/lethal-gun.png"),
  III: require("@assets/icons/learning_medium/ongoing/non-lethal-gun.png"),
  IV: require("@assets/icons/learning_medium/ongoing/storage-gun.png"),
  V: require("@assets/icons/learning_medium/ongoing/travell-gun.png"),
  VI: require("@assets/icons/learning_medium/ongoing/fine.png"),
  VII: require("@assets/icons/learning_medium/ongoing/cpr.png"),
};

const finishedIcons = {
  I: require("@assets/icons/learning_medium/finished/law-book.png"),
  II: require("@assets/icons/learning_medium/finished/lethal-gun.png"),
  III: require("@assets/icons/learning_medium/finished/non-lethal-gun.png"),
  IV: require("@assets/icons/learning_medium/finished/storage-gun.png"),
  V: require("@assets/icons/learning_medium/finished/travell-gun.png"),
  VI: require("@assets/icons/learning_medium/finished/fine.png"),
  VII: require("@assets/icons/learning_medium/finished/cpr.png"),
};

const labels = {
  I: "I - Dispoziţii generale",
  II: "II - Procurarea, deţinerea, portul şi folosirea armelor letale",
  III: "III - Procurarea, deţinerea, portul şi folosirea armelor neletale",
  IV: "IV - Condiţiile de păstrare, port şi folosire a armelor letale / neletale",
  V: "V - Permis de armă şi paşaportul european pentru arme de foc. Călătorii în state terţe cu arme letale şi muniţia corespunzatoare",
  VI: "VI - Contravenţii şi infracţiuni",
  VII: "VII - Acordarea primului ajutor în cazul plăgilor prin împuscare",
};

type Navigation = NavigationProp<LearningMediumsStackParamList>;

type Props = {
  label: string;
  progress: number;
  totalQuestionsCount: number;
  correctlyAnsweredQuestionsCount: number;
  studentCategoryProgressId: number;
  disabled: boolean;
};

const LearningMediumButton: React.FC<Props> = (props) => {
  const {
    label,
    progress,
    totalQuestionsCount,
    correctlyAnsweredQuestionsCount,
    studentCategoryProgressId,
    disabled,
  } = props;
  const navigation = useNavigation<Navigation>();

  const filledWidth = `${progress * 100}%` as `${number}%`;

  const handlePress = () => {
    navigation.navigate("LearningMediumsShow", {
      id: studentCategoryProgressId,
    });
  };

  const indexInRoman = label.split(" ")[0];
  const iconSource =
    progress === 0
      ? uninitializedIcons[indexInRoman]
      : progress === 1
      ? finishedIcons[indexInRoman]
      : ongoingIcons[indexInRoman];

  return (
    <Pressable onPress={handlePress} disabled={disabled}>
      <BaseCard style={styles.container}>
        <View style={styles.row}>
          <View style={styles.leftSide}>
            <Image source={iconSource} style={[styles.icon]} />
            <View style={styles.percentageContainer}>
              <Text style={[styles.percentageText]}>
                {(progress * 100).toFixed(0)}%
              </Text>
            </View>
          </View>
          <View style={styles.mainContent}>
            <Text style={[styles.text, styles.label]} numberOfLines={5}>
              {labels[label.split(" ")[0]]}
            </Text>
            <Text style={[styles.text, styles.questionsCountText]}>
              {correctlyAnsweredQuestionsCount} / {totalQuestionsCount}
            </Text>
          </View>
        </View>
        <View style={styles.progressBarContainer}>
          <View
            style={[
              styles.progressBar,
              {
                width: filledWidth,
                backgroundColor:
                  progress === 1 ? Colors.success : Colors.accent,
              },
            ]}
          />
        </View>
        {totalQuestionsCount === 0 && (
          <View style={styles.disabledOverlay}>
            <Text style={styles.disabledLabel}>
              Momentan nu exista intrebari pentru aceasta categorie.
            </Text>
          </View>
        )}
      </BaseCard>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: UI.sizes.margin,
    marginHorizontal: UI.sizes.margin,
    padding: 0,
    overflow: "hidden",
  },
  row: {
    flexDirection: "row",
    padding: UI.sizes.padding,
  },
  leftSide: {
    flex: 1,
    alignItems: "center",
    // justifyContent: "space-between",
    marginRight: 8,
    padding: UI.sizes.padding / 2,
    paddingLeft: 0,
    borderRightColor: "gray",
    borderRightWidth: 1,
  },
  mainContent: {
    flex: 4,
    justifyContent: "space-between",
    // marginRight: UI.sizes.margin,
  },
  percentageContainer: {
    marginTop: UI.sizes.margin / 2,
    paddingRight: 0,
  },
  percentageText: {
    fontSize: 18,
    fontWeight: UI.font.weight.bold,
    color: Colors.darkNeutral,
  },
  progressBarContainer: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: 4,
    borderBottomLeftRadius: UI.sizes.borderRadius,
    borderBottomRightRadius: UI.sizes.borderRadius,
    overflow: "hidden",
  },
  progressBar: {
    height: "100%",
  },
  text: {
    color: Colors.darkNeutral,
  },
  label: {
    flexWrap: "wrap",
    marginBottom: UI.sizes.margin,
  },
  questionsCountText: {
    width: "100%",
    textAlign: "right",
    fontWeight: UI.font.weight.semiBold,
  },
  icon: {
    width: 32,
    height: 32,
    objectFit: "contain",
  },
  disabledOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, .8)",
    justifyContent: "center",
    alignItems: "center",
  },
  disabledLabel: {
    color: Colors.darkNeutral,
  },
});

export default LearningMediumButton;
