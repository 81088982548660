import { Colors } from "@config/colors";
import { ActivityIndicator, StyleSheet, Text, View } from "react-native";

type Props = {
  message?: string;
};

const LoadingOverlay = ({ message }: Props) => {
  return (
    <View style={styles.rootContainer}>
      {message && <Text style={styles.message}>{message}</Text>}
      <ActivityIndicator size="large" color={Colors.accent} />
    </View>
  );
};

export default LoadingOverlay;

const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 32,
  },
  message: {
    fontSize: 16,
    marginBottom: 12,
    color: Colors.darkNeutral,
  },
});
