import { MutationLifecycleApi } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { studentRandomTestsApi } from "./studentRandomTestsApi";
import { AnswerStudentRandomTestQuestionArg, StudentRandomTest } from "./types";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query";
import { Question } from "../questions/types";

type MyApiBaseQuery = BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
>;

export const onAnswerStudentRandomTestQuestionQueryStarted = async (
  arg: AnswerStudentRandomTestQuestionArg,
  mutationApi: MutationLifecycleApi<
    AnswerStudentRandomTestQuestionArg,
    MyApiBaseQuery,
    StudentRandomTest
  >
) => {
  const test = mutationApi.getState().baseApi.queries[
    "getOngoingStudentRandomTest(undefined)"
  ].data as StudentRandomTest;

  const questions = mutationApi.getState().baseApi.queries[
    "getQuestions(undefined)"
  ].data as Question[];

  const testQuestions = questions.filter((q) =>
    test.questions.map((q) => q[0]).includes(q.id)
  );

  let correctAnswersCount = 0;
  let wrongAnswersCount = 0;

  const allCompactQuestions = test.questions.map((question) => [...question]);
  const answeredQuestionIndex = allCompactQuestions.findIndex(
    (q) => q[0] === arg.questionId
  );

  if (answeredQuestionIndex !== -1) {
    allCompactQuestions[answeredQuestionIndex] = [
      allCompactQuestions[answeredQuestionIndex][0], // keep question ID
      arg.answerId, // new answer ID
      allCompactQuestions[answeredQuestionIndex][2], // keep index
    ];
  }
  const allAnsweredQuestions = allCompactQuestions.filter((q) => q[1]);
  allAnsweredQuestions.forEach((qCompact) => {
    const question = testQuestions.find((q) => q.id === qCompact[0]);

    if (question.correctAnswerId === qCompact[1]) {
      correctAnswersCount += 1;
    } else {
      wrongAnswersCount += 1;
    }
  });

  const updatedStatus =
    wrongAnswersCount > 4
      ? "failed"
      : correctAnswersCount + wrongAnswersCount === test.questions.length
      ? "passed"
      : "ongoing";

  const patchResultStudentRandomTest = mutationApi.dispatch(
    studentRandomTestsApi.util.updateQueryData(
      "getOngoingStudentRandomTest",
      undefined,
      (draft) => {
        Object.assign(draft, {
          ...test,
          questions: allCompactQuestions,
          status: updatedStatus,
          correctAnswersCount: correctAnswersCount,
          wrongAnswersCount: wrongAnswersCount,
          endedAt: new Date().toString(),
        });
      }
    )
  );
  let patchStudentRandomTestsResult = null;
  if (updatedStatus !== "ongoing") {
    patchStudentRandomTestsResult = mutationApi.dispatch(
      studentRandomTestsApi.util.updateQueryData(
        "getStudentRandomTests",
        undefined,
        (draft) => {
          draft.unshift({
            ...test,
            questions: allCompactQuestions,
            status: updatedStatus,
            correctAnswersCount: correctAnswersCount,
            wrongAnswersCount: wrongAnswersCount,
            endedAt: new Date().toString(),
          } as StudentRandomTest);
        }
      )
    );
  }

  try {
    await mutationApi.queryFulfilled;
  } catch (error: any) {
    console.log(
      "Error in onAnswerStudentRandomTestQuestionQueryStarted: ",
      error
    );
    patchResultStudentRandomTest.undo();
    patchStudentRandomTestsResult && patchStudentRandomTestsResult.undo();
  }
};

export const onSetTestAsExpired = async (
  arg: void,
  mutationApi: MutationLifecycleApi<void, MyApiBaseQuery, StudentRandomTest>
) => {
  const test = mutationApi.getState().baseApi.queries[
    "getOngoingStudentRandomTest(undefined)"
  ].data as StudentRandomTest;

  const questions = mutationApi.getState().baseApi.queries[
    "getQuestions(undefined)"
  ].data as Question[];

  const testQuestions = questions.filter((q) =>
    test.questions.map((q) => q[0]).includes(q.id)
  );

  let correctAnswersCount = 0;
  let wrongAnswersCount = 0;

  const allCompactQuestions = test.questions.map((question) => [...question]);

  const allAnsweredQuestions = allCompactQuestions.filter((q) => q[1]);
  allAnsweredQuestions.forEach((qCompact) => {
    const question = testQuestions.find((q) => q.id === qCompact[0]);

    if (question.correctAnswerId === qCompact[1]) {
      correctAnswersCount += 1;
    } else {
      wrongAnswersCount += 1;
    }
  });

  const updatedStatus =
    correctAnswersCount + wrongAnswersCount === test.questions.length
      ? "passed"
      : "failed";

  const startDate = new Date(test.startedAt);
  const endDate = new Date(startDate.getTime() + 3600 * 1000);

  const patchResultStudentRandomTest = mutationApi.dispatch(
    studentRandomTestsApi.util.updateQueryData(
      "getOngoingStudentRandomTest",
      undefined,
      (draft) => {
        Object.assign(draft, {
          ...test,
          questions: allCompactQuestions,
          status: updatedStatus,
          correctAnswersCount: correctAnswersCount,
          wrongAnswersCount: wrongAnswersCount,
          endedAt: endDate.toISOString(),
        });
      }
    )
  );

  let patchStudentRandomTestsResult = null;
  patchStudentRandomTestsResult = mutationApi.dispatch(
    studentRandomTestsApi.util.updateQueryData(
      "getStudentRandomTests",
      undefined,
      (draft) => {
        draft.unshift({
          ...test,
          questions: allCompactQuestions,
          status: updatedStatus,
          correctAnswersCount: correctAnswersCount,
          wrongAnswersCount: wrongAnswersCount,
          endedAt: new Date().toISOString(),
        } as StudentRandomTest);
      }
    )
  );

  try {
    await mutationApi.queryFulfilled;
  } catch (error: any) {
    console.log(
      "Error in onAnswerStudentRandomTestQuestionQueryStarted: ",
      error
    );
    patchResultStudentRandomTest.undo();
    patchStudentRandomTestsResult && patchStudentRandomTestsResult.undo();
  }
};

export const onGenerateStudentRandomTestQueryStarted = async (
  undefined,
  { dispatch, queryFulfilled }
) => {
  try {
    const response = await queryFulfilled;

    console.log(
      "Response in onGenerateStudentRandomTestQueryStarted: ",
      response
    );

    console.log(
      "Type of response in onGenerateStudentRandomTestQueryStarted: ",
      typeof response
    );

    dispatch(
      studentRandomTestsApi.util.updateQueryData(
        "getOngoingStudentRandomTest",
        undefined,
        (draft) => {
          Object.assign(draft, response.data);
        }
      )
    );
  } catch (error: any) {}
};
