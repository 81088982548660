import DECButton from "@Core/UI/components/Buttons/DECButton";
import DECInput from "@Core/UI/components/Inputs/DECInput";
import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import { useAddGroupMutation } from "@services/api/groups/groupsApi";
import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet } from "react-native";

type Props = { onSubmit: () => void };

const isNameValid = (name) => name.length >= 3;

const GroupForm: React.FC<Props> = ({ onSubmit }) => {
  const [enteredName, setEnteredName] = useState("");
  const [addGroup, { isLoading, isSuccess, isError }] = useAddGroupMutation();

  const handleSubmit = () => {
    if (isNameValid(enteredName)) {
      addGroup({ name: enteredName });
    } else {
      console.log("isNotValid");
    }
  };

  useEffect(() => {
    if (isSuccess) onSubmit();
  }, [isSuccess]);

  return (
    <View style={styles.component}>
      <View style={styles.form}>
        <DECInput
          onUpdateValue={(value) => setEnteredName(value)}
          value={enteredName}
          placeholder="Nume (ex.: Ian23#1)"
          keyboardType="default"
          validationFn={isNameValid} // Example validation
          errorMessage="Trebuie sa aiba cel putin 3 caractere"
          autoFocus
        />
        <DECButton
          onPress={handleSubmit}
          isLoading={isLoading}
          isSuccess={isSuccess}
        >
          Adauga grupa
        </DECButton>
        {isError && (
          <Text style={styles.errorText}>
            A aparut o eroare, incearca din nou...
          </Text>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  component: {
    marginBottom: (UI.sizes.margin * 1.618) / 2.618,
    // flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    // verticalAlign: "center",
  },
  form: {
    maxWidth: 560,
  },
  submitButton: {
    height: 40,
    marginTop: (UI.sizes.margin * 1.618) / 2.618,
    justifyContent: "center",
    backgroundColor: Colors.success,
  },
  errorText: {
    marginTop: 10,
    color: Colors.error,
  },
});

export default GroupForm;
