import { useContext, useState } from "react";
import { Alert } from "react-native";

import AuthContent from "../components/Auth/AuthContent";
import LoadingOverlay from "../components/UI/LoadingOverlay";
import authApi from "../services/auth/authApi";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { UnauthenticatedStackParamList } from "@navigation/types";
import { useAppDispatch } from "@store/hooks";
import { loginWithPassword } from "../store/auth/authThunks";

export type LoginScreenNavigationProp = NativeStackScreenProps<
  UnauthenticatedStackParamList,
  "Login"
>;

const LoginScreen: React.FC<LoginScreenNavigationProp> = (props) => {
  const { navigation } = props;
  const dispatch = useAppDispatch();

  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [hasError, setHasError] = useState(false);

  const loginHandler = async ({ email, password }) => {
    setIsAuthenticating(true);

    try {
      dispatch(loginWithPassword({ email: email, password: password }));
    } catch (error) {
      Alert.alert(
        "Authentication failed!",
        "Could not log you in. Please check your credentials or try again later!"
      );
      setIsAuthenticating(false);
      setHasError(true);
    }
  };

  // if (isAuthenticating) {
  //   return <LoadingOverlay message="Logging you in..." />;
  // }

  return (
    <AuthContent
      isLogin
      onAuthenticate={loginHandler}
      navigation={navigation}
    />
  );
};

export default LoginScreen;
