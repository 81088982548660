import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { UI } from "@config/constants";
import { AccessCode } from "@services/api/accessCodes/type";
import { useGetGroupQuery } from "@services/api/groups/groupsApi";
import { Colors } from "@config/colors";
import { useRoute } from "@react-navigation/native";

const AccesCodeDetails: React.FC<{ accessCode: AccessCode }> = ({
  accessCode,
}) => {
  const { data: group } = useGetGroupQuery(accessCode.groupId);

  const route = useRoute();
  return (
    <View style={styles.details}>
      {route.name == "AccessCodes" && (
        <Text style={styles.group}>
          Grupa: <Text style={styles.groupName}>{group?.name}</Text>
        </Text>
      )}
      <Text style={styles.code}>
        {accessCode.code.slice(0, 3) + " " + accessCode.code.slice(3)}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  details: {
    flex: 1.618,
  },
  name: {
    fontWeight: UI.font.weight.semiBold,
    fontSize: 16,
  },
  phoneNumber: {
    fontSize: 12,
    marginVertical: 4,
  },
  group: { fontSize: 12, color: Colors.darkNeutral },
  groupName: { fontSize: 16 },

  code: {
    fontSize: 32,
    color: Colors.darkNeutral,
  },
});

export default AccesCodeDetails;
