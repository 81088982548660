import React, { ReactElement, ReactNode, useEffect } from "react";
import { View, Text, StyleSheet } from "react-native";
import tinycolor from "tinycolor2";
import { UI } from "@config/constants";
import { Colors } from "@config/colors";
import BaseCard from "./BaseCard/BaseCard";

type Props = {
  title: string;
  backgroundColor?: string;
  rightItem?: ReactElement;
  children?: ReactNode;
};

const TitleCard: React.FC<Props> = (props) => {
  const { title, backgroundColor, rightItem, children } = props;

  const textStyle = {
    color: tinycolor(backgroundColor || Colors.backgroundSecondary).isDark()
      ? Colors.darkNeutral
      : Colors.lightNeutral,
  };
  return (
    <BaseCard backgroundColor={backgroundColor} style={styles.card}>
      <View style={styles.header}>
        <Text style={[styles.cardTitle, textStyle]}>{title}</Text>
        <View>{rightItem}</View>
      </View>
      {children}
    </BaseCard>
  );
};

const styles = StyleSheet.create({
  card: {
    // margin: UI.sizes.margin / 2,
    flex: 1,
    // borderWidth: 1,
  },
  header: {
    width: "100%",
    marginBottom: UI.sizes.margin * (1.618 / 2.618),
    flexDirection: "row",
    justifyContent: "space-between",
  },

  cardTitle: {
    fontSize: 16,
    textDecorationLine: "underline",
    color: Colors.lightNeutral,
    opacity: 0.6,
  },
});

export default TitleCard;
