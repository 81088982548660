export const Colors = {
  backgroundPrimary: "#2C2D2E",
  backgroundSecondary: "#1C1D22",
  darkNeutral: "#FEFEFE",
  lightNeutral: "#1C1D22",
  accent: "#FFD572", // => rgb(255, 229, 114)
  error: "#FF7878",
  success: "#2ECC71",
  disabledBackground: "#bfbfbf",
  disabledText: "#8c8c8c",
  disabledBorder: "#d9d9d9",
  primary: "#1C1C1C",
  primary100: "#f2f2f2",
  primary300: "#d9d9d9",
  primary500: "#8c8c8c",
  primary800: "#262626",
  secondary: "#C7C7C7",
  secondary100: "#fafafa",
  secondary300: "#e6e6e6",
  secondary500: "#bfbfbf",
  secondary800: "#333333",
  accent1: "#FF6347",
  accent1100: "#ffe0e0",
  accent1300: "#ff8c8c",
  accent1500: "#ff4d4d",
  accent1800: "#b30000",
  accent2: "#00FFFF",
  accent2100: "#e0ffff",
  accent2300: "#8cd9e8",
  accent2500: "#4db8d9",
  accent2800: "#0080a0",
  // success: "#32CD32",
  success100: "#d9f2e6",
  success300: "#8cd9c7",
  success500: "#4db8a8",
  success800: "#008066",
  // error: "#FF4500",
  error100: "#ffe0e0",
  error300: "#ff8c8c",
  error500: "#ff4d4d",
  error800: "#b30000",
  warning: "#FFC300",
  warning100: "#fff2cc",
  warning300: "#ffe699",
  warning500: "#ffd966",
  warning800: "#b38600",
  neutral: "#FFFFFF",
  neutral100: "#ffffff",
  neutral300: "#f2f2f2",
  neutral500: "#bfbfbf",
  neutral800: "#333333",
  background: "#282828",
  background100: "#f2f2f2",
  background300: "#d9d9d9",
  background500: "#8c8c8c",
  background800: "#262626",
};

export const ColorsPalette = {
  primary: {
    100: "#fff8e8",
    200: "#ffecc0",
    300: "#ffe098",
    400: "#ffd572",
    500: "#facb5e",
    600: "#f4c14d",
    700: "#ecb73d",
    800: "#e3ad2e",
    900: "#d4a025",
  },
};
