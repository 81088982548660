import React, { useState } from "react";
import { View, Text, StyleSheet, Pressable } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { UI } from "@config/constants";
import { Colors } from "@config/colors";
import { Test } from "@services/api/tests/type";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { EmployeeStackParamList } from "@navigation/types";
import { useGetQuestionsQuery } from "@services/api/smsOtp/smsOtpApi";
import QuestionsCounterPerCategory from "./QuestionsCounterPerCategory";
import TestErrors from "./TestErrors";

type Props = {
  test: Test;
  first: boolean;
  last: boolean;
};

type Navigation = NativeStackNavigationProp<
  EmployeeStackParamList,
  "TestsIndex"
>;

const TestListItem: React.FC<Props> = (props) => {
  const { test, first, last } = props;
  const [isHovered, setIsHovered] = useState(false);
  const navigation = useNavigation<Navigation>();

  const handleOnPress = () => {
    navigation.navigate("TestsEdit", { id: test.id });
  };

  return (
    <Pressable
      style={[
        styles.component,
        first ? styles.firstItem : last ? styles.lastItem : {},
      ]}
      onPress={handleOnPress}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
    >
      <View style={[styles.content, last ? { borderBottomWidth: 0 } : {}]}>
        <View style={styles.leftSide}>
          <Text
            style={[
              styles.text,
              styles.nameText,
              isHovered && styles.highlightedTextL,
            ]}
          >
            {test.name}
          </Text>
          <TestErrors questionsCount={test.questionIds?.length} />
        </View>
        <QuestionsCounterPerCategory questionIds={test.questionIds} />
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  component: {
    width: "100%",
    backgroundColor: Colors.backgroundPrimary,
  },
  firstItem: {
    borderTopRightRadius: UI.sizes.borderRadius,
    borderTopLeftRadius: UI.sizes.borderRadius,
    borderColor: "rgba(255, 255, 255, 0.3)",
  },
  lastItem: {
    borderBottomRightRadius: UI.sizes.borderRadius,
    borderBottomLeftRadius: UI.sizes.borderRadius,
  },
  content: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: UI.sizes.padding,
    marginHorizontal: UI.sizes.margin,
    borderBottomWidth: 1,
    borderColor: "rgba(255, 255, 255, 0.1)",
  },
  leftSide: {
    flex: 1,
    justifyContent: "space-between",
  },
  centeredContainer: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  generalDetails: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  nameText: {
    flex: 1,
    fontSize: 18,
  },
  text: {
    flex: 1,
    color: Colors.darkNeutral,
    textAlignVertical: "center",
    marginRight: UI.sizes.margin,
  },
  highlightedTextL: {
    color: Colors.accent,
  },
});

export default TestListItem;
