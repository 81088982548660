import { useContext, useState } from "react";
import { Alert, Platform, StyleSheet, Text, View } from "react-native";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  NoRoleStackParamList,
  UnauthenticatedStackParamList,
} from "@navigation/types";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useCreateSchoolMutation } from "@services/api";
import Input from "@Core/Auth/components/Auth/Input";
import DECButton from "@Core/UI/components/Buttons/DECButton";
import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import { addStudentRequest } from "@store/slices/studentRequestsSlice";
import { useAddStudentRequestMutation } from "@services/api/studentRequests/studentsRequestsApi";
import SixDigitsInput from "@Core/UI/components/Inputs/SixDigitsInput";
import DECTextButton from "@Core/UI/components/Buttons/DECTextButton";

export type JoinSchoolNavigationProp = NativeStackScreenProps<
  NoRoleStackParamList,
  "JoinSchool"
>;

const JoinSchoolScreen: React.FC<JoinSchoolNavigationProp> = (props) => {
  const [addStudentRequest, { isLoading, isError, isSuccess }] =
    useAddStudentRequestMutation();
  const isBigScreen = useAppSelector(
    (state) => state.ui.dimensions.window.width > 400
  );
  const [enteredCode, setEnteredCode] = useState(__DEV__ ? "" : "");

  // Type '"form"' is not assignable to type 'AccessibilityRole'.ts(2769)
  // This is a known issue from React-Native/Typescript

  return (
    <View style={[styles.screen, isBigScreen ? { alignItems: "center" } : {}]}>
      <View
        style={[
          styles.form,
          styles.formContainer,
          isBigScreen ? { width: 320, alignItems: "center" } : {},
        ]}
        {...Platform.select({ ios: { accessibilityRole: "form" } })}
      >
        <Text style={styles.mainLabel}>Alatura-te unei scoli</Text>
        <Text style={styles.secondLabel}>Cere codul profesorului tau</Text>
        <SixDigitsInput handleValueChange={(value) => setEnteredCode(value)} />
        {/* <Input
            label="Cod de acces"
            onUpdateValue={(value) => setEnteredCode(value)}
            value={enteredCode}
            keyboardType="number-pad"
            isInvalid={isError}
          /> */}
        <View style={styles.buttons}>
          <DECTextButton
            onPress={() => addStudentRequest(parseInt(enteredCode))}
            isLoading={isLoading}
            isSuccess={isSuccess}
            title="Trimite cererea"
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Colors.backgroundPrimary,
  },
  formContainer: {
    maxWidth: 1280,
    marginTop: 64,
    marginHorizontal: 32,
    padding: 16,
    borderRadius: 8,
    backgroundColor: Colors.backgroundSecondary,
    elevation: 4,
    shadowColor: "black",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.35,
    shadowRadius: 4,
  },
  form: {
    // justifyContent: "center",
  },
  buttons: {
    marginTop: 12,
  },
  mainLabel: {
    color: Colors.accent,
    fontSize: 16,
    fontWeight: UI.font.weight.bold,
    textAlign: "center",
    marginBottom: 4,
  },
  secondLabel: {
    color: Colors.darkNeutral,
    fontSize: 14,
    fontWeight: UI.font.weight.light,
    opacity: 0.7,
    textAlign: "center",
    marginBottom: 16,
  },
});

export default JoinSchoolScreen;
