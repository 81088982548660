import React, { useState } from "react";
import { View, Text, StyleSheet, Pressable } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { AntDesign, MaterialIcons } from "@expo/vector-icons";

import { QuestionsStackParamList } from "@navigation/types";
import { UI } from "@config/constants";
import { Colors } from "@config/colors";
import { Question } from "@services/api/questions/types";
import QuestionListItemBody from "./QuestionListItemBody";
import { FuseResultMatch } from "fuse.js";
import QuestionListItemPrompt from "./QuestionListItemPrompt";

type Props = {
  question: Question & { matches: FuseResultMatch[] };
  first: boolean;
  last: boolean;
  isSelectable?: boolean;
  isSelected?: boolean;
  isExpanded: boolean;
  onSelect?: (number) => void;
  onPress: (number) => void;
};

type Navigation = NativeStackNavigationProp<QuestionsStackParamList>;

const QuestionListItem: React.FC<Props> = (props) => {
  const {
    question,
    first,
    last,
    isSelectable,
    isSelected,
    isExpanded,
    onSelect,
    onPress,
  } = props;

  const [isEditButtonHovered, setIsEditButtonHovered] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const navigation = useNavigation<Navigation>();

  const handleActionPress = () => {
    if (isSelectable) {
      onSelect(question.id);
    } else {
      navigation.navigate("QuestionsEdit", { id: question.id });
    }
  };

  const handlePress = () => {
    if (isExpanded) {
      onPress(0);
    } else {
      onPress(question.id);
    }
  };

  return (
    <Pressable
      style={[
        styles.component,
        first ? styles.firstItem : last ? styles.lastItem : {},
      ]}
      onPress={handlePress}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
    >
      <View style={[styles.content, last ? { borderBottomWidth: 0 } : {}]}>
        <View style={styles.header}>
          <View style={styles.questionContainer}>
            <View style={styles.expandButton}>
              {isExpanded ? (
                <MaterialIcons
                  name="arrow-drop-up"
                  size={24}
                  color={
                    isHovered && !isEditButtonHovered
                      ? Colors.accent
                      : Colors.darkNeutral
                  }
                />
              ) : (
                <MaterialIcons
                  name="arrow-drop-down"
                  size={24}
                  color={
                    isHovered && !isEditButtonHovered
                      ? Colors.accent
                      : Colors.darkNeutral
                  }
                />
              )}
            </View>
            <QuestionListItemPrompt
              question={question}
              isExpanded={isExpanded}
            />
          </View>
          <View style={styles.actions}>
            <Pressable
              onPress={handleActionPress}
              style={styles.editButton}
              onHoverIn={() => setIsEditButtonHovered(true)}
              onHoverOut={() => setIsEditButtonHovered(false)}
            >
              {isSelectable ? (
                isSelected ? (
                  <AntDesign
                    name="checkcircle"
                    size={24}
                    color={Colors.success}
                  />
                ) : (
                  <AntDesign
                    name="checkcircleo"
                    size={24}
                    color={
                      isEditButtonHovered ? Colors.accent : Colors.darkNeutral
                    }
                  />
                )
              ) : (
                <AntDesign
                  name="edit"
                  size={24}
                  color={
                    isEditButtonHovered ? Colors.accent : Colors.darkNeutral
                  }
                />
              )}
            </Pressable>
          </View>
        </View>
        {isExpanded && (
          <QuestionListItemBody question={question} isVisible={isExpanded} />
        )}
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  component: {
    width: "100%",
    // minHeight: 62,
    backgroundColor: Colors.backgroundPrimary,
  },
  firstItem: {
    borderTopRightRadius: UI.sizes.borderRadius,
    borderTopLeftRadius: UI.sizes.borderRadius,
  },
  lastItem: {
    borderBottomRightRadius: UI.sizes.borderRadius,
    borderBottomLeftRadius: UI.sizes.borderRadius,
  },
  content: {
    flex: 1,
    justifyContent: "space-between",
    paddingVertical: UI.sizes.padding,
    marginHorizontal: UI.sizes.margin,
    borderBottomWidth: 1,
    borderColor: "rgba(255, 255, 255, 0.3)",
  },
  expandButton: { position: "relative", bottom: 3 },
  editButton: { flex: 1 },
  header: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  questionContainer: {
    flexDirection: "row",
    maxWidth: "85%",
  },

  actions: {
    // flex: 1,
    width: "10%",
    alignItems: "flex-end",
  },
});

export default React.memo(QuestionListItem);
