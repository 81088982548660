import DECTextButton from "@Core/UI/components/Buttons/DECTextButton";
import BaseCard from "@components/UI/BaseCard/BaseCard";
import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import { LearningMediumsStackParamList } from "@navigation/types";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React from "react";
import { View, Text, StyleSheet, Modal } from "react-native";

type Navigation = NavigationProp<
  LearningMediumsStackParamList,
  "LearningMediumsShow"
>;
type Props = {
  visible: boolean;
};

const LearningMediumFinishModal: React.FC<Props> = ({ visible }) => {
  const navigation = useNavigation<Navigation>();
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={() => {
        navigation.canGoBack && navigation.goBack();
      }}
    >
      <View style={styles.centeredView}>
        <BaseCard style={styles.modalView}>
          <Text style={styles.modalTitle}>Felicitari!</Text>
          <Text style={styles.modalText}>
            Ai parcurs toate intrebarile din categorie.
          </Text>
          <DECTextButton
            onPress={() => navigation.canGoBack && navigation.goBack()}
            title="Inapoi la mediul de invatare"
          />
        </BaseCard>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: UI.sizes.margin * 2,
    padding: UI.sizes.padding * 2,
    backgroundColor: Colors.accent,
    // borderColor: Colors.accent,
    // borderWidth: 1,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 4,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 24,
    textAlign: "center",
    // color: Colors.darkNeutral,
    color: Colors.lightNeutral,
  },
  modalTitle: {
    marginBottom: 18,
    textAlign: "center",
    color: Colors.success,
    fontWeight: UI.font.weight.semiBold,
    fontSize: 24,
  },
});

export default LearningMediumFinishModal;
