import { api } from "../index";
import { School } from "./types";

export const schoolsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSchool: build.query<School, void>({
      query: () => `schools`,
      transformErrorResponse: (
        response: { status: string | number },
        meta,
        arg
      ) => response.status,
      providesTags: (result, error) => [{ type: "School", id: result.id }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetSchoolQuery } = schoolsApi;
