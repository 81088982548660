import React, { useMemo, useState } from "react";
import { View, Text, StyleSheet, Dimensions } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { StudentDrawerParamList } from "@navigation/types";
import {
  LineChart,
  BarChart,
  PieChart,
  ProgressChart,
  ContributionGraph,
  StackedBarChart,
} from "react-native-chart-kit";
import { useGetStudentRandomTestsQuery } from "@services/api/studentRandomTest/studentRandomTestsApi";
import LoadingOverlay from "@Core/Auth/components/UI/LoadingOverlay";
import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import { ScrollView } from "react-native-gesture-handler";
import ChartLegend from "./ChartLegend";
import CompactHistoryDetails from "@components/Statistics/CompactProgressCard";
import CompactProgressCard from "@components/Statistics/CompactProgressCard";
import TestsCountChart from "./TestsCountChart";
import ActivityChart from "./ActivityChart";

type Props = NativeStackScreenProps<StudentDrawerParamList, "Statistics">;

const StatisticsScreen: React.FC<Props> = (props) => {
  const { navigation } = props;

  const { data: studentRandomTests, isLoading: isLoadingStudentRandomTests } =
    useGetStudentRandomTestsQuery();

  const filteredTests = useMemo(
    () => studentRandomTests?.filter((t) => t.status !== "ongoing"),
    [studentRandomTests?.length]
  );

  if (isLoadingStudentRandomTests) {
    return <LoadingOverlay />;
  }

  return (
    <View style={styles.screen}>
      <ScrollView contentContainerStyle={styles.content}>
        <CompactProgressCard tests={filteredTests} navigation={navigation} />
        <TestsCountChart tests={filteredTests} />
        <ActivityChart tests={filteredTests} />
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    // paddingHorizontal: UI.sizes.padding,
    // paddingTop: UI.sizes.padding,
  },
  content: {
    paddingVertical: UI.sizes.padding,
  },
});

export default StatisticsScreen;
