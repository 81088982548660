import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import React, { ReactNode } from "react";
import { View, Text, StyleSheet, ViewStyle, Image } from "react-native";

type Props = {
  backgroundColor?: string;
  locked?: boolean;
  lockedText?: string;
  style?: ViewStyle | ViewStyle[];
  children?: ReactNode;
};

const BaseCard: React.FC<Props> = (props) => {
  const { backgroundColor, style, locked, lockedText, children } = props;

  if (locked) {
    return (
      <View
        style={[
          styles.lockedCardContainer,
          { backgroundColor: backgroundColor || Colors.backgroundSecondary },
          style,
        ]}
      >
        {locked && (
          <View style={styles.disabledOverlay}>
            <View style={styles.lockedContainer}>
              <Image
                source={require("@assets/icons/lock_light.png")}
                style={styles.lockedIcon}
              />
              <Text style={styles.lockedText}>{lockedText}</Text>
            </View>
          </View>
        )}
        <View style={[styles.lockedCard, style]}>{children}</View>
      </View>
    );
  }

  return (
    <View
      style={[
        styles.card,
        { backgroundColor: backgroundColor || Colors.backgroundSecondary },
        style,
      ]}
    >
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    padding: UI.sizes.padding,
    borderRadius: UI.sizes.borderRadius,
    shadowColor: "rgba(0, 0, 0, 1)",
    shadowRadius: UI.sizes.borderRadius,
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.35,
  },
  lockedCardContainer: {
    borderRadius: UI.sizes.borderRadius,
    shadowColor: "rgba(0, 0, 0, 1)",
    shadowRadius: UI.sizes.borderRadius,
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.35,
  },
  lockedCard: {
    margin: UI.sizes.margin,
    flex: 1,
  },
  disabledOverlay: {
    borderRadius: UI.sizes.borderRadius,
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0, 0, 0, .75)",
    zIndex: 1,
  },
  lockedContainer: {
    flex: 1,
    borderRadius: UI.sizes.borderRadius,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    zIndex: 3,
  },
  lockedIcon: {
    marginBottom: UI.sizes.margin / 2,
    width: 44,
    height: 44,
  },
  lockedText: {
    color: Colors.darkNeutral,
    fontWeight: UI.font.weight.medium,
    flexWrap: "wrap",
  },
});

export default BaseCard;
