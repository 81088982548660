import React, { useEffect } from "react";
import { View, StyleSheet, Text, Pressable } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { QuestionsStackParamList } from "@navigation/types";
import { Colors } from "@config/colors";
import SideMenu from "@components/UI/SideMenu/SideMenu";
import { UI } from "@config/constants";
import QuestionForm from "@components/Questions/Form/QuestionForm";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import {
  useGetQuestionsQuery,
  useRemoveQuestionMutation,
  useUpdateQuestionMutation,
} from "@services/api/questions/questionsApi";
import DECTrashButton from "@Core/UI/components/Buttons/DECTrashButton";
import TitleCard from "@components/UI/TitleCard";
import ImageButton from "@components/UI/ImageButton";

type Props = NativeStackScreenProps<QuestionsStackParamList, "QuestionsEdit">;

const EditQuestionScreen: React.FC<Props> = (props) => {
  const { navigation } = props;
  const route = useRoute<RouteProp<QuestionsStackParamList>>();

  const {
    data: questions,
    isFetching: isFetchingQuestion,
    isLoading: isLoadingQuestion,
  } = useGetQuestionsQuery();
  const [
    updateQuestion,
    {
      isLoading: isLoadingSubmit,
      isSuccess: isSuccessSubmit,
      isError: isErrorSubmit,
    },
  ] = useUpdateQuestionMutation();
  const [
    removeQuestion,
    {
      isLoading: isLoadingRemove,
      isSuccess: isSuccessRemove,
      isError: isErrorRemove,
    },
  ] = useRemoveQuestionMutation();

  const handleRemove = () => {
    removeQuestion(route.params.id);
  };

  useEffect(() => {
    if (isSuccessRemove) {
      setTimeout(() => {
        navigation.navigate("QuestionsIndex");
      }, 500);
    }
  }, [isSuccessRemove]);

  return (
    <View style={styles.screen}>
      <View style={styles.content}>
        <View style={styles.GLDR1}>
          <SideMenu />
        </View>
        <View style={styles.GLDR2}>
          <TitleCard
            title={"Editeaza intrebare"}
            rightItem={
              <ImageButton
                source={require("@assets/icons/close.png")}
                size={16}
                onPress={() => navigation.navigate("QuestionsIndex")}
              />
            }
          >
            <QuestionForm
              isEdit={true}
              isLoading={isLoadingSubmit}
              isSuccess={isSuccessSubmit}
              isError={isErrorSubmit}
              isLoadingQuestion={isLoadingQuestion || isFetchingQuestion}
              question={questions?.find(
                (question) => question.id === route.params.id
              )}
              onSubmit={(questionData) =>
                updateQuestion({ id: route.params.id, ...questionData })
              }
            />
            <DECTrashButton
              isLoading={isLoadingRemove}
              isSuccess={isSuccessRemove}
              isError={isErrorRemove}
              onPress={handleRemove}
            />
          </TitleCard>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Colors.backgroundPrimary,
  },
  content: {
    margin: UI.sizes.margin / 2,
    flex: 1,
    flexDirection: "row",
  },

  GLDR1: { flex: 1 },
  GLDR2: { flex: 1.618 + 2.618, margin: UI.sizes.margin / 2 },
});

export default EditQuestionScreen;
