import React from "react";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { HeaderBackButton } from "@react-navigation/elements";
import {
  LearningMediumsStackParamList,
  StudentDrawerParamList,
} from "@navigation/types";

import { Colors } from "@config/colors";
import LogoTitle from "@components/UI/LogoTitle";
import LearningMediumsIndexScreen from "@screens/student/LearningMediums/LearningMediumsIndexScreen";
import LearningMediumShowScreen from "@screens/student/LearningMediums/LearningMediumsShowScreen";

const LearningMediumsStack =
  createNativeStackNavigator<LearningMediumsStackParamList>();

type DrawerNavigation = NavigationProp<
  StudentDrawerParamList,
  "LearningMediums"
>;

const LearningMediumsStackNavigator: React.FC<{}> = (props) => {
  const drawerNavigation = useNavigation<DrawerNavigation>();

  return (
    <LearningMediumsStack.Navigator
      initialRouteName="LearningMediumsIndex"
      screenOptions={{
        headerStyle: {
          backgroundColor: Colors.backgroundSecondary,
          borderBottomWidth: 0,
        } as {},
        headerTintColor: "#fff",
        headerTitleStyle: {
          fontWeight: "bold",
        },
        headerTitle: (navigation) => <LogoTitle />,
        // headerTransparent: true,
        // headerBlurEffect: "dark",
      }}
    >
      <LearningMediumsStack.Screen
        name="LearningMediumsIndex"
        component={LearningMediumsIndexScreen}
        options={{
          headerShown: true,
          headerLeft: () => (
            <HeaderBackButton
              tintColor={Colors.darkNeutral}
              onPress={() => {
                drawerNavigation.navigate("Home");
              }}
            />
          ),
        }}
      />
      <LearningMediumsStack.Screen
        name="LearningMediumsShow"
        component={LearningMediumShowScreen}
        options={{ headerBackTitleVisible: false }}
      />
    </LearningMediumsStack.Navigator>
  );
};

export default LearningMediumsStackNavigator;
