import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@Core/utils/transfomObjectKeys";
import { api } from "../index";
import { Question } from "./types";
import {
  onAddQuestionQueryStarted,
  onRemoveQuestionQueryStarted,
  onUpdateQuestionQueryStarted,
} from "./utils";

export const questionsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getQuestions: build.query<Question[], void>({
      query: () => "questions",
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Question" as const,
                id,
              })),
              { type: "Question", id: "LIST" },
            ]
          : ["Question"],
    }),

    getQuestion: build.query<Question, number>({
      query: (id) => `questions/${id}`,
      transformErrorResponse: (
        response: { status: string | number },
        meta,
        arg
      ) => response.status,
      providesTags: (result, error, id) => [{ type: "Question", id }],
    }),

    updateQuestion: build.mutation<
      Question,
      Partial<Question> & Pick<Question, "id">
    >({
      query: ({ id, ...patch }) => ({
        url: `questions/${id}`,
        method: "PATCH",
        body: transformKeysToSnakeCase(patch),
      }),

      onQueryStarted: onUpdateQuestionQueryStarted,
    }),

    addQuestion: build.mutation<Question, Omit<Question, "id">>({
      query: ({ ...question }) => ({
        url: `questions`,
        method: "POST",
        body: transformKeysToSnakeCase(question),
      }),
      invalidatesTags: (response, error, arg) => [
        { type: "Question", id: response.id },
      ],
      onQueryStarted: onAddQuestionQueryStarted,
    }),

    removeQuestion: build.mutation<Question, number>({
      query: (id) => ({
        url: `questions/${id}`,
        method: "DELETE",
      }),
      onQueryStarted: onRemoveQuestionQueryStarted,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetQuestionsQuery,
  useGetQuestionQuery,
  useAddQuestionMutation,
  useUpdateQuestionMutation,
  useRemoveQuestionMutation,
} = questionsApi;
