export enum SubscriptionState {
  PENDING = "pending",
  SUBSCRIBED = "subscribed",
  UNSUBSCRIBED = "unsubscribed",
}

export class Subscription {
  identifier: string;
  handler: (payload: any) => void;
  state: SubscriptionState;

  constructor(identifier: string, handler: (payload: any) => void) {
    this.identifier = JSON.parse(identifier);
    this.handler = handler;
    this.state = SubscriptionState.PENDING;
  }

  setState(state: SubscriptionState) {
    this.state = state;
  }

  getIdentifier() {
    return this.identifier;
  }

  handleMessage(payload: any) {
    if (this.handler) {
      this.handler(payload);
    }
  }
}
