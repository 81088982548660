import { Colors } from "@config/colors";
import React, { useMemo } from "react";
import { View, StyleSheet } from "react-native";
import AccesCodeListItem from "./AccesCodeListItem";
import TitleCard from "@components/UI/TitleCard";
import { ScrollView } from "react-native-gesture-handler";
import { useGenerateNewAccessCodeMutation } from "@services/api/accessCodes/accessCodesApi";
import { AccessCode } from "@services/api/accessCodes/type";
import NewFormLink from "@components/UI/BaseCard/NewFormLink";
import EmptyBox from "@components/UI/EmptyBox";
import { Spinner } from "native-base";

type Props = { accessCodes: AccessCode[]; groupId?: number; isLoading };

const AccessCodesList: React.FC<Props> = ({
  accessCodes,
  groupId,
  isLoading,
}) => {
  const [generateNewAccessCode] = useGenerateNewAccessCodeMutation();

  const filteredAccessCodes = useMemo(
    () =>
      groupId
        ? accessCodes.filter((a) => !groupId || a.groupId === groupId)
        : accessCodes,
    [accessCodes, groupId]
  );

  return (
    <TitleCard
      title="Coduri de acces active"
      backgroundColor={Colors.backgroundSecondary}
      rightItem={
        groupId && (
          <NewFormLink
            onPress={() => generateNewAccessCode(groupId)}
            text="Generaza cod de acces"
          />
        )
      }
    >
      {filteredAccessCodes?.length === 0 ? (
        <View style={styles.centered}>
          <EmptyBox message="Nu exista niciun cod de acces activ." />
        </View>
      ) : isLoading ? (
        <Spinner size="lg" />
      ) : (
        <ScrollView>
          {filteredAccessCodes?.map((accessCode, index) => (
            <AccesCodeListItem
              key={accessCode.id}
              accessCode={accessCode}
              first={index === 0}
              last={index === filteredAccessCodes?.length - 1}
            />
          ))}
        </ScrollView>
      )}
    </TitleCard>
  );
};

const styles = StyleSheet.create({
  centered: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: Colors.darkNeutral,
  },
});

export default AccessCodesList;
