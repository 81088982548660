import React, { ReactNode, useEffect } from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { EmployeeStackParamList } from "../types";
import LogoTitle from "@components/UI/LogoTitle";
import DashboardScreen from "@screens/employee/DashboardScreen";
import QuestionsNavigator from "./QuestionsNavigator";
import StudentRequestsScreen from "@screens/employee/access/StudentRequestsScreen";
import AccessCodesScreen from "@screens/employee/access/AccessCodesScreen";
import IndexStudentsScreen from "@screens/employee/students/IndexStudentsScreen";
import { Colors } from "@config/colors";
import GroupsNavigator from "./GroupsNavigator";
import TestsNavigator from "./TestsNavigator";
import { useAppSelector } from "@store/hooks";
import NotAvailableOnMobileScreen from "@screens/employee/NotAvailableOnMobileScreen";
import CreateSchoolScreen from "@screens/none/CreateSchoolScreen";
import VerifyPhoneNumberScreen from "@Core/Auth/screens/VerifyPhoneNumberScreen/VerifyPhoneNumberScreen";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { useGetUserDetailsQuery } from "@services/api/userDetails/userDetailsApi";
import { WEBSOCKET_BASE_URL } from "@config/constants";
import LoadingScreen from "@screens/LoadingScreen";
// import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";

const EmployeeMainStack = createNativeStackNavigator<EmployeeStackParamList>();

const EmployeeNavigator: React.FC<{}> = (props) => {
  const navigation = useNavigation<NavigationProp<EmployeeStackParamList>>();
  const isSmallScreen = useAppSelector(
    (state) => state.ui.dimensions.window.width < 850
  );
  const isAuthenticated = useAppSelector((state) => state.auth.auth.token);
  const { data: userDetails, isLoading: isLoadingUserDetails } =
    useGetUserDetailsQuery(undefined, { skip: !isAuthenticated });

  return (
    <EmployeeMainStack.Navigator
      initialRouteName="Home"
      screenOptions={{
        headerStyle: {
          backgroundColor: Colors.backgroundSecondary,
          borderBottomWidth: 0,
        } as { backgroundColor?: string },
        headerTintColor: "#fff",
        headerTitleStyle: {
          fontWeight: "bold",
        },
        headerTitle: (navigation) => <LogoTitle />,
      }}
    >
      {isLoadingUserDetails ? (
        <EmployeeMainStack.Screen
          name="LoadingScreen"
          component={LoadingScreen}
        />
      ) : isSmallScreen ? (
        <EmployeeMainStack.Screen
          name="NotAvailableOnMobile"
          component={NotAvailableOnMobileScreen}
        />
      ) : !userDetails?.phoneVerified ? (
        <EmployeeMainStack.Screen
          name="PhoneVerification"
          component={VerifyPhoneNumberScreen}
        />
      ) : userDetails?.role === "pending_employee" ? (
        <EmployeeMainStack.Screen
          name="CreateSchool"
          component={CreateSchoolScreen}
        />
      ) : (
        userDetails?.role === "employee" && (
          <>
            <EmployeeMainStack.Screen name="Home" component={DashboardScreen} />
            <EmployeeMainStack.Screen
              name="Questions"
              component={QuestionsNavigator}
            />
            <EmployeeMainStack.Screen
              name="Groups"
              component={GroupsNavigator}
            />
            <EmployeeMainStack.Screen
              name="StudentRequests"
              component={StudentRequestsScreen}
            />
            <EmployeeMainStack.Screen
              name="AccessCodes"
              component={AccessCodesScreen}
            />
            <EmployeeMainStack.Screen
              name="Students"
              component={IndexStudentsScreen}
            />
            <EmployeeMainStack.Screen name="Tests" component={TestsNavigator} />
          </>
        )
      )}
    </EmployeeMainStack.Navigator>
  );
};

export default EmployeeNavigator;
