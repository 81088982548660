import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import { Colors } from "@config/colors";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import {
  EmployeeStackParamList,
  QuestionsStackParamList,
} from "@navigation/types";
import { useGetGroupsQuery } from "@services/api/groups/groupsApi";
import ErrorNotification from "@Core/UI/components/Feedback/ErrorNotification";
import LoadingOverlay from "@Core/Auth/components/UI/LoadingOverlay";
import { Group } from "@services/api/groups/types";
import GroupListItem from "./GroupListItem";
import EmptyBox from "@components/UI/EmptyBox";

type DashboardScreenNavigationProp = NativeStackNavigationProp<
  EmployeeStackParamList,
  "Home"
>;
type QuestionsIndexScreenNavigationProp = NativeStackNavigationProp<
  QuestionsStackParamList,
  "QuestionsIndex"
>;
type Props = {
  // navigation:
  //   | DashboardScreenNavigationProp
  //   | QuestionsIndexScreenNavigationProp;
};

const GroupsList: React.FC<Props> = (props) => {
  // const { navigation } = props;
  const { data, isLoading, isFetching, isError } = useGetGroupsQuery();

  if (isError) {
    return <ErrorNotification />;
  }

  if (isLoading || isFetching) {
    return <LoadingOverlay />;
  }

  if (data?.length === 0) {
    return <EmptyBox message="Momentan nu exista grupe. Adauga una!" />;
  }

  return (
    <>
      <GroupListItem header />
      <FlatList
        data={data}
        keyExtractor={(item) => item.id?.toString()}
        renderItem={({ item, index }) => (
          <GroupListItem group={item} last={index === data.length - 1} />
        )}
      ></FlatList>
    </>
  );
};

const styles = StyleSheet.create({});

export default GroupsList;
