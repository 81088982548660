import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import React, { useEffect, useMemo } from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  Image,
  ActivityIndicator,
} from "react-native";
import RequesListItem from "./RequestListItem";
import TitleCard from "@components/UI/TitleCard";
import { ScrollView } from "react-native-gesture-handler";
import { useGetStudentRequestsQuery } from "@services/api/studentRequests/studentsRequestsApi";
import LoadingOverlay from "@Core/Auth/components/UI/LoadingOverlay";
import ErrorNotification from "@Core/UI/components/Feedback/ErrorNotification";
import { RouteProp, useRoute } from "@react-navigation/native";
import { GroupsStackParamList } from "@navigation/types";
import EmptyBox from "@components/UI/EmptyBox";
import { useAppDispatch } from "@store/hooks";
import { api } from "@services/api";
import DECRefreshButton from "@Core/UI/components/Buttons/DECRefreshButton";

type Route = RouteProp<GroupsStackParamList, "GroupsShow">;

const StudentRequestsList: React.FC<{}> = () => {
  const route = useRoute<Route>();
  const dispatch = useAppDispatch();

  const {
    data: studentRequests,
    isLoading,
    isFetching,
    isError,
    refetch,
    isUninitialized,
  } = useGetStudentRequestsQuery();

  const filteredStudentRequests = useMemo(
    () =>
      isLoading
        ? []
        : studentRequests
            .filter((sr) => sr.status === "pending")
            .filter(
              (sr) => !route.params?.id || sr.group.id === route.params.id
            ),
    [studentRequests]
  );

  return (
    <TitleCard
      title="Cereri in asteptare"
      backgroundColor={Colors.accent}
      rightItem={
        isLoading || isFetching ? (
          <ActivityIndicator color={Colors.backgroundSecondary} />
        ) : (
          <DECRefreshButton
            onPress={() =>
              dispatch(api.util.invalidateTags(["StudentRequest"]))
            }
          />
        )
      }
    >
      {isError ? (
        <ErrorNotification />
      ) : (
        <>
          {filteredStudentRequests?.length === 0 ? (
            <View style={styles.centered}>
              <EmptyBox
                message="Momentan nu exista cereri de inregistrare."
                dark
              />
            </View>
          ) : (
            <ScrollView>
              {isLoading ? (
                <LoadingOverlay />
              ) : (
                filteredStudentRequests.map((studentRequest, index) => (
                  <RequesListItem
                    key={studentRequest.id}
                    data={studentRequest}
                    first={index === 0}
                    last={index === filteredStudentRequests.length - 1}
                  />
                ))
              )}
            </ScrollView>
          )}
        </>
      )}
    </TitleCard>
  );
};

const styles = StyleSheet.create({
  showAllButton: {
    marginTop: UI.sizes.margin * (1.618 / 2.618),
    borderRadius: UI.sizes.borderRadius,
    padding: UI.sizes.padding / 2,
    backgroundColor: Colors.darkNeutral,
  },
  buttonText: {
    width: "100%",
    textAlign: "center",
    fontSize: 16,
    fontWeight: UI.font.weight.medium,
  },
  dotsCard: {
    height: 12,
    marginTop: UI.sizes.margin / 4,
    padding: UI.sizes.margin / 4,
    justifyContent: "center",
    alignItems: "center",
  },
  dotsText: {
    fontSize: 32,
  },
  centered: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default StudentRequestsList;
