import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { QuestionsStackParamList } from "./types";
import IndexQuestionsScreen from "@screens/employee/questions/IndexQuestionsScreen";
import NewQuestionScreen from "@screens/employee/questions/NewQuestionScreen";
import EditQuestionScreen from "@screens/employee/questions/EditQuestionScreen";

const QuestionsStack = createNativeStackNavigator<QuestionsStackParamList>();

const QuestionsNavigator: React.FC<{}> = (props) => {
  return (
    <QuestionsStack.Navigator
      initialRouteName="QuestionsIndex"
      screenOptions={{
        headerShown: false,
      }}
    >
      <QuestionsStack.Screen
        name="QuestionsIndex"
        component={IndexQuestionsScreen}
        // options={{ headerShown: false }}
      />
      <QuestionsStack.Screen
        name="QuestionsNew"
        component={NewQuestionScreen}
        // options={{ headerShown: false }}
      />
      <QuestionsStack.Screen
        name="QuestionsEdit"
        component={EditQuestionScreen}
        // options={{ headerShown: false }}
      />
    </QuestionsStack.Navigator>
  );
};

export default QuestionsNavigator;
