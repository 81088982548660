import { WEBSOCKET_BASE_URL } from "@config/constants";
import { Subscription, SubscriptionState } from "./Subscription";
import { ActionCableMessage, IntervalID, Message } from "./types";
import AsyncStorage from "@react-native-async-storage/async-storage";

interface Identifier {
  channel: string;
  [key: string]: any;
}

class WebSocketService {
  private static instance: WebSocketService | null = null;
  private socket!: WebSocket;
  private subscriptions: { [identifier: string]: Subscription } = {};
  private reconnectTimeoutId?: NodeJS.Timeout;
  private readonly reconnectDelay: number = 5000; // 5 seconds
  private token: string;
  private reconnectTries: number = 0;

  private constructor(token?: string) {
    if (!token) {
      this.getStoredToken();
    }
    this.token = token;
    this.connect();
  }

  getStoredToken = async () => {
    const storedAuthDataStr = await AsyncStorage.getItem("auth");
    const storedAuthData = await JSON.parse(storedAuthDataStr);
    this.token = storedAuthData.token;
  };

  static getInstance(token?: string): WebSocketService {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService(token);
    } else if (token) {
      WebSocketService.instance.token = token;
    }
    return WebSocketService.instance;
  }

  private connect(): void {
    const url = this.token
      ? `${WEBSOCKET_BASE_URL}?token=${this.token}`
      : WEBSOCKET_BASE_URL;
    this.socket = new WebSocket(url);
    this.socket.addEventListener("open", this.handleOpen.bind(this));
    this.socket.addEventListener("close", this.handleClose.bind(this));
    this.socket.addEventListener("message", this.handleMessageEvent.bind(this));

    for (const key in this.subscriptions) {
      if (this.subscriptions[key].state === SubscriptionState.SUBSCRIBED) {
        this.subscriptions[key].state = SubscriptionState.PENDING;
      }
    }
  }

  private handleOpen(): void {
    console.log("WebSocket connection established.");
    this.resubscribePendingChannels();
  }

  private handleClose(): void {
    console.log("WebSocket connection closed. Reconnecting in 5 seconds...");
    this.reconnectTimeoutId = setTimeout(
      () => {
        this.connect();
        this.reconnectTries += 1;
      },
      this.reconnectTries < 2 ? 500 : this.reconnectDelay
    );
  }

  private handleMessageEvent(event: MessageEvent): void {
    const payload: ActionCableMessage = JSON.parse(event.data);
    // if (payload.type == "ping") return;
    switch (payload.type) {
      case "ping":
        break;
      case "confirm_subscription":
        this.subscriptions[payload.identifier].state =
          SubscriptionState.SUBSCRIBED;
        break;
      case "reject_subscription":
        this.subscriptions[payload.identifier].state =
          SubscriptionState.UNSUBSCRIBED;
        break;
      case "message":
        this.subscriptions[payload.identifier].handleMessage(payload.message);
        break;
      // case "welcome":
    }
  }

  private resubscribePendingChannels(): void {
    Object.values(this.subscriptions).forEach((subscription) => {
      if (subscription.state === SubscriptionState.PENDING) {
        this.sendSubscription(subscription);
      }
    });
  }

  subscribe(identifier: Identifier, handler: (payload: any) => void): void {
    const identifierString = JSON.stringify(identifier);
    if (this.subscriptions[identifierString]) {
      if (
        this.subscriptions[identifierString].state ===
        SubscriptionState.SUBSCRIBED
      ) {
        console.log(`Already subscribed to channel ${identifierString}.`);
        return;
      }
    } else {
      this.subscriptions[identifierString] = new Subscription(
        identifierString,
        handler
      );
    }

    if (this.socket.readyState === WebSocket.OPEN) {
      this.sendSubscription(this.subscriptions[identifierString]);
    } else {
      this.subscriptions[identifierString].setState(SubscriptionState.PENDING);
    }
  }

  private sendSubscription(subscription: Subscription): void {
    const identifierString = JSON.stringify(subscription.getIdentifier());
    console.log(
      JSON.stringify({ command: "subscribe", identifier: identifierString })
    );
    this.socket.send(
      JSON.stringify({ command: "subscribe", identifier: identifierString })
    );
    subscription.setState(SubscriptionState.SUBSCRIBED);
  }

  unsubscribe(identifier: Identifier) {
    const identifierString = JSON.stringify(identifier);
    if (this.subscriptions[identifierString]) {
      this.sendUnsubscription(this.subscriptions[identifierString]);
      delete this.subscriptions[identifierString];
    } else {
      console.error(
        `Attempt to unsubscribe from a non-existent channel ${identifierString}`
      );
    }
  }

  private sendUnsubscription(subscription: Subscription) {
    const identifierString = JSON.stringify(subscription.getIdentifier());
    console.log(
      JSON.stringify({ command: "unsubscribe", identifier: identifierString })
    );
    this.socket.send(
      JSON.stringify({ command: "unsubscribe", identifier: identifierString })
    );
    subscription.setState(SubscriptionState.UNSUBSCRIBED);
  }
}

export default WebSocketService;
