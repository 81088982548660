import LoadingOverlay from "@Core/Auth/components/UI/LoadingOverlay";
import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import { useGetQuestionCategoriesQuery } from "@services/api/questionCategories/questionCategoriesApi";
import React, { useState } from "react";
import { View, Text, StyleSheet, Pressable, Image } from "react-native";

type Props = {
  filteredCategories: number[];
  onFilterChange: (categoryId: number) => void;
};

const QuestionsFiltersBody: React.FC<Props> = (props) => {
  const { filteredCategories, onFilterChange } = props;

  const {
    data: questionCategories,
    isLoading,
    isError,
  } = useGetQuestionCategoriesQuery();

  const [isHoveringTrash, setIsHoveringTrash] = useState(false);

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <View style={styles.filterCategoriesBody}>
      {questionCategories.map((category) => (
        <Pressable
          key={category.id}
          style={[
            styles.categoryFilterCard,
            filteredCategories.includes(category.id) &&
              styles.highlightedCategoryFilterCard,
          ]}
          onPress={() => onFilterChange(category.id)}
        >
          <Text style={styles.categoryFilterLabel}>
            {category.name.slice(0, 75)}...
          </Text>
        </Pressable>
      ))}
      <Pressable
        style={[styles.categoryFilterCard, { alignItems: "center" }]}
        onHoverIn={() => setIsHoveringTrash(true)}
        onHoverOut={() => setIsHoveringTrash(false)}
        onPress={() => onFilterChange(null)}
      >
        {isHoveringTrash ? (
          <Image
            source={require("@assets/icons/trash_error.png")}
            style={{ width: 24, height: 24, marginRight: 8 }}
          />
        ) : (
          <Image
            source={require("@assets/icons/trash.png")}
            style={{ width: 24, height: 24, marginRight: 8 }}
          />
        )}
        <Text style={styles.categoryFilterLabel}>Sterge filtrele</Text>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  filterCategoriesBody: {
    flexDirection: "row",
    flexWrap: "wrap",
    paddingBottom: UI.sizes.padding / 2,
  },
  categoryFilterCard: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: UI.sizes.borderRadius,
    // justifyContent: "center",
    backgroundColor: Colors.backgroundPrimary,
    padding: UI.sizes.padding / 2,
    marginRight: UI.sizes.margin / 2,
    marginBottom: UI.sizes.margin / 2,
    width: "23%",
    borderColor: Colors.backgroundSecondary,
    borderWidth: 2,
  },
  highlightedCategoryFilterCard: {
    borderWidth: 1,
    borderColor: Colors.darkNeutral,
  },
  categoryFilterLabel: {
    fontSize: 12,
    flexWrap: "wrap",
    color: Colors.darkNeutral,
    textAlignVertical: "center",
  },
});

export default QuestionsFiltersBody;
