import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "../Core/Auth/store/auth/authSlice";
import uiSlice from "../Core/UI/store/slices/UiSlice";
import { api } from "@services/api";
import roleSlice from "./slices/roleSlice";

export const rootReducer = combineReducers({
  auth: authSlice.reducer,
  role: roleSlice.reducer,
  ui: uiSlice.reducer,
  [api.reducerPath]: api.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch;
export default store;
