import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { SchoolQueryResponse } from "./types";
import { API_BASE_URL } from "@config/constants";
import { RootState } from "@store";
import { updateUserRole } from "@Core/Auth/store/auth/authSlice";
import { transformKeysToCamelCase } from "@Core/utils/transfomObjectKeys";

const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.auth.token;

    headers.set("Accept", "application/json");
    headers.set("Content-Type", "application/json");

    if (token) {
      headers.set("Authorization", token);
    }

    return headers;
  },
});
const baseQueryWithReauthAndTransform: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // TODO implement token refresh
    // try to get a new token
    // const refreshResult = await baseQuery("/refreshToken", api, extraOptions);
    // if (refreshResult.data) {
    //   // store the new token
    //   api.dispatch(tokenReceived(refreshResult.data));
    //   // retry the initial query
    //   result = await baseQuery(args, api, extraOptions);
    // } else {
    //   api.dispatch(loggedOut());
    // }
    // TODO if that fails remove stored auth data
  } else if (result.error) {
    return result;
  } else if (result && Object.keys(result).length > 0) {
    return transformKeysToCamelCase(result);
  }
  return result;
};

export const api = createApi({
  baseQuery: baseQueryWithReauthAndTransform,
  reducerPath: "baseApi",
  tagTypes: [
    "Question",
    "QuestionCategory",
    "Group",
    "Student",
    "Test",
    "TestGroupAccess",
    "AccessCode",
    "StudentRequest",
    "UserDetails",
    "SmsOtp",
    "RandomlyGeneratedTest",
    "StudentRandomTest",
    "School",
    "StudentCategoryProgress",
  ],
  endpoints: (build) => ({
    createSchool: build.mutation<
      SchoolQueryResponse,
      Omit<SchoolQueryResponse, "id">
    >({
      query: ({ ...school }) => ({
        url: `schools`,
        method: "POST",
        body: school,
      }),
      transformErrorResponse: (
        response: { status: string | number },
        meta,
        arga
      ) => response.status,
      invalidatesTags: ["UserDetails"],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: createdSchool } = await queryFulfilled;
          dispatch(updateUserRole("employee"));
        } catch (error: any) {
          console.log(error);
        }
      },
    }),
  }),
});

export const { useCreateSchoolMutation } = api;
