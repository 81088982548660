import React from "react";
import { View, StyleSheet } from "react-native";

import ImageButton from "@components/UI/ImageButton";
import AccesCodeDetails from "./AccesCodeDetails";

import { UI } from "@config/constants";
import { Colors } from "@config/colors";
import { AccessCode } from "@services/api/accessCodes/type";
import { useRemoveAccessCodeMutation } from "@services/api/accessCodes/accessCodesApi";
import DECTrashButton from "@Core/UI/components/Buttons/DECTrashButton";

type Props = {
  accessCode: AccessCode;
  first: boolean;
  last: boolean;
};

const AccesCodeListItem: React.FC<Props> = (props) => {
  const { accessCode, first, last } = props;

  const [
    removeCode,
    {
      isLoading: isLoadingRemove,
      isError: isErrorRemove,
      isSuccess: isSuccessRemove,
    },
  ] = useRemoveAccessCodeMutation();

  return (
    <View
      style={[
        styles.component,
        first && styles.firstItem,
        last && styles.lastItem,
      ]}
    >
      <View style={[styles.content, last ? { borderBottomWidth: 0 } : {}]}>
        <AccesCodeDetails accessCode={accessCode} />
        <View style={styles.actions}>
          <DECTrashButton
            isLoading={isLoadingRemove}
            isError={isErrorRemove}
            isSuccess={isSuccessRemove}
            onPress={() => removeCode(accessCode.id)}
          />
          {/* <ImageButton
            source={require("@assets/icons/trash.png")}
            size={24}
            onPress={() => removeCode(accessCode.id)}
          /> */}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  component: {
    width: "100%",
    // minHeight: 62,
    // backgroundColor: Colors.darkNeutral,
  },
  firstItem: {
    borderTopRightRadius: UI.sizes.borderRadius,
    borderTopLeftRadius: UI.sizes.borderRadius,
  },
  lastItem: {
    borderBottomRightRadius: UI.sizes.borderRadius,
    borderBottomLeftRadius: UI.sizes.borderRadius,
  },
  content: {
    flex: 1,
    flexDirection: "row",
    paddingVertical: UI.sizes.padding,
    paddingHorizontal: UI.sizes.padding,
    borderBottomWidth: 1,
    borderColor: "rgba(255, 255, 255, 0.3)",
  },
  actions: {
    flex: 1,
    flexDirection: "row",
    maxWidth: 24,
  },
});

export default AccesCodeListItem;
