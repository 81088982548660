import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { EmployeeStackParamList } from "@navigation/types";
import { Colors } from "@config/colors";
import SideMenu from "@components/UI/SideMenu/SideMenu";
import { UI } from "@config/constants";
import GroupShowContent from "@components/Groups/Show/GroupsShowContent";
import TitleCard from "@components/UI/TitleCard";
import {
  useGetGroupQuery,
  useRemoveGroupMutation,
} from "@services/api/groups/groupsApi";
import ImageButton from "@components/UI/ImageButton";
import ErrorNotification from "@Core/UI/components/Feedback/ErrorNotification";
import LoadingOverlay from "@Core/Auth/components/UI/LoadingOverlay";
import AccessCodeCard from "@components/AccessCodes/AccessCodeCard";
import StudentRequestsList from "@components/StudentRequests/StudentRequestsList";
import { useGetStudentRequestsQuery } from "@services/api/studentRequests/studentsRequestsApi";
import { ScrollView } from "react-native-gesture-handler";
import DECTrashButton from "@Core/UI/components/Buttons/DECTrashButton";

type Props = NativeStackScreenProps<EmployeeStackParamList, "GroupsShow">;

const ShowGroupScreen: React.FC<Props> = (props) => {
  const { navigation, route } = props;
  return (
    <View style={styles.screen}>
      <View style={styles.content}>
        <View style={styles.GLDR1}>
          <SideMenu />
        </View>
        <View style={styles.GLDR2}>
          <GroupShowContent route={route} navigation={navigation} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Colors.backgroundPrimary,
  },
  content: {
    margin: UI.sizes.margin / 2,
    flex: 1,
    flexDirection: "row",
  },

  GLDR1: { flex: 1 },
  GLDR2: { flex: 1.618 + 2.618, margin: UI.sizes.margin / 2 },
});

export default ShowGroupScreen;
