import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RoleState {
  role: "student" | "employee";
}

const initialState: RoleState = {
  role: "student",
};

const roleSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    changeToStudentRole: (state, action: PayloadAction<undefined>) => {
      state.role = "student";
    },
    changeToEmployeeRole: (state, action: PayloadAction<undefined>) => {
      state.role = "employee";
    },
  },
  extraReducers: (builder) => {},
});

export const { changeToStudentRole, changeToEmployeeRole } = roleSlice.actions;
export default roleSlice;
