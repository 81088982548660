import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import React, { ReactNode } from "react";
import { View, Text, StyleSheet } from "react-native";

type Props = { title: string; children: ReactNode[] };

const SideMenuSection: React.FC<Props> = (props) => {
  return (
    <View style={styles.section}>
      <Text style={styles.sectionTitle}>{props.title}</Text>
      {props.children}
    </View>
  );
};

const styles = StyleSheet.create({
  section: {
    marginTop: (UI.sizes.margin * 1.618) / 2.618,
  },
  sectionTitle: {
    flex: 1,
    fontSize: 16,
    fontWeight: UI.font.weight.extraLight,
    color: Colors.darkNeutral,
    opacity: 0.6,
  },
});

export default SideMenuSection;
