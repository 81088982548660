import { transformKeysToSnakeCase } from "@Core/utils/transfomObjectKeys";
import { api } from "../index";
import { AnswerStudentRandomTestQuestionArg, StudentRandomTest } from "./types";
import {
  onAnswerStudentRandomTestQuestionQueryStarted,
  onGenerateStudentRandomTestQueryStarted,
  onSetTestAsExpired,
} from "./utils";
import { Student } from "../students/type";

export const studentRandomTestsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStudentRandomTests: build.query<StudentRandomTest[], void>({
      query: () => "student_random_tests",
      providesTags: (result, error, arg) => {
        return [{ type: "StudentRandomTest", id: "LIST" }];
      },
    }),
    generateStudentRandomTest: build.mutation<Student, void>({
      query: () => ({
        url: `student_random_tests`,
        method: "POST",
      }),
      onQueryStarted: onGenerateStudentRandomTestQueryStarted,
    }),
    getOngoingStudentRandomTest: build.query<StudentRandomTest, void>({
      query: () => `student_random_tests/ongoing`,
      transformErrorResponse: (
        response: { status: string | number },
        meta,
        arg
      ) => response.status,
      providesTags: (result) => [{ type: "StudentRandomTest", id: result?.id }],
    }),
    getStudentRandomTestById: build.query<StudentRandomTest, SVGAnimatedNumber>(
      {
        query: (id) => `student_random_tests/${id}`,
        transformErrorResponse: (
          response: { status: string | number },
          meta,
          arg
        ) => response.status,
        providesTags: (result) => [
          { type: "StudentRandomTest", id: result.id },
        ],
      }
    ),

    answerStudentRandomTestQuestion: build.mutation<
      StudentRandomTest,
      AnswerStudentRandomTestQuestionArg
    >({
      query: (answer) => ({
        url: `student_random_tests`,
        method: "PATCH",
        body: transformKeysToSnakeCase(answer),
      }),

      onQueryStarted: onAnswerStudentRandomTestQuestionQueryStarted,
    }),

    setTestAsExpired: build.mutation<StudentRandomTest, void>({
      query: () => ({
        url: `student_random_tests/expired`,
        method: "PATCH",
      }),

      onQueryStarted: onSetTestAsExpired,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetStudentRandomTestsQuery,
  useGetOngoingStudentRandomTestQuery,
  useAnswerStudentRandomTestQuestionMutation,
  useSetTestAsExpiredMutation,
  useGenerateStudentRandomTestMutation,
} = studentRandomTestsApi;
