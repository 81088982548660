import { testsApi } from "./testsApi";
import { Test } from "./type";

export const onAddTestQueryStarted = async (
  test,
  { dispatch, queryFulfilled }
) => {
  try {
    const { data } = await queryFulfilled;
    const addResult = dispatch(
      testsApi.util.updateQueryData("getTests", undefined, (draftTests) => {
        draftTests.unshift(data as Test);
      })
    );
  } catch (error: any) {
    console.log(error);
  }
};

export const onUpdateTestQueryStarted = async (
  { id, ...patch },
  { dispatch, queryFulfilled }
) => {
  const patchResultTest = dispatch(
    testsApi.util.updateQueryData("getTest", id, (draft) => {
      Object.assign(draft, patch);
    })
  );
  const patchResultList = dispatch(
    testsApi.util.updateQueryData("getTests", undefined, (draftTests) => {
      const testIndex = draftTests.findIndex((test) => test.id === id);
      draftTests[testIndex] = { id, ...patch } as Test;
    })
  );
  try {
    await queryFulfilled;
  } catch (error: any) {
    patchResultTest.undo();
    patchResultList.undo();
  }
};

export const onRemoveTestQueryStarted = async (
  id,
  { dispatch, queryFulfilled }
) => {
  const removeResult = dispatch(
    testsApi.util.updateQueryData("getTests", undefined, (draftTests) =>
      [...draftTests].filter((q) => q.id !== id)
    )
  );
  try {
    await queryFulfilled;
  } catch (error: any) {
    console.log(error);
    removeResult.undo();
  }
};

export const onAddTestQuestionQueryStarted = async (
  { id, questionId },
  { dispatch, queryFulfilled }
) => {
  const patchResultTest = dispatch(
    testsApi.util.updateQueryData("getTest", id, (draftTest) => {
      Object.assign(draftTest, {
        ...draftTest,
        questionIds: [questionId, ...draftTest.questionIds],
      });
    })
  );
  const patchResultList = dispatch(
    testsApi.util.updateQueryData("getTests", undefined, (draftTests) => {
      const testIndex = draftTests.findIndex((test) => test.id === id);
      draftTests[testIndex] = {
        ...draftTests[testIndex],
        questionIds: [questionId, ...draftTests[testIndex].questionIds],
      } as Test;
    })
  );

  // const patchResultList = dispatch(
  //   testsApi.util.updateQueryData("getTests", undefined, (draftTests) =>
  //     [...draftTests].map((test) => {
  //       if (test.id === id) {
  //         return { ...test, testIds: [testId, ...test.testIds] };
  //       }
  //       return test;
  //     })
  //   )
  // );
  try {
    await queryFulfilled;
  } catch (error: any) {
    patchResultTest.undo();
    patchResultList.undo();
  }
};

export const onRemoveTestQuestionQueryStarted = async (
  { id, questionId },
  { dispatch, queryFulfilled }
) => {
  const patchResultTest = dispatch(
    testsApi.util.updateQueryData("getTest", id, (draftTest) => {
      Object.assign(draftTest, {
        ...draftTest,
        questionIds: draftTest.questionIds.filter((id) => id !== questionId),
      });
    })
  );
  const patchResultList = dispatch(
    testsApi.util.updateQueryData("getTests", undefined, (draftTests) => {
      const testIndex = draftTests.findIndex((test) => test.id === id);
      draftTests[testIndex] = {
        ...draftTests[testIndex],
        questionIds: draftTests[testIndex].questionIds.filter(
          (id) => id !== questionId
        ),
      } as Test;
    })
  );

  try {
    await queryFulfilled;
  } catch (error: any) {
    patchResultTest.undo();
    patchResultList.undo();
  }
};
