import { studentRequestApi } from "./studentsRequestsApi";
import { StudentRequest } from "./type";
import WebSocketService from "@services/WebSocketService/WebSocketService";
import { userDetailsApi } from "../userDetails/userDetailsApi";
import StudentRequestsList from "@components/StudentRequests/StudentRequestsList";
import { studentsApi } from "../students/studentsApi";

export const onAddStudentRequestQueryStarted = async (
  _,
  { dispatch, queryFulfilled }
) => {
  try {
    const { data } = await queryFulfilled;
    const schoolRequest = data.schoolRequest;
    dispatch(
      studentRequestApi.util.updateQueryData(
        "getUserStudentRequest",
        undefined,
        (draftStudentRequests) => {
          Object.assign(draftStudentRequests, schoolRequest);
        }
      )
    );
  } catch (error: any) {
    console.log(error);
  }
};

export const onUpdateStudentRequestQueryStarted = async (
  request,
  { dispatch, queryFulfilled }
) => {
  // Remove the request from the RequestsList
  const patchResultList = dispatch(
    studentRequestApi.util.updateQueryData(
      "getStudentRequests",
      undefined,
      (draftStudentRequests) => {
        const StudentRequestIndex = draftStudentRequests.findIndex(
          (StudentRequest) => StudentRequest.id === request.id
        );
        draftStudentRequests[StudentRequestIndex] = request;
      }
    )
  );
  let patchStudentsList;
  if (request.status === "accepted") {
    // Add the Student to the group's student list with partial data for seemless experience
    patchStudentsList = dispatch(
      studentsApi.util.updateQueryData(
        "getStudentsByGroupId",
        request.group.id as number,
        (draftStudents) => {
          draftStudents.unshift({ id: "TEMPORARY", ...request.student });
        }
      )
    );
  }
  try {
    const result = await queryFulfilled;

    if (request.status === "accepted") {
      // Add the rest of student's data to the list of student for that group
      dispatch(
        studentsApi.util.updateQueryData(
          "getStudentsByGroupId",
          request.group.id as number,
          (draftStudents) => {
            if (
              !draftStudents.find(
                (student) => student.id === result.data.schoolRequest.student.id
              )
            ) {
              draftStudents[0] = result.data.schoolRequest.student;
            }
          }
        )
      );
      // Patch the entire list of students
      dispatch(
        studentsApi.util.updateQueryData(
          "getStudents",
          undefined,
          (draftStudents) => {
            draftStudents.unshift(result.data.schoolRequest.student);
          }
        )
      );
    }
  } catch (error: any) {
    patchResultList.undo();
    patchStudentsList && patchStudentsList.undo();
  }
};

export const onRemoveStudentRequestQueryStarted = async (
  id,
  { dispatch, queryFulfilled }
) => {
  const removeResult = dispatch(
    studentRequestApi.util.updateQueryData(
      "getStudentRequests",
      undefined,
      (draftStudentRequests) =>
        [...draftStudentRequests].filter((a) => a.id !== id)
    )
  );
  try {
    await queryFulfilled;
  } catch (error: any) {
    console.log(error);
    removeResult.undo();
  }
};

// ActionCable

export const onCacheEntryAddedStudentRequests = async (
  arg,
  { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
) => {
  // Get the singleton instance of WebSocketService
  const wsConnection = WebSocketService.getInstance();

  const handleReceivedData = (data) => {
    if (data.type === "ping" || data.channel !== arg) return;

    updateCachedData((draft) => {
      draft.push(data);
    });
  };

  try {
    // Wait for the initial query to resolve before proceeding
    await cacheDataLoaded;

    // Create an identifier for the subscription
    const identifier = { channel: "SchoolRequestChannel" };

    // Subscribe to the channel
    wsConnection.subscribe(identifier, handleReceivedData);
  } catch {
    // No-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
    // in which case `cacheDataLoaded` will throw
  }

  // `cacheEntryRemoved` will resolve when the cache subscription is no longer active
  await cacheEntryRemoved;

  // Perform cleanup steps once the `cacheEntryRemoved` promise resolves.
  // This assumes that WebSocketService has an `unsubscribe` method
  wsConnection.unsubscribe({ channel: "SchoolRequestChannel" });
};

export const onCacheEntryAddedUserStudentRequests = async (
  arg,
  { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }
) => {
  // Get the singleton instance of WebSocketService
  const wsConnection = WebSocketService.getInstance();

  const handleReceivedData = (data) => {
    if (data.type === "ping" || data.channel !== arg) return;
    updateCachedData((draft) => {
      const draftData = { ...draft };
      Object.assign(draft, { ...draftData, ...data });
      dispatch(userDetailsApi.util.invalidateTags(["UserDetails"]));
    });
  };

  try {
    // Wait for the initial query to resolve before proceeding
    await cacheDataLoaded;

    // Create an identifier for the subscription
    const identifier = { channel: "SchoolRequestChannel" };

    // Subscribe to the channel
    wsConnection.subscribe(identifier, handleReceivedData);
  } catch {
    // No-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
    // in which case `cacheDataLoaded` will throw
  }

  // `cacheEntryRemoved` will resolve when the cache subscription is no longer active
  await cacheEntryRemoved;

  // Perform cleanup steps once the `cacheEntryRemoved` promise resolves.
  // This assumes that WebSocketService has an `unsubscribe` method
  wsConnection.unsubscribe({ channel: "SchoolRequestChannel" });
};
