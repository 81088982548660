import { questionsCategories } from "@config/constants";
import { Answer } from "@types";

const groups = [
  {
    id: 1,
    name: "M613",
    startedAt: "2023-06-08T16:30:00+03:00",
    theoreticExamDate: "2023-07-08T10:00:00+03:00",
    practicExamDate: "2023-07-09T10:00:00+03:00",
  },
  {
    id: 2,
    name: "M512",
    startedAt: "2023-05-25T16:30:00+03:00",
    theoreticExamDate: "2023-06-25T10:00:00+03:00",
    practicExamDate: "2023-06-25T10:00:00+03:00",
  },
  {
    id: 3,
    name: "M613",
    startedAt: "2023-06-08T16:30:00+03:00",
    theoreticExamDate: "2023-07-08T10:00:00+03:00",
    practicExamDate: "2023-07-09T10:00:00+03:00",
  },
  {
    id: 4,
    name: "M512",
    startedAt: "2023-05-25T16:30:00+03:00",
    theoreticExamDate: "2023-06-25T10:00:00+03:00",
    practicExamDate: "2023-06-25T10:00:00+03:00",
  },
  {
    id: 5,
    name: "M613",
    startedAt: "2023-06-08T16:30:00+03:00",
    theoreticExamDate: "2023-07-08T10:00:00+03:00",
    practicExamDate: "2023-07-09T10:00:00+03:00",
  },
  {
    id: 6,
    name: "M512",
    startedAt: "2023-05-25T16:30:00+03:00",
    theoreticExamDate: "2023-06-25T10:00:00+03:00",
    practicExamDate: "2023-06-25T10:00:00+03:00",
  },
  {
    id: 7,
    name: "M613",
    startedAt: "2023-06-08T16:30:00+03:00",
    theoreticExamDate: "2023-07-08T10:00:00+03:00",
    practicExamDate: "2023-07-09T10:00:00+03:00",
  },
  {
    id: 8,
    name: "M512",
    startedAt: "2023-05-25T16:30:00+03:00",
    theoreticExamDate: "2023-06-25T10:00:00+03:00",
    practicExamDate: "2023-06-25T10:00:00+03:00",
  },
  {
    id: 9,
    name: "M613",
    startedAt: "2023-06-08T16:30:00+03:00",
    theoreticExamDate: "2023-07-08T10:00:00+03:00",
    practicExamDate: "2023-07-09T10:00:00+03:00",
  },
  {
    id: 10,
    name: "M512",
    startedAt: "2023-05-25T16:30:00+03:00",
    theoreticExamDate: "2023-06-25T10:00:00+03:00",
    practicExamDate: "2023-06-25T10:00:00+03:00",
  },
  {
    id: 11,
    name: "M613",
    startedAt: "2023-06-08T16:30:00+03:00",
    theoreticExamDate: "2023-07-08T10:00:00+03:00",
    practicExamDate: "2023-07-09T10:00:00+03:00",
  },
  {
    id: 12,
    name: "M512",
    startedAt: "2023-05-25T16:30:00+03:00",
    theoreticExamDate: "2023-06-25T10:00:00+03:00",
    practicExamDate: "2023-06-25T10:00:00+03:00",
  },
];
export const dummyData = {
  groups: groups,
  studentRequests: [
    {
      id: 1,
      firstName: "Ion",
      lastName: "Popescu",
      phoneNumber: "+40 (741) 234 567",
      group: groups[0],
    },
    {
      id: 2,
      firstName: "Bob",
      lastName: "Mihai",
      phoneNumber: "+40 (741) 234 567",
      group: groups[0],
    },
    {
      id: 3,
      firstName: "Ion",
      lastName: "Andrei",
      phoneNumber: "+40 (741) 234 567",
      group: groups[1],
    },
  ],
  accessCodes: [
    {
      id: 1,
      code: "12312212",
      group_id: 1,
    },
  ],
  questions: [
    {
      id: 1,
      prompt:
        "Intrebare intrebatoareIntrebare intrebatoareIntrebare intrebatoare?",
      explanation: "",
      category: questionsCategories[0],
      answers: [
        {
          id: 1,
          body: "Corect",
          isCorrect: true,
        },
        {
          id: 2,
          body: "Raspuns 2",
          isCorrect: false,
        },
        {
          id: 3,
          body: "Raspuns 2",
          isCorrect: false,
        },
      ],
    },
    {
      id: 2,
      prompt:
        "Intrebare intrebatoareIntrebare intrebatoareIntrebare intrebatoare?",
      explanation: "",
      category: questionsCategories[1],
      answers: [
        {
          id: 4,
          body: "Corect",
          isCorrect: true,
        },
        {
          id: 5,
          body: "Raspuns 2",
          isCorrect: false,
        },
        {
          id: 6,
          body: "Raspuns 2",
          isCorrect: false,
        },
      ],
    },
    {
      id: 3,
      prompt:
        "Intrebare intrebatoareIntrebare intrebatoareIntrebare intrebatoare?",
      explanation: "",
      category: questionsCategories[4],
      answers: [
        {
          id: 7,
          body: "Corect",
          isCorrect: true,
        },
        {
          id: 8,
          body: "Raspuns 2",
          isCorrect: false,
        },
        {
          id: 9,
          body: "Raspuns 2",
          isCorrect: false,
        },
      ],
    },
    {
      id: 4,
      prompt:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur mollis nulla vitae erat facilisis venenatis. Sed vestibulum orci et quam interdum tempor. Vestibulum in pulvinar quam, vel placerat quam. In vitae condimentum ante. Quisque vel velit eros. Nulla sed ante tincidunt, elementum urna imperdiet, consectetur arcu. Nam imperdiet faucibus dapibus. Sed eget interdum quam. Integer ultrices sollicitudin vehicula. Nullam pharetra dolor in libero tincidunt dignissim. Mauris nec libero malesuada, rutrum metus sed, vulputate magna. Nam non fermentum neque. Nunc lacinia rutrum neque, blandit accumsan risus vehicula ac. ?",
      explanation: "",
      category: questionsCategories[3],
      answers: [
        {
          id: 10,
          body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas efficitur libero eget nibh sodales viverra. Nam malesuada turpis vel pulvinar.",
          isCorrect: true,
        },
        {
          id: 11,
          body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ",
          isCorrect: false,
        },
        {
          id: 12,
          body: "Raspuns 2",
          isCorrect: false,
        },
      ] as Answer[],
    },
  ],
};
