import { Colors } from "@config/colors";
import { Otp } from "@services/api/smsOtp/types";
import React, { useMemo } from "react";
import { View, Text, StyleSheet } from "react-native";

type Props = { errors: string[]; otp?: Otp };

const ErrorsContainer: React.FC<Props> = ({ errors, otp }) => {
  // const errorsArray = useMemo(() => {
  //   if (errors) return errors;

  //   if (otp.attemptsLeft === 0) return [""];
  // }, [otp, errors]);

  return (
    <View style={styles.errorContainer}>
      {errors.map(
        (error) =>
          error && (
            <Text style={styles.errorText} key={error}>
              {error}
            </Text>
          )
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  errorContainer: {
    marginTop: 8,
  },
  errorText: {
    color: Colors.error,
  },
});

export default ErrorsContainer;
