import React, { useEffect } from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { UnauthenticatedStackParamList } from "./types";
import { Colors } from "@config/colors";
import OTPLoginScreen from "@Core/Auth/screens/OTPLoginScreen";
import LoginScreen from "@Core/Auth/screens/LoginScreen";
import SignupScreen from "@Core/Auth/screens/SignupScreen";
import LogoTitle from "@components/UI/LogoTitle";
import VerifyPhoneNumberScreen from "@Core/Auth/screens/VerifyPhoneNumberScreen/VerifyPhoneNumberScreen";
import { useAppSelector } from "@store/hooks";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { StyleProp } from "react-native";

const UnauthenticatedStack =
  createNativeStackNavigator<UnauthenticatedStackParamList>();

const UnauthenticatedNavigator: React.FC<{}> = (props) => {
  const navigation =
    useNavigation<NavigationProp<UnauthenticatedStackParamList>>();
  const role = useAppSelector((state) => state.role.role);

  useEffect(() => {
    if (role === "student") {
      navigation.reset({
        index: 0,
        routes: [{ name: "OTPLogin" }],
      });
    } else {
      navigation.reset({
        index: 0,
        routes: [{ name: "Login" }],
      });
    }
  }, [role]);

  return (
    <UnauthenticatedStack.Navigator
      initialRouteName="OTPLogin"
      screenOptions={{
        headerStyle: {
          backgroundColor: Colors.backgroundSecondary,
          borderBottomWidth: 0,
        } as StyleProp<{ backgroundColor?: string }>,
        headerTintColor: "#fff",
        headerTitleStyle: {
          fontWeight: "bold",
        },
        headerTitle: (navigation) => <LogoTitle />,
      }}
    >
      <UnauthenticatedStack.Screen name="OTPLogin" component={OTPLoginScreen} />
      <UnauthenticatedStack.Screen
        name="PhoneVerification"
        component={VerifyPhoneNumberScreen}
        options={{ headerBackTitleVisible: false }}
      />
      <UnauthenticatedStack.Screen
        name="Login"
        component={LoginScreen}
        options={{ headerBackTitleVisible: false }}
      />
      <UnauthenticatedStack.Screen
        name="Signup"
        component={SignupScreen}
        options={{ headerBackTitleVisible: false }}
      />
    </UnauthenticatedStack.Navigator>
  );
};

export default UnauthenticatedNavigator;
