import { Colors, ColorsPalette } from "@config/colors";
import React from "react";
import { View, Text, StyleSheet, Image, ViewStyle } from "react-native";
import { ViewProps } from "react-native-svg/lib/typescript/fabric/utils";

const uninitializedIcons = {
  I: require("@assets/icons/learning_medium/uninitialized/law-book.png"),
  II: require("@assets/icons/learning_medium/uninitialized/lethal-gun.png"),
  III: require("@assets/icons/learning_medium/uninitialized/non-lethal-gun.png"),
  IV: require("@assets/icons/learning_medium/uninitialized/storage-gun.png"),
  V: require("@assets/icons/learning_medium/uninitialized/travell-gun.png"),
  VI: require("@assets/icons/learning_medium/uninitialized/fine.png"),
  VII: require("@assets/icons/learning_medium/uninitialized/cpr.png"),
};

const ongoingIcons = {
  I: require("@assets/icons/learning_medium/ongoing/law-book.png"),
  II: require("@assets/icons/learning_medium/ongoing/lethal-gun.png"),
  III: require("@assets/icons/learning_medium/ongoing/non-lethal-gun.png"),
  IV: require("@assets/icons/learning_medium/ongoing/storage-gun.png"),
  V: require("@assets/icons/learning_medium/ongoing/travell-gun.png"),
  VI: require("@assets/icons/learning_medium/ongoing/fine.png"),
  VII: require("@assets/icons/learning_medium/ongoing/cpr.png"),
};

const finishedIcons = {
  I: require("@assets/icons/learning_medium/finished/law-book.png"),
  II: require("@assets/icons/learning_medium/finished/lethal-gun.png"),
  III: require("@assets/icons/learning_medium/finished/non-lethal-gun.png"),
  IV: require("@assets/icons/learning_medium/finished/storage-gun.png"),
  V: require("@assets/icons/learning_medium/finished/travell-gun.png"),
  VI: require("@assets/icons/learning_medium/finished/fine.png"),
  VII: require("@assets/icons/learning_medium/finished/cpr.png"),
};

type Props = {
  label: string;
  progress: number;
  totalQuestionsCount: number;
  correctlyAnsweredQuestionsCount: number;
  studentCategoryProgressId: number;
};

const ProgressItem: React.FC<Props> = (props) => {
  const {
    label,
    progress,
    totalQuestionsCount,
    correctlyAnsweredQuestionsCount,
    studentCategoryProgressId,
  } = props;

  const progressBarStyle = {
    borderColor:
      progress === 0
        ? Colors.darkNeutral
        : progress === 1
        ? Colors.success
        : ColorsPalette.primary[500],
    // : Colors.backgroundPrimary,
  };

  const innerProgressBarStyle = {
    width: `${100 * progress}%`,
    height: "100%",
    backgroundColor:
      progress === 0
        ? Colors.darkNeutral
        : progress === 1
        ? Colors.success
        : ColorsPalette.primary[500],
    borderBottomRightRadius: progress === 1 ? 8 : 0,
    borderTopRightRadius: progress === 1 ? 8 : 0,
  };

  const itemIndex = label.split(" ")[0];

  const iconSource =
    progress === 0
      ? uninitializedIcons[itemIndex]
      : progress === 1
      ? finishedIcons[itemIndex]
      : ongoingIcons[itemIndex];

  return (
    <View style={styles.component}>
      <Image source={iconSource} style={styles.progressIcon} />
      <View style={styles.progressBarContainer}>
        <View style={[styles.progressBar, progressBarStyle]}>
          <View
            style={[
              styles.innerProgressBar,
              innerProgressBarStyle as ViewStyle,
            ]}
          ></View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  component: {
    flexDirection: "row",
    marginBottom: 8,
  },
  progressBarContainer: {
    flex: 1,
    justifyContent: "center",
  },
  progressBar: {
    flex: 1,
    marginVertical: 3,
    marginLeft: 8,
    borderRadius: 8,
    borderWidth: 1,
    overflow: "hidden",
  },
  innerProgressBar: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  progressIcon: { width: 16, height: 16, objectFit: "contain" },
});

export default ProgressItem;
