import {
  DrawerContentScrollView,
  DrawerItemList,
} from "@react-navigation/drawer";
import { useGetUserDetailsQuery } from "@services/api/userDetails/userDetailsApi";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import CustomDrawerHeader from "./CustomDrawerHeader";
import MenuButton from "@components/UI/SideMenu/MenuButton";
import { Colors } from "@config/colors";
import { logout } from "@Core/Auth/store/auth/authThunks";
import { UI } from "@config/constants";
import { StyleSheet, View } from "react-native";
import { StudentDrawerParamList } from "@navigation/types";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { isAuthDataValid } from "@Core/Auth/utils/checkAuthDataValidity";
import { SafeAreaView } from "react-native-safe-area-context";
import { useGetSchoolQuery } from "@services/api/schools/schoolsApi";

type Navigation = NavigationProp<StudentDrawerParamList, "Home">;

const CustomDrawerContent = (props) => {
  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector((state) => state.auth.auth.token);
  const { data: userDetails } = useGetUserDetailsQuery(undefined, {
    skip: !isAuthenticated,
  });

  const { data: school } = useGetSchoolQuery();

  const navigation = useNavigation<Navigation>();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <View style={styles.container}>
      <DrawerContentScrollView {...props}>
        <CustomDrawerHeader userDetails={userDetails} school={school} />
        <DrawerItemList {...props} contentContainerStyle={{ flexGrow: 1 }} />
        {/* Other drawer items */}
        <View style={{ flexGrow: 1 }} />
      </DrawerContentScrollView>
      <View style={styles.bottomActions}>
        <MenuButton
          onPress={handleLogout}
          hoverColor={Colors.error}
          color={Colors.error}
        >
          Iesi din cont
        </MenuButton>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "space-between",
  },
  bottomActions: {
    marginBottom: UI.sizes.margin,
    height: 64,
  },
});

export default CustomDrawerContent;
