import React, { useEffect, useState } from "react";
import { Dimensions, Image, StyleSheet, View, Text } from "react-native";
import * as SplashScreen from "expo-splash-screen";
import MainNavigator from "@navigation/MainNavigator";
import { setDimensions } from "@Core/UI/store/slices/UiSlice";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { verifyStoredData } from "./Core/Auth/store/auth/authThunks";
import LoadingOverlay from "@Core/Auth/components/UI/LoadingOverlay";
import { Colors } from "@config/colors";
import DECTextButton from "@Core/UI/components/Buttons/DECTextButton";
import { UI } from "@config/constants";

const AppContent: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        dispatch(setDimensions({ window, screen }));
      }
    );

    return () => subscription?.remove();
  }, []);

  useEffect(() => {
    async function getTokenFromStorage() {
      try {
        await dispatch(verifyStoredData());
        SplashScreen.hideAsync();
        setIsLoading(false);

        // Check if user exists
      } catch (error) {
        console.log("Error in AppContent.tsx: ", error);
        console.error(error);
      }
    }

    getTokenFromStorage();
  }, []);

  if (authState.errors?.includes("NETWORK_ERROR")) {
    return (
      <View style={styles.centered}>
        <Image
          source={require("@assets/icons/error.png")}
          style={{ width: 64, height: 64 }}
        />
        <Text style={styles.bigText}>Nu am putut conecta serverul.</Text>
        <Text style={styles.smallText}>
          Verifica-ti conectiunea la internet sau incearca mai tarziu.
        </Text>
        <DECTextButton
          title="Incearca din nou"
          onPress={() => dispatch(verifyStoredData())}
          containerStyle={styles.retryButton}
          disabled={authState.loading === "pending"}
        />
      </View>
    );
  }

  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <LoadingOverlay />
      </View>
    );
  }

  return <MainNavigator />;
};

const styles = StyleSheet.create({
  centered: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: UI.sizes.padding,
  },
  bigText: {
    fontWeight: "600",
    fontSize: 24,
    color: Colors.darkNeutral,
    flexWrap: "wrap",
    textAlign: "center",
  },
  smallText: {
    fontWeight: "300",
    fontSize: 14,
    color: Colors.darkNeutral,
    flexWrap: "wrap",
    textAlign: "center",
  },
  retryButton: {
    marginTop: 16,
  },
  loadingContainer: {
    flex: 1,
    backgroundColor: Colors.backgroundSecondary,
  },
});

export default AppContent;
