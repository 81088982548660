import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import React, { useEffect, useState } from "react";
import { View, TextInput, StyleSheet } from "react-native";

type Props = { handleValueChange: (value: string) => void };

const SixDigitsInput: React.FC<Props> = ({ handleValueChange }) => {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const inputs = [];

  const handleTextChange = (text, index) => {
    const newOtp = [...otp];
    newOtp[index] = text;

    setOtp(newOtp);
    handleValueChange(newOtp.join(""));

    // If filled, move focus to next input
    if (text.length === 1 && index !== 5) {
      inputs[index + 1].focus();
    }
  };

  const handleKeyPress = (e: any, index: number) => {
    if (e.nativeEvent.key === "Backspace" && otp[index] === "" && index !== 0) {
      inputs[index - 1]?.focus();
    }
  };

  useEffect(() => {
    inputs[0].focus();
  }, []);

  return (
    <View style={styles.otpContainer}>
      {Array.from({ length: 6 }).map((_, index) => (
        <TextInput
          key={index}
          style={styles.SixDigitsInput}
          keyboardType="number-pad"
          autoComplete="one-time-code"
          textContentType="oneTimeCode"
          maxLength={1}
          onChangeText={(text) => handleTextChange(text, index)}
          onKeyPress={(e) => handleKeyPress(e, index)}
          value={otp[index]}
          ref={(input) => inputs.push(input)}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  otpContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  SixDigitsInput: {
    width: 40,
    height: 50,
    borderWidth: 1,
    borderColor: "grey",
    textAlign: "center",
    fontSize: 24,
    marginHorizontal: 5,
    color: Colors.darkNeutral,
    borderRadius: UI.sizes.borderRadius,
  },
});

export default SixDigitsInput;
