import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dimensions } from "react-native";

interface DimensionsState {
  window: {
    height: number;
    width: number;
  };
  screen: {
    height: number;
    width: number;
  };
}

interface AuthState {
  dimensions: DimensionsState;
}

const initialState: AuthState = {
  dimensions: {
    window: Dimensions.get("window"),
    screen: Dimensions.get("screen"),
  },
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setDimensions(state, action: PayloadAction<DimensionsState>) {
      state.dimensions = action.payload;
    },
  },
});

export const { setDimensions } = uiSlice.actions;
export default uiSlice;
