import { getMiddleColor } from "@Core/utils/colors";
import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import { LinearGradient } from "expo-linear-gradient";
import React, { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  StyleProp,
  ViewStyle,
  TextStyle,
} from "react-native";

import tinyColor from "tinycolor2";

type Props = {
  progress: number;
  maxValue?: number; // Defaults 100
  currentValue?: number;
  minValue?: number; // Defaults 0
  displayDecimalProgress?: boolean; // Defaults false
  displayProgressAsFraction?: boolean; // Defaults false
  backgroundColor?: string; // Defaults to Colors.backgroundPrimary
  solidFillColor?: string; // Defaults to Colors.accent
  gradientColors?: string[]; // Array of all the colors
  style?: StyleProp<ViewStyle>;
};

const ProgressBar: React.FC<Props> = (props) => {
  const {
    progress,
    maxValue = 100,
    currentValue,
    minValue = 0,
    displayDecimalProgress = false,
    displayProgressAsFraction = false,
    solidFillColor = Colors.success,
    backgroundColor = Colors.backgroundPrimary,
    gradientColors,
  } = props;

  const [componentDimensions, setComponentDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [textDimensions, setTextDimensions] = useState({
    width: 0,
    height: 0,
  });

  // Variables
  const label = displayProgressAsFraction
    ? `${currentValue} / ${maxValue}`
    : `${(100 * progress).toFixed(displayDecimalProgress ? 2 : 0)}%`;

  const emptyWidth = `${100 - 100 * progress}%`;

  const emptyTextColor = tinyColor(backgroundColor).isDark()
    ? Colors.darkNeutral
    : Colors.lightNeutral;

  const fillTextColor = tinyColor(
    gradientColors
      ? getMiddleColor(gradientColors[0], gradientColors[1])
      : solidFillColor
  ).isDark()
    ? Colors.darkNeutral
    : Colors.lightNeutral;

  // Dynamic Styles
  const filledStyle = {
    width: "100%",
    backgroundColor: solidFillColor,
    left: 0,
    borderRadius: 8,
  } as ViewStyle;

  const emptyStyle = {
    width: emptyWidth,
    backgroundColor: backgroundColor,

    right: 0,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  } as ViewStyle;

  const fillTextStyle = {
    position: "absolute",
    left: (componentDimensions.width - textDimensions.width) / 2,
    top: (componentDimensions.height - textDimensions.height - 6) / 2,
    color: fillTextColor,
  } as TextStyle;

  const emptyTextStyle = {
    position: "absolute",
    left:
      componentDimensions.width * (0.5 - progress) -
      textDimensions.width / 2 +
      1,
    top: (componentDimensions.height - textDimensions.height - 6) / 2,
    color: emptyTextColor,
  } as TextStyle;

  const onLayoutComponent = (event) => {
    const { width, height } = event.nativeEvent.layout;
    setComponentDimensions({ width: width, height: height });
  };

  const onLayoutText = (event) => {
    const { width, height } = event.nativeEvent.layout;
    setTextDimensions({ width: width, height: height });
  };

  return (
    <View onLayout={onLayoutComponent} style={[styles.component, props.style]}>
      {gradientColors?.length > 0 ? (
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
          colors={gradientColors}
          style={[styles.barSharedStyle, filledStyle]}
        />
      ) : (
        <View style={[styles.barSharedStyle, filledStyle]} />
      )}
      <Text style={[styles.label, fillTextStyle]} onLayout={onLayoutText}>
        {label}
      </Text>
      <View style={[styles.barSharedStyle, emptyStyle]}>
        <Text style={[styles.label, emptyTextStyle]}>{label}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  component: {
    height: 20,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "rgba(255, 255, 255, 0.3)",
    overflow: "hidden",

    justifyContent: "center",
    alignItems: "center",
  },
  barSharedStyle: {
    width: "100%",
    height: "100%",

    position: "absolute",
    top: 0,

    overflow: "hidden",
  },
  label: {
    color: Colors.lightNeutral,
    fontWeight: UI.font.weight.semiBold,
    marginVertical: 2,
  },
});

export default ProgressBar;
