import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { EmployeeStackParamList } from "@navigation/types";
import { Colors } from "@config/colors";
import SideMenu from "@components/UI/SideMenu/SideMenu";
import { UI } from "@config/constants";
import StudentRequestsList from "@components/StudentRequests/StudentRequestsList";
import { dummyData } from "@store/dummyData";

type Props = NativeStackScreenProps<EmployeeStackParamList, "StudentRequests">;

const StudentRequestsScreen: React.FC<Props> = (props) => {
  const { navigation } = props;

  return (
    <View style={styles.screen}>
      <View style={styles.content}>
        <View style={[styles.GLDR1, styles.borderRed]}>
          <SideMenu />
        </View>
        <View style={[styles.GLDR2, styles.borderRed]}>
          <StudentRequestsList />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Colors.backgroundPrimary,
  },
  content: {
    margin: UI.sizes.margin / 2,
    flex: 1,
    flexDirection: "row",
    // borderColor: "red", // TODO delete
    // borderWidth: 1, // TODO delete
  },
  borderRed: {
    // borderColor: "red", // TODO delete
    // borderWidth: 1, // TODO delete
  },
  GLDR1: { flex: 1 },
  GLDR2: { flex: 1.618 + 2.618, margin: UI.sizes.margin / 2 },
});

export default StudentRequestsScreen;
