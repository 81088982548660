import tinycolor from "tinycolor2";

export const getMiddleColor = (startColor, endColor) => {
  const start = tinycolor(startColor).toRgb();
  const end = tinycolor(endColor).toRgb();

  // Calculate the midpoint for each color channel
  const mid = {
    r: (start.r + end.r) / 2,
    g: (start.g + end.g) / 2,
    b: (start.b + end.b) / 2,
  };

  return tinycolor(mid).toHexString();
};
