import { transformKeysToSnakeCase } from "@Core/utils/transfomObjectKeys";
import { api } from "../index";
import { Otp } from "./types";
import { onRequestNewOtpStarted, onVerifyOtpQueryStarted } from "./utils";

export const smsOtpApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOtp: build.query<Otp, string | undefined>({
      query: (phoneNumber) =>
        `show${
          phoneNumber
            ? `?phone_number=${phoneNumber
                .replaceAll(" ", "")
                .replace("+", "%2B")}`
            : ""
        }`,
      providesTags: ["SmsOtp"],
    }),

    createOtp: build.mutation<Otp, string | undefined>({
      query: (phoneNumber) => ({
        url: `request_new_otp${
          phoneNumber
            ? `?phone_number=${phoneNumber
                .replaceAll(" ", "")
                .replace("+", "%2B")}`
            : ""
        }`,
        method: "POST",
      }),
      invalidatesTags: ["SmsOtp"],
      onQueryStarted: onRequestNewOtpStarted,
    }),

    verifyOtp: build.mutation<Otp, { phoneNumber: string; otpCode: string }>({
      query: (data) => ({
        url: `verify_otp`,
        method: "POST",
        body: transformKeysToSnakeCase(data),
      }),
      invalidatesTags: ["SmsOtp"],
      onQueryStarted: onVerifyOtpQueryStarted,
    }),

    resendOtp: build.mutation<Otp, string | undefined>({
      query: (phoneNumber) => ({
        url: `resend_otp`,
        method: "POST",
        body: transformKeysToSnakeCase({ phoneNumber: phoneNumber }),
      }),
      invalidatesTags: ["SmsOtp"],
      onQueryStarted: onVerifyOtpQueryStarted,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetOtpQuery,
  useCreateOtpMutation,
  useResendOtpMutation,
  useVerifyOtpMutation,
} = smsOtpApi;
