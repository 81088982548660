import { transformKeysToSnakeCase } from "@Core/utils/transfomObjectKeys";
import { api } from "../index";
import {
  onAddStudentRequestQueryStarted,
  onCacheEntryAddedStudentRequests,
  onCacheEntryAddedUserStudentRequests,
  onUpdateStudentRequestQueryStarted,
} from "./utils";
import { StudentRequest } from "./type";

export const studentRequestApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStudentRequests: build.query<StudentRequest[], void>({
      query: () => "school_requests",
      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.map(({ id }) => ({
                type: "StudentRequest" as const,
                id,
              })),
              { type: "StudentRequest", id: "LIST" },
            ]
          : ["StudentRequest"];
      },
      // onCacheEntryAdded: onCacheEntryAddedStudentRequests,
    }),

    getUserStudentRequest: build.query<StudentRequest, void>({
      query: () => "school_request",
      providesTags: [
        {
          type: "StudentRequest" as const,
          id: "UserStudentRequest",
        },
      ],
      // onCacheEntryAdded: onCacheEntryAddedUserStudentRequests,
    }),

    addStudentRequest: build.mutation<StudentRequest, number>({
      query: (code) => ({
        url: `school_requests`,
        method: "POST",
        body: { code: code },
      }),
      invalidatesTags: (response, error, arg) => [
        { type: "StudentRequest" as const, id: "UserStudentRequest" }, //response.id },
      ],
      // onQueryStarted: onAddStudentRequestQueryStarted,
    }),

    updateStudentRequest: build.mutation<
      StudentRequest,
      Partial<StudentRequest> & Pick<StudentRequest, "id">
    >({
      query: ({ id, ...patch }) => ({
        url: `school_requests/${id}`,
        method: "PATCH",
        body: transformKeysToSnakeCase(patch),
      }),
      // invalidatesTags: (result, error, arg) => [
      //   { type: "Student", id: `LIST_GROUP${arg.group.id}` },
      // ],
      onQueryStarted: onUpdateStudentRequestQueryStarted,
    }),

    cancelStudentRequest: build.mutation<StudentRequest, number>({
      query: (id) => ({
        url: `school_requests/${id}`,
        method: "PATCH",
        body: { status: "canceled" },
      }),
      invalidatesTags: [
        {
          type: "StudentRequest" as const,
          id: "UserStudentRequest",
        },
      ],
    }),

    removeStudentRequest: build.mutation<StudentRequest, number>({
      query: (id) => ({
        url: `school_requests/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (response) => [
        { type: "StudentRequest" as const, id: response.id },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetStudentRequestsQuery,
  useGetUserStudentRequestQuery,
  useAddStudentRequestMutation,
  useCancelStudentRequestMutation,
  useUpdateStudentRequestMutation,
  useRemoveStudentRequestMutation,
} = studentRequestApi;
