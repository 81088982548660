import React, { ReactNode } from "react";
import { View, Text, StyleSheet } from "react-native";
import MenuButton from "./MenuButton";
import BaseCard from "@components/UI/BaseCard/BaseCard";
import { UI } from "@config/constants";
import { Colors } from "@config/colors";
import { useAppDispatch } from "@store/hooks";
import { logout } from "../../../Core/Auth/store/auth/authThunks";
import SideMenuSection from "./SideMenuSection";

const SideMenu: React.FC<{}> = () => {
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <BaseCard style={styles.card}>
      <View>
        <SideMenuSection title="Acces">
          <MenuButton routeName="StudentRequests">Cereri de acces</MenuButton>
          <MenuButton routeName="AccessCodes">Coduri de acces</MenuButton>
        </SideMenuSection>
        <SideMenuSection title="Studenti">
          <MenuButton routeName="Students">Studenti</MenuButton>
          <MenuButton routeName="Groups">Grupe</MenuButton>
        </SideMenuSection>
        <SideMenuSection title="Teste">
          <MenuButton routeName="Questions">Intrebari</MenuButton>
          <MenuButton routeName="Tests">Teste</MenuButton>
        </SideMenuSection>
      </View>
      <View>
        <MenuButton
          onPress={handleLogout}
          hoverColor={Colors.error}
          color={Colors.error}
        >
          Iesi din cont
        </MenuButton>
      </View>
    </BaseCard>
  );
};

const styles = StyleSheet.create({
  card: {
    margin: UI.sizes.margin / 2,
    padding: UI.sizes.padding,
    borderRadius: UI.sizes.borderRadius,
    flex: 1,
    backgroundColor: Colors.backgroundSecondary,
    justifyContent: "space-between",
  },
});

export default SideMenu;
