import DECTextButton from "@Core/UI/components/Buttons/DECTextButton";
import DECInput from "@Core/UI/components/Inputs/DECInput";
import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import { useUpdateUserDetailsMutation } from "@services/api/userDetails/userDetailsApi";
import { useAppSelector } from "@store/hooks";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { View, Text, StyleSheet } from "react-native";

type FormData = {
  firstName: string;
  lastName: string;
};

type Props = {};

const UserDetailsForm: React.FC<Props> = (props) => {
  const [updateUserDetails, { isLoading, isError, isSuccess }] =
    useUpdateUserDetailsMutation();
  const userData = useAppSelector((state) => state.auth.user);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      firstName: userData.firstName || "",
      lastName: userData.lastName || "",
    },
  });

  const onSubmit = (data) => {
    updateUserDetails(data);
  };

  return (
    <View style={styles.formContainer}>
      <Text style={styles.title}>Date personale</Text>
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <DECInput
            placeholder="Ion"
            label="Prenume"
            onBlur={onBlur}
            onUpdateValue={onChange}
            value={value}
            validationFn={() => !!errors.lastName}
            errorMessage="Camp obligatoriu"
            autoFocus
            // isRequired={true}
          />
        )}
        name="firstName"
      />
      {/* {errors.firstName && <Text>Camp obligatoriu.</Text>} */}
      <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <DECInput
            placeholder="Popescu"
            label="Nume"
            onBlur={onBlur}
            onUpdateValue={onChange}
            value={value}
            validationFn={() => !!errors.lastName}
            errorMessage="Camp obligatoriu"
            // isRequired={true}
          />
        )}
        name="lastName"
      />
      {/* {errors.lastName && <Text>Camp obligatoriu.</Text>} */}
      <View style={styles.actions}>
        <DECTextButton
          title={"Continua"}
          onPress={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  formContainer: {
    borderRadius: 8,
    backgroundColor: Colors.backgroundSecondary,
    elevation: 4,
  },
  title: {
    color: Colors.accent,
    fontWeight: UI.font.weight.bold,
    marginBottom: (UI.sizes.margin * 1.618) / 2.618,
  },
  actions: {
    marginTop: (UI.sizes.margin * 1.618) / 2.618,
  },
});

export default UserDetailsForm;
