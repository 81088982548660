import { current } from "@reduxjs/toolkit";

export const helloMessage = () => {
  const currentHour = new Date().getHours();

  if (currentHour < 10 && currentHour >= 4) {
    return "Bună dimineața";
  } else if (currentHour >= 10 && currentHour <= 18) {
    return "Bună ziua";
  } else {
    return "Bună seara";
  }
};
