import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, Image } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { NoRoleStackParamList } from "@navigation/types";
import { Colors } from "@config/colors";
import {
  studentRequestApi,
  useCancelStudentRequestMutation,
  useGetUserStudentRequestQuery,
} from "@services/api/studentRequests/studentsRequestsApi";
import DECTextButton from "@Core/UI/components/Buttons/DECTextButton";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { UI } from "@config/constants";
import { Spinner } from "native-base";

type Props = NativeStackScreenProps<NoRoleStackParamList, "ChooseAccessMethod">;

const PendingRequestScreen: React.FC<Props> = (props) => {
  const { navigation } = props;
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector((state) => state.auth.auth.token);

  const [hasTriedToCheck, setHasTriedToCheck] = useState(false);

  const {
    data: studentRequest,
    isLoading,
    isFetching,
    error: errorGetUserStudentRequest,
    isError: isErrorGetUserStudentRequest,
    refetch,
  } = useGetUserStudentRequestQuery(undefined, { skip: !isAuthenticated });
  const [cancelStudentRequest, { isLoading: isLoadingCancel, isSuccess }] =
    useCancelStudentRequestMutation();

  const handleCancelPendingRequest = () => {
    navigation.navigate("JoinSchool");
    cancelStudentRequest(studentRequest.id);
  };

  // TODO this does not look efficient but fixes a bug, so fix the bug from another place, not here
  useEffect(() => {
    errorGetUserStudentRequest &&
      "status" in errorGetUserStudentRequest &&
      errorGetUserStudentRequest?.status === 404 &&
      dispatch(studentRequestApi.util.resetApiState());
  }, [isErrorGetUserStudentRequest, errorGetUserStudentRequest]);

  useEffect(() => {
    const intervalId = setInterval(() => !isLoading && refetch(), 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <View style={styles.screen}>
      <View style={styles.container}>
        <Text style={styles.title}>Cerere trimisa</Text>
        <View style={styles.header}>
          <Text style={styles.subtitle}>
            Cererea ta e verificata de catre{" "}
            <Text style={styles.schoolNameText}>
              {studentRequest.schoolName}
            </Text>
            . Te rugam sa astepti...
          </Text>

          <View style={styles.iconContainer}>
            <Image
              source={require("@assets/icons/deadline.png")}
              style={{ width: 64, height: 64 }}
            />
          </View>
        </View>
        <View style={styles.spinnerContainer}>
          <Spinner size="lg" />
        </View>

        <DECTextButton
          onPress={handleCancelPendingRequest}
          disabled={isLoadingCancel}
          title="Anuleaza cererea"
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Colors.backgroundPrimary,
    // justifyContent: "center",
    alignItems: "center",
  },
  container: {
    marginTop: 64,
    marginHorizontal: 32,
    padding: 16,
    borderRadius: 8,
    backgroundColor: Colors.backgroundSecondary,
    elevation: 4,
    shadowColor: "black",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.35,
    shadowRadius: 4,
    maxWidth: 360,
  },
  header: {
    flexDirection: "row",
    marginBottom: UI.sizes.margin,
  },
  title: {
    color: Colors.accent,
    fontSize: 24,
    marginBottom: (UI.sizes.margin * 1.618) / 2.618,
    fontWeight: UI.font.weight.bold,
  },
  subtitle: {
    color: Colors.darkNeutral,
    fontSize: 14,
    marginBottom: (UI.sizes.margin * 1.618) / 2.618,
  },
  iconContainer: {
    // width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    color: Colors.darkNeutral,
    opacity: 0.7,
    marginTop: 16,
    marginBottom: 8,
  },
  accentText: {
    color: Colors.accent,
    fontSize: 12,
    marginBottom: 4,
    // opacity: 0.5,
  },
  schoolNameText: {
    fontSize: 16,
    fontWeight: UI.font.weight.extraBold,
  },
  spinnerContainer: {
    marginBottom: UI.sizes.margin,
  },

  buttonContainer: {
    marginBottom: (UI.sizes.margin * 1.618) / 2.618,
  },
});

export default PendingRequestScreen;
