import React from "react";
import { Text, StyleSheet } from "react-native";
import DECBaseButton from "./DECBaseButton";
import { TextButtonProps } from "./types";

const DECTextButton: React.FC<TextButtonProps> = ({ title, ...props }) => {
  const textStyle = [
    styles.buttonText,
    props.disabled && styles.disabledText,
    props.textStyle,
  ];

  return (
    <DECBaseButton {...props} containerStyle={props.containerStyle}>
      <Text style={textStyle}>{title}</Text>
    </DECBaseButton>
  );
};

const styles = StyleSheet.create({
  buttonText: {
    textAlign: "center",
    textAlignVertical: "center",
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
  disabledText: {
    color: "rgba(255, 255, 255, 0.3)",
  },
});

export default DECTextButton;
