import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Colors } from "@config/colors";
import { EmployeeStackParamList } from "@navigation/types";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import DECTextButton from "@Core/UI/components/Buttons/DECTextButton";
import { useAppDispatch } from "@store/hooks";
import { logout } from "@Core/Auth/store/auth/authThunks";
import { UI } from "@config/constants";
import FlatButton from "@Core/Auth/components/UI/FlatButton";

type Props = NativeStackScreenProps<
  EmployeeStackParamList,
  "NotAvailableOnMobile"
>;

const NotAvailableOnMobileScreen: React.FC<Props> = (props) => {
  const { navigation, route } = props;
  const dispatch = useAppDispatch();

  return (
    <View style={styles.screen}>
      <View style={styles.container}>
        <Text style={styles.title}>Ups... Se pare ca esti pe mobil.</Text>
        <MaterialCommunityIcons
          name="cellphone-off"
          size={128}
          color={Colors.accent}
        />
        <Text style={styles.text}>
          Platforma profesorilor nu poate fi folosita de pe telefon.
        </Text>
        <DECTextButton
          title="Iesi din cont"
          onPress={() => dispatch(logout())}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.backgroundPrimary,
  },
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: UI.sizes.padding,
    margin: UI.sizes.margin,
    borderRadius: UI.sizes.borderRadius,
    backgroundColor: Colors.backgroundSecondary,
  },
  title: {
    fontSize: 24,
    fontWeight: UI.font.weight.bold,
    margin: UI.sizes.margin,
    color: Colors.darkNeutral,
  },
  text: {
    fontSize: 18,
    textAlign: "center",
    marginBottom: 20,
    color: Colors.darkNeutral,
    margin: UI.sizes.margin,
  },
  button: {
    backgroundColor: "#e74c3c",
    padding: 10,
    borderRadius: 5,
  },
  buttonText: {
    color: "white",
  },
});

export default NotAvailableOnMobileScreen;
