import { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import DECInput from "@Core/UI/components/Inputs/DECInput";
import DECButton from "@Core/UI/components/Buttons/DECButton";
import { ScrollView } from "react-native-gesture-handler";
import { Colors } from "@config/colors";
import DECPicker from "@Core/UI/components/Inputs/DECPicker";
import { useNavigation } from "@react-navigation/native";
import { QuestionsStackParamList } from "@navigation/types";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useRemoveQuestionMutation } from "@services/api/questions/questionsApi";
import LoadingOverlay from "@Core/Auth/components/UI/LoadingOverlay";
import { UI } from "@config/constants";
import { Question, QuestionCategory } from "@services/api/questions/types";
import { useGetQuestionCategoriesQuery } from "@services/api/questionCategories/questionCategoriesApi";

type Props = {
  question?: Question;
  isEdit?: boolean;
  isLoading: boolean;
  isLoadingQuestion?: boolean;
  isSuccess: boolean;
  isError: boolean;
  onSubmit: (question: Omit<Question, "id"> | Question) => void;
};
const formatDataToStateData = (questionData) => ({
  question: questionData.prompt,
  explanation: questionData.explanation,
  answer1: questionData.answers[0].body,
  answer2: questionData.answers[1].body,
  answer3: questionData.answers[2].body,
  correctAnswerIndex:
    questionData.answers.findIndex((answer) => answer.isCorrect) || 0,
  category: questionData.category,
});

const validatePresence = (value: string) => value.length > 0;

const emptyState = {
  question: "",
  explanation: "",
  answer1: "",
  answer2: "",
  answer3: "",
  correctAnswerIndex: 0,
  category: {
    id: 0,
    label: "",
  },
};

type Navigation = NativeStackNavigationProp<QuestionsStackParamList>;

const QuestionInputLabel = ({ isCorrect }) => {
  return (
    <View style={styles.questionInputLabelContainer}>
      <Text style={styles.textLight}>
        {isCorrect ? "Raspuns " : "Varianta raspuns "}
      </Text>
      <View style={isCorrect ? styles.correct : styles.incorrect}>
        <Text style={styles.textDark}>{isCorrect ? "corect" : "incorect"}</Text>
      </View>
    </View>
  );
};

const QuestionForm = ({
  question,
  isEdit,
  isLoading,
  isLoadingQuestion,
  isSuccess,
  isError,
  onSubmit,
}: Props) => {
  const { navigate } = useNavigation<Navigation>();
  const [
    removeQuestion,
    {
      isLoading: isLoadingDelete,
      isSuccess: isSuccessDelete,
      isError: isErrorDelete,
    },
  ] = useRemoveQuestionMutation();

  const {
    data: questionCategories,
    isLoading: isLoadingQuestionCategory,
    isError: isErrorQuestionCateory,
  } = useGetQuestionCategoriesQuery();

  const [isChanged, setIsChanged] = useState(false);
  const [hasSubmited, setHasSubmited] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [enteredData, setEnteredData] = useState(
    question
      ? {
          question: question?.prompt || "",
          explanation: question?.explanation || "",
          answer1: question?.answers[0]?.body || "",
          answer2: question?.answers[1]?.body || "",
          answer3: question?.answers[2]?.body || "",
          correctAnswerIndex:
            question.answers.findIndex(
              (a) => a.id === question?.correctAnswerId
            ) || 0,
          category:
            question?.category || ({ id: 0, label: "" } as QuestionCategory),
        }
      : emptyState
  );
  const [credentialsInvalid, setCredentialsInvalid] = useState({
    question: isEdit ? false : true,
    explanation: false,
    answer1: isEdit ? false : true,
    answer2: isEdit ? false : true,
    answer3: isEdit ? false : true,
    category: isEdit ? false : true,
  });

  const updateInputValueHandler = (inputType, enteredValue) => {
    setIsChanged(true);
    switch (inputType) {
      case "question":
        setCredentialsInvalid((prevState) => ({
          ...prevState,
          question: !validatePresence(enteredValue),
        }));
        setEnteredData((prevState) => ({
          ...prevState,
          question: enteredValue,
        }));
        break;
      case "explanation":
        setCredentialsInvalid((prevState) => ({
          ...prevState,
          explanation: false,
        }));
        setEnteredData((prevState) => ({
          ...prevState,
          explanation: enteredValue,
        }));
        break;
      case "answer1":
        setCredentialsInvalid((prevState) => ({
          ...prevState,
          answer1: !validatePresence(enteredValue),
        }));
        setEnteredData((prevState) => ({
          ...prevState,
          answer1: enteredValue,
        }));
        break;
      case "answer2":
        setCredentialsInvalid((prevState) => ({
          ...prevState,
          answer2: !validatePresence(enteredValue),
        }));
        setEnteredData((prevState) => ({
          ...prevState,
          answer2: enteredValue,
        }));
        break;
      case "answer3":
        setCredentialsInvalid((prevState) => ({
          ...prevState,
          answer3: !validatePresence(enteredValue),
        }));
        setEnteredData((prevState) => ({
          ...prevState,
          answer3: enteredValue,
        }));
        break;
      case "category":
        setCredentialsInvalid((prevState) => ({
          ...prevState,
          category: !questionCategoriesStrings.includes(enteredValue),
        }));
        setEnteredData((prevState) => ({
          ...prevState,
          category: {
            id: questionCategories.find((qc) => qc.name === enteredValue).id,
            label: enteredValue,
          },
        }));
        break;
    }
  };

  const handleSubmit = () => {
    setHasSubmited(true);
    if (!isInvalid) {
      onSubmit({
        prompt: enteredData.question,
        explanation: enteredData.explanation,
        category: {
          id: enteredData.category.id,
          label: enteredData.category.label,
        },
        correctAnswerId: question?.answers[enteredData.correctAnswerIndex].id,
        answers: [
          {
            id: question?.answers[0].id,
            body: enteredData.answer1,
            isCorrect: enteredData.correctAnswerIndex === 0,
          },
          {
            id: question?.answers[1].id,
            body: enteredData.answer2,
            isCorrect: enteredData.correctAnswerIndex === 1,
          },
          {
            id: question?.answers[2].id,
            body: enteredData.answer3,
            isCorrect: enteredData.correctAnswerIndex === 2,
          },
        ],
      });
    }
  };

  useEffect(() => {
    let isValid = true;
    for (const key in credentialsInvalid) {
      if (credentialsInvalid[key]) {
        isValid = false;
      }
    }
    setIsInvalid(!isValid);
  }, [credentialsInvalid]);

  useEffect(() => {
    if (question) {
      setEnteredData(formatDataToStateData(question));
    }
  }, [question]);

  if (isSuccess) {
    setTimeout(() => {
      navigate("QuestionsIndex");
    }, 500);
  }

  if (isLoadingQuestion || isLoadingQuestionCategory) {
    return <LoadingOverlay />;
  }

  const questionCategoriesStrings = questionCategories.map((qc) => qc.name);

  return (
    <ScrollView
      style={styles.form}
      contentContainerStyle={{
        backgroundColor: Colors.backgroundSecondary,
      }}
    >
      <DECInput
        label="Intrebare"
        onUpdateValue={(value) => updateInputValueHandler("question", value)}
        value={enteredData.question}
        keyboardType="default"
        validationFn={validatePresence}
        errorMessage="Trebuie introdusa o intrebare"
        multiline
        numberOfLines={8}
        autoFocus
        hasSubmited={hasSubmited}
        isRequired
      />
      <DECInput
        label="Explicatie"
        onUpdateValue={(value) => updateInputValueHandler("explanation", value)}
        value={enteredData.explanation}
        validationFn={(value) => true}
        errorMessage=""
        multiline
        hasSubmited={hasSubmited}
      />
      <DECInput
        labelItem={() => (
          <QuestionInputLabel
            isCorrect={enteredData.correctAnswerIndex === 0}
          />
        )}
        onUpdateValue={(value) => updateInputValueHandler("answer1", value)}
        value={enteredData.answer1}
        validationFn={validatePresence}
        errorMessage="Camp obligatoriu"
        multiline
        hasSubmited={hasSubmited}
        isRequired
      />
      <DECInput
        labelItem={() => (
          <QuestionInputLabel
            isCorrect={enteredData.correctAnswerIndex === 1}
          />
        )}
        onUpdateValue={(value) => updateInputValueHandler("answer2", value)}
        value={enteredData.answer2}
        validationFn={validatePresence}
        errorMessage="Camp obligatoriu"
        multiline
        hasSubmited={hasSubmited}
        isRequired
      />
      <DECInput
        labelItem={() => (
          <QuestionInputLabel
            isCorrect={enteredData.correctAnswerIndex === 2}
          />
        )}
        onUpdateValue={(value) => updateInputValueHandler("answer3", value)}
        value={enteredData.answer3}
        validationFn={validatePresence}
        errorMessage="Camp obligatoriu"
        multiline
        hasSubmited={hasSubmited}
        isRequired
      />
      <DECPicker
        label="Categorie"
        options={questionCategoriesStrings}
        onValueChange={(value) => updateInputValueHandler("category", value)}
        selectedValue={enteredData.category.label}
        validationFn={(value) => questionCategoriesStrings.includes(value)}
        errorMessage="Alege o categorie"
        hasSubmited={hasSubmited}
        isRequired
      />
      {!isEdit && (
        <Text style={styles.infoLabel}>
          Raspunsurile sunt ordonate aleatoriu dupa crearea intrebarii.
        </Text>
      )}
      <View style={styles.buttons}>
        <DECButton
          onPress={handleSubmit}
          isLoading={isLoading}
          isSuccess={isSuccess}
        >
          {isEdit ? "Salveaza" : "Adauga intrebarea"}
        </DECButton>
      </View>
    </ScrollView>
  );
};

export default QuestionForm;

const styles = StyleSheet.create({
  form: { width: "100%" },
  buttons: {
    marginTop: 12,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  infoLabel: {
    color: Colors.darkNeutral,
    marginTop: UI.sizes.margin,
  },
  questionInputLabelContainer: { flexDirection: "row", marginBottom: 4 },
  correct: {
    backgroundColor: Colors.success,
    paddingHorizontal: 4,
    borderRadius: 4,
    marginRight: 2,
  },
  incorrect: {
    backgroundColor: Colors.error,
    paddingHorizontal: 4,
    borderRadius: 4,
    marginRight: 2,
  },
  textLight: {
    color: "#A3A3A3",
    fontWeight: UI.font.weight.semiBold,
  },
  textDark: {
    color: Colors.lightNeutral,
  },
});
