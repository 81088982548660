import { Colors } from "@config/colors";
import React from "react";
import { View, Text, StyleSheet, Image } from "react-native";

type Props = { message: string; dark?: boolean };

const EmptyBox: React.FC<Props> = ({ message, dark }) => {
  return (
    <View style={styles.centered}>
      {dark ? (
        <Image
          source={require("@assets/icons/empty_box.png")}
          style={{ width: 128, height: 128 }}
        />
      ) : (
        <Image
          source={require("@assets/icons/empty_box_light.png")}
          style={{ width: 128, height: 128 }}
        />
      )}
      <Text style={[styles.emptyLabel, dark && { color: Colors.lightNeutral }]}>
        {message}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  centered: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  emptyLabel: {
    color: Colors.darkNeutral,
  },
});

export default EmptyBox;
