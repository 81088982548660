import { Alert } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import AuthContent from "../components/Auth/AuthContent";
import { signupWithPassword } from "../store/auth/authThunks";
import { useAppDispatch } from "@store/hooks";
import { UnauthenticatedStackParamList } from "@navigation/types";

export type SignupScreenNavigationProp = NativeStackScreenProps<
  UnauthenticatedStackParamList,
  "Signup"
>;

const SignupScreen: React.FC<SignupScreenNavigationProp> = (props) => {
  const { navigation } = props;
  const dispatch = useAppDispatch();

  async function signupHandler({
    email,
    password,
    firstName,
    lastName,
    phoneNumber,
  }) {
    try {
      dispatch(
        signupWithPassword({
          email: email,
          password: password,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
        })
      );
    } catch (error) {
      Alert.alert(
        "Authentication failed",
        "Could not create user, please check your input and try again later."
      );
    }
  }

  return <AuthContent onAuthenticate={signupHandler} navigation={navigation} />;
};

export default SignupScreen;
