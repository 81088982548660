import React, { useMemo, useState } from "react";
import { View, Text, StyleSheet, Dimensions, Pressable } from "react-native";
import ChartLegend from "./ChartLegend";
import { Colors } from "@config/colors";
import { StudentRandomTest } from "@services/api/studentRandomTest/types";
import { UI } from "@config/constants";
import { LineChart } from "react-native-chart-kit";
import {
  getLast28DaysNames,
  getLast7DaysNames,
  getNumberOfTestsPerDay,
  getNumberOfTestsPerWeek,
  totalTestsChartLegend,
} from "./utils";
import { useAppSelector } from "@store/hooks";
import BaseCard from "@components/UI/BaseCard/BaseCard";

type Props = { tests: StudentRandomTest[] };

const TestsCountChart: React.FC<Props> = ({ tests }) => {
  const isSmallScreen = useAppSelector(
    (state) => state.ui.dimensions.window.width < 850
  );
  const [isShowingLongRange, setIsShowingLongRange] = useState(false);

  const numberOfTestsPerDay = useMemo(
    () => getNumberOfTestsPerDay(tests, isShowingLongRange),
    [tests?.length, isShowingLongRange]
  );

  const numberOfTestsPerWeek = useMemo(
    () => getNumberOfTestsPerWeek(tests),
    [tests?.length]
  );

  const handlePeriodChange = () => {
    setIsShowingLongRange((prev) => !prev);
  };

  const dataToShow = !isShowingLongRange
    ? numberOfTestsPerDay
    : isSmallScreen
    ? numberOfTestsPerWeek
    : numberOfTestsPerDay;

  const labelsToShow = !isShowingLongRange
    ? getLast7DaysNames()
    : isSmallScreen
    ? ["1", "2", "3", "4"]
    : getLast28DaysNames().map((value, index) =>
        (index + 1) % 2 === 0 ? value : ""
      );

  return (
    <BaseCard style={styles.chartCard}>
      <View style={styles.chartHeader}>
        <Text style={[styles.darkText, styles.boldText]}>Chestionare</Text>
        <Pressable onPress={handlePeriodChange}>
          <Text style={[styles.darkText, styles.lightText]}>
            {!isShowingLongRange
              ? "ultimele 7 zile"
              : isSmallScreen
              ? "ultimele 4 saptamani"
              : "ultimele 28 de zile"}
          </Text>
        </Pressable>
      </View>
      <LineChart
        // Modified the library, at line 385 of the LineChart.js (the first <G> rendered, replace the x="0" with x={-16} so that the chart is a bit more centered)
        data={{
          labels: labelsToShow,
          datasets: [
            {
              data: dataToShow.map((v) => v[2]),
              color: () => Colors.error,
            },
            {
              data: dataToShow.map((v) => v[1]),
              color: () => Colors.success,
            },
            {
              data: dataToShow.map((v) => v[0]),
            },
          ],
        }}
        width={Dimensions.get("window").width - 64}
        height={Dimensions.get("window").height / 4}
        // segments={numberOfTestsPerDay.toSorted((a, b) => a[0] - b[0])[0][0]}
        segments={6}
        chartConfig={{
          backgroundColor: Colors.success,
          backgroundGradientFrom: Colors.accent,
          backgroundGradientTo: Colors.accent,
          decimalPlaces: 0, // optional, defaults to 2dp
          color: (opacity) => Colors.backgroundSecondary,
          labelColor: (opacity = 1) => Colors.backgroundSecondary,
          style: {},
          propsForLabels: {},
          propsForDots: {
            r: "4",
            strokeWidth: "4",
            stroke: Colors.accent,
          },
        }}
        bezier
        style={{
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      />
      <View style={styles.chartFooter}>
        <ChartLegend items={totalTestsChartLegend} />
      </View>
    </BaseCard>
  );
};

const styles = StyleSheet.create({
  chartCard: {
    marginTop: UI.sizes.margin,
    marginHorizontal: UI.sizes.margin,
    borderRadius: UI.sizes.borderRadius,
    padding: UI.sizes.padding,
    backgroundColor: Colors.accent,
  },
  chartHeader: {
    backgroundColor: Colors.accent,
    paddingHorizontal: UI.sizes.padding,
    paddingBottom: UI.sizes.padding,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  chartFooter: {
    backgroundColor: Colors.accent,
    paddingHorizontal: UI.sizes.padding,
    paddingVertical: UI.sizes.padding / 2,
    flexDirection: "row",
  },
  boldText: {
    fontWeight: UI.font.weight.bold,
  },
  lightText: {
    fontWeight: UI.font.weight.light,
  },

  darkText: {
    color: Colors.backgroundSecondary,
  },
});

export default TestsCountChart;
