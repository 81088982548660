import { accessCodeApi } from "./accessCodesApi";
import { AccessCode } from "./type";

export const onAddAccessCodeQueryStarted = async (
  _,
  { dispatch, queryFulfilled }
) => {
  try {
    const { data } = await queryFulfilled;
    dispatch(
      accessCodeApi.util.updateQueryData(
        "getAccessCodes",
        undefined,
        (draftAccessCodes) => {
          draftAccessCodes.unshift(data as AccessCode);
        }
      )
    );
  } catch (error: any) {
    console.log(error);
  }
};

export const onRemoveAccessCodeQueryStarted = async (
  id,
  { dispatch, queryFulfilled }
) => {
  const removeFromIndexResult = dispatch(
    accessCodeApi.util.updateQueryData(
      "getAccessCodes",
      undefined,
      (draftAccessCodes) => [...draftAccessCodes].filter((a) => a.id !== id)
    )
  );

  try {
    await queryFulfilled;
  } catch (error: any) {
    console.log(error);
    removeFromIndexResult.undo();
  }
};
