import { Colors } from "@config/colors";
import React from "react";
import { View, Text, StyleSheet } from "react-native";

type Props = { items: { label: string; color: string }[] };

const ChartLegend: React.FC<Props> = ({ items }) => {
  return (
    <View style={styles.chartLegend}>
      {items.map((item) => (
        <View style={styles.chartLegendItem} key={item.color + item.label}>
          <View
            style={[
              styles.chartLegendItemSample,
              { backgroundColor: item.color },
            ]}
          />
          <Text style={styles.darkText}>{item.label}</Text>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  chartLegend: {
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  chartLegendItem: {
    flexDirection: "row",
    alignItems: "center",
  },
  chartLegendItemSample: {
    width: 32,
    height: 4,
    borderRadius: 4,
    marginRight: 8,
  },
  darkText: {
    color: Colors.backgroundSecondary,
  },
});

export default ChartLegend;
