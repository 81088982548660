import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TextInput,
  StyleSheet,
  TextInputProps,
} from "react-native";

import { Colors } from "@config/colors";
import {
  Box,
  FormControl,
  Input,
  Stack,
  WarningOutlineIcon,
} from "native-base";

type Props = {
  label?: string;
  labelItem?: () => JSX.Element;
  onUpdateValue: (text: string) => void;
  onBlur?: (value: string) => void;
  value?: string;
  validationFn?: (value: string) => boolean;
  isInvalid?: boolean;
  errorMessage?: string;
  secure?: boolean;
  hasSubmited?: boolean;
  isRequired?: boolean;
} & TextInputProps;

const DECInput: React.FC<Props> = (props) => {
  const {
    label,
    labelItem,
    onUpdateValue,
    onBlur,
    value = "",
    validationFn,
    isInvalid,
    errorMessage,
    secure,
    multiline,
    numberOfLines,
    hasSubmited,
    isRequired,
    placeholder,

    ...textInputProps
  } = props;
  const [isTouched, setIsTouched] = useState(false);
  const [isInvalidLocal, setIsInvalidLocal] = useState(false);
  const [inputHeight, setInputHeight] = useState(40);

  const handleContentSizeChange = (event: any) => {
    const { contentSize } = event.nativeEvent;
    setInputHeight(contentSize.height);
  };

  const handleChangeText = (value: string) => {
    setIsInvalidLocal(isTouched && !validationFn(value));
    onUpdateValue(value);
  };

  const handleBlur = (event) => {
    onBlur && onBlur(event.currentTarget.value);
  };

  useEffect(
    () => setIsInvalidLocal((isTouched || hasSubmited) && !validationFn(value)),
    [isTouched, hasSubmited]
  );

  return (
    <Box style={styles.inputContainer} testID="box">
      <FormControl isRequired={isRequired} isInvalid={isInvalidLocal}>
        <Stack>
          <FormControl.Label>
            {label ? label : labelItem && labelItem()}
          </FormControl.Label>
          <Input
            style={[multiline && { height: inputHeight }]}
            // defaultValue={value}
            placeholder={placeholder}
            onChangeText={handleChangeText}
            onBlur={handleBlur}
            value={value}
            secureTextEntry={secure}
            multiline={multiline}
            numberOfLines={numberOfLines}
            onContentSizeChange={multiline && handleContentSizeChange}
            {...textInputProps}
          />
          {/* <FormControl.HelperText>
            Trebuie sa aiba cel putin 3 caractere.
          </FormControl.HelperText> */}
          {isInvalidLocal && (
            <FormControl.ErrorMessage
              leftIcon={<WarningOutlineIcon size="xs" />}
            >
              {errorMessage}
            </FormControl.ErrorMessage>
          )}
        </Stack>
      </FormControl>
    </Box>
  );

  return (
    <View style={styles.inputContainer}>
      {label ? (
        <Text style={[styles.label, isInvalidLocal && styles.labelInvalid]}>
          {label}
        </Text>
      ) : labelItem ? (
        labelItem()
      ) : undefined}
      <TextInput
        style={[
          styles.input,
          multiline && { height: inputHeight },
          (isInvalidLocal || isInvalid) && styles.inputInvalid,
        ]}
        autoCapitalize="none"
        onChangeText={handleChangeText}
        onBlur={() => setIsTouched(true)}
        value={value}
        secureTextEntry={secure}
        multiline={multiline}
        numberOfLines={numberOfLines}
        onContentSizeChange={multiline && handleContentSizeChange}
        {...textInputProps}
      />
      {isInvalidLocal && (
        <Text style={styles.errorMessage}>{errorMessage}</Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    marginVertical: 8,
  },
  label: {
    color: "white",
    marginBottom: 4,
  },
  labelInvalid: {
    color: Colors.error500,
  },
  input: {
    paddingVertical: 8,
    paddingHorizontal: 6,
    borderRadius: 4,
    fontSize: 16,
    backgroundColor: Colors.backgroundPrimary,
    color: Colors.darkNeutral,
    outlineStyle: "none",
  },
  inputInvalid: {
    // backgroundColor: Colors.error100,
    borderWidth: 1,
    borderColor: Colors.error100,
  },
  errorMessage: {
    color: Colors.error,
  },
});

export default DECInput;
