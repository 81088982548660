import DECTextButton from "@Core/UI/components/Buttons/DECTextButton";
import { formatSecondsToTime } from "@Core/utils/dateAndTime";
import BaseCard from "@components/UI/BaseCard/BaseCard";
import { Colors, ColorsPalette } from "@config/colors";
import { UI } from "@config/constants";
import { StudentDrawerParamList } from "@navigation/types";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { Question } from "@services/api/questions/types";
import {
  useGenerateStudentRandomTestMutation,
  useSetTestAsExpiredMutation,
} from "@services/api/studentRandomTest/studentRandomTestsApi";
import { StudentRandomTest } from "@services/api/studentRandomTest/types";
import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, Image } from "react-native";
import HomeScreenCard from "../HomeScreenCard";

type Navigation = NavigationProp<StudentDrawerParamList, "Home">;
type Props = { test: StudentRandomTest; questions: Question[] };

const TestCard: React.FC<Props> = ({ test, questions }) => {
  const navigation = useNavigation<Navigation>();

  const [
    setTestAsExpired,
    { isLoading: isLoadingSetTestAsExpired, isError: isErrorSetTestAsExpired },
  ] = useSetTestAsExpiredMutation();

  const [generateTest, { isLoading: isLoadingGenerateTest }] =
    useGenerateStudentRandomTestMutation();

  const [secondsLeft, setSecondsLeft] = useState(() => {
    const totalSeconds = 3600;
    const elapsedSeconds = Math.floor(
      (new Date().getTime() - new Date(test?.startedAt).getTime()) / 1000
    );
    return totalSeconds - elapsedSeconds;
  });

  const answersArray = test?.questions.map((questionArray) => {
    if (questionArray[1] === null) return "Unanswered";
    const question = questions?.find((q) => q.id === questionArray[0]);

    if (question.correctAnswerId === questionArray[1]) {
      return "Correct";
    }

    return "Incorrect";
  });

  const handleButtonPress = () => {
    const generateTestAndNavigate = async () => {
      if (!test || test?.status !== "ongoing") {
        await generateTest();
      }

      navigation.navigate("Test");
    };

    generateTestAndNavigate();
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSecondsLeft((prevSeconds) => {
        if (prevSeconds <= 0) {
          clearInterval(intervalId);
          setTestAsExpired();
          return 0;
        } else {
          return prevSeconds - 1;
        }
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const header = (
    <>
      {test?.status !== "ongoing" && (
        <View style={styles.textIconItem}>
          <Text style={styles.lightText}>20</Text>
          <Image
            source={require("@assets/icons/question.png")}
            style={{ width: 24, height: 24, marginLeft: 8 }}
          />
        </View>
      )}
      <View style={styles.textIconItem}>
        <Text style={[styles.lightText, styles.timer]}>
          {formatSecondsToTime(test?.status === "ongoing" ? secondsLeft : 3600)}
        </Text>
        <Image
          source={require("@assets/icons/timer.png")}
          style={{ width: 24, height: 24, marginLeft: 8 }}
        />
      </View>
    </>
  );

  return (
    <HomeScreenCard
      title={test?.status === "ongoing" ? "Test in derulare" : "Test aleatoriu"}
      buttonText={
        test?.status === "ongoing" ? "Continuă testul" : "Începe un nou test"
      }
      header={header}
      onButtonPress={handleButtonPress}
    >
      {test?.status === "ongoing" && (
        <>
          <View style={styles.progressInfo}>
            <View style={styles.textIconItem}>
              <Text style={styles.lightText}>{test?.correctAnswersCount}</Text>
              <Image
                source={require("@assets/icons/checked.png")}
                style={{ width: 24, height: 24, marginLeft: 8 }}
              />
            </View>
            <View style={styles.textIconItem}>
              <Text style={styles.lightText}>{test?.wrongAnswersCount}</Text>
              <Image
                source={require("@assets/icons/cancel.png")}
                style={{ width: 24, height: 24, marginLeft: 8 }}
              />
            </View>
            <View style={styles.textIconItem}>
              <Text style={styles.lightText}>
                {20 - test?.correctAnswersCount - test?.wrongAnswersCount}
              </Text>
              <Image
                source={require("@assets/icons/question.png")}
                style={{ width: 24, height: 24, marginLeft: 8 }}
              />
            </View>
          </View>
          <View style={styles.progressBarContainer}>
            {answersArray.map((answer, index) => (
              <View
                key={`${index}${answer}`}
                style={[
                  styles.progressBarItem,
                  styles[`progressBarItem${answer}`],
                ]}
              />
            ))}
          </View>
        </>
      )}
    </HomeScreenCard>
  );
};

const styles = StyleSheet.create({
  component: {
    marginBottom: UI.sizes.margin,
  },
  content: {
    flex: 1,
    padding: UI.sizes.padding,
    borderColor: "red",
    borderWidth: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 4,
    marginBottom: UI.sizes.margin,
  },
  titleContainer: {
    borderBottomWidth: 2,
    borderBottomColor: Colors.darkNeutral,
    // borderBottomColor: ColorsPalette.primary[500],
  },
  title: {
    color: Colors.darkNeutral,
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: 16,
    fontWeight: "bold",
  },
  timer: {
    fontWeight: UI.font.weight.light,
    color: ColorsPalette.primary[500],
  },
  body: {},
  progressInfo: {
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
  },
  textIconItem: {
    flexDirection: "row",
  },
  progressBarContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginVertical: UI.sizes.margin,
  },
  lightText: {
    fontSize: 20,
    fontWeight: "bold",
    color: Colors.darkNeutral,
  },
  progressBarItem: {
    width: 12,
    height: 12,
    borderWidth: 1,
  },
  progressBarItemUnanswered: {
    borderColor: Colors.disabledBorder,
  },
  progressBarItemCorrect: {
    borderColor: Colors.success,
    backgroundColor: Colors.success,
  },
  progressBarItemIncorrect: {
    borderColor: Colors.error,
    backgroundColor: Colors.error,
  },
  footer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  continueButton: {
    backgroundColor: ColorsPalette.primary[500],
    // width: "45%",
  },
  continueButtonText: {
    color: Colors.backgroundPrimary,
  },
});

export default TestCard;
