import { Pressable, StyleSheet, Text, View } from "react-native";

import { Colors } from "@config/colors";
import { useState } from "react";

function FlatButton({ children, onPress }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Pressable
      style={({ pressed }) => [styles.button, pressed && styles.pressed]}
      onPress={onPress}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
    >
      <View>
        <Text
          style={[styles.buttonText, isHovered && styles.buttonTextHovered]}
        >
          {children}
        </Text>
      </View>
    </Pressable>
  );
}

export default FlatButton;

const styles = StyleSheet.create({
  button: {
    paddingVertical: 6,
    // paddingHorizontal: 12,
  },
  pressed: {
    opacity: 0.7,
  },
  buttonText: {
    textAlign: "center",
    width: "100%",
    color: Colors.secondary,
  },
  buttonTextHovered: {
    opacity: 0.7,
  },
});
