import NewFormLink from "@components/UI/BaseCard/NewFormLink";
import ImageButton from "@components/UI/ImageButton";
import TitleCard from "@components/UI/TitleCard";
import React, { useState } from "react";
import { StyleSheet } from "react-native";
import GroupForm from "./GroupForm";
import GroupsList from "@components/Groups/Index/GroupsList";
import ErrorNotification from "@Core/UI/components/Feedback/ErrorNotification";
import LoadingOverlay from "@Core/Auth/components/UI/LoadingOverlay";
import { useGetGroupsQuery } from "@services/api/groups/groupsApi";

const GroupsIndexContent: React.FC<{}> = () => {
  const { isLoading, isFetching, isError } = useGetGroupsQuery();
  const [isFormOpen, setIsFormOpen] = useState(false);

  if (isError) {
    return <ErrorNotification />;
  }

  if (isLoading || isFetching) {
    return <LoadingOverlay />;
  }

  return (
    <TitleCard
      title={"Grupe"}
      rightItem={
        isFormOpen ? (
          <ImageButton
            source={require("@assets/icons/close.png")}
            size={16}
            onPress={() => setIsFormOpen(false)}
          />
        ) : (
          <NewFormLink
            onPress={() => setIsFormOpen(true)}
            text="Adauga o grupa"
          />
        )
      }
    >
      {isFormOpen && <GroupForm onSubmit={() => setIsFormOpen(false)} />}
      <GroupsList />
    </TitleCard>
  );
};

const styles = StyleSheet.create({
  component: {},
});

export default GroupsIndexContent;
