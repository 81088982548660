import {
  ActivityIndicator,
  Pressable,
  PressableStateCallbackType,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { ReactNode } from "react";
import { Colors } from "@config/colors";
import tinycolor from "tinycolor2";
import { UI } from "@config/constants";

type Props = {
  children: ReactNode;
  onPress: () => void;
  isLoading?: boolean;
  isSuccess?: boolean;
  disabled?: boolean;
};

const DECButton = ({
  children,
  onPress,
  isLoading,
  isSuccess,
  disabled,
}: Props) => {
  return (
    <Pressable
      onPress={onPress}
      disabled={isLoading || disabled}
      style={({
        pressed,
        hovered,
      }: PressableStateCallbackType & { hovered?: boolean }) => [
        styles.button,
        isSuccess && styles.success,
        { opacity: pressed ? 0.6 : hovered ? 0.8 : 1 },
      ]}
    >
      <View
        style={[
          styles.content,
          isLoading && { position: "relative", left: -15 },
        ]}
      >
        {isLoading && (
          <ActivityIndicator
            size="small"
            color={Colors.darkNeutral}
            style={styles.activityIndicator}
          />
        )}
        {typeof children === "string" ? (
          <Text style={[styles.buttonText, disabled && styles.disabledText]}>
            {children}
          </Text>
        ) : (
          children
        )}
      </View>
    </Pressable>
  );
};

export default DECButton;

const styles = StyleSheet.create({
  button: {
    borderRadius: 6,
    backgroundColor: Colors.backgroundPrimary,
    elevation: 4,
    shadowColor: "black",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
  },
  pressed: {
    opacity: 0.7,
  },
  success: {
    backgroundColor: Colors.success,
  },
  error: {
    backgroundColor: Colors.error,
  },
  buttonText: {
    textAlign: "center",
    textAlignVertical: "center",
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
  disabledText: {
    color: "rgba(255, 255, 255, 0.3)",
  },
  content: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: UI.sizes.padding,
    paddingVertical: (UI.sizes.padding * 1.618) / 2.618,
  },
  activityIndicator: {
    marginRight: 10,
  },
});
