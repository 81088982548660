import { useState } from "react";
import { Platform, StyleSheet, View, Text } from "react-native";
// import Button from "@Core/UI/Buttons/Button";
import Input from "./Input";
import { useAppSelector } from "@store/hooks";
import DECButton from "@Core/UI/components/Buttons/DECButton";
import PhoneNumberInput from "../../../UI/components/Inputs/PhoneNumberInput";
import { Colors } from "@config/colors";

function AuthForm({ isLogin, onSubmit, credentialsInvalid }) {
  const loadingState = useAppSelector((state) => state.auth.loading);
  const errors = useAppSelector((state) => state.auth.errors);

  const [enteredEmail, setEnteredEmail] = useState(
    __DEV__ ? "admin@admin.com" : ""
  );
  const [enteredPassword, setEnteredPassword] = useState(
    __DEV__ ? "admin123" : ""
  );
  const [enteredPhoneNumber, setEnteredPhoneNumber] = useState(
    __DEV__ ? "749 096 856" : ""
  );
  const [enteredFirstName, setEnteredFirstName] = useState(
    __DEV__ ? "firstname" : ""
  );
  const [enteredLastName, setEnteredLastName] = useState(
    __DEV__ ? "lastname" : ""
  );

  const {
    email: emailIsInvalid,
    password: passwordIsInvalid,
    phoneNumber: phoneNumberIsValid,
    firstName: firstNameIsInvalid,
    lastName: lastNameIsInvalid,
  } = credentialsInvalid;

  const updateInputValueHandler = (inputType, enteredValue) => {
    switch (inputType) {
      case "email":
        setEnteredEmail(enteredValue);
        break;
      case "password":
        setEnteredPassword(enteredValue);
        break;
      case "phoneNumber":
        setEnteredPhoneNumber(enteredValue);
        break;
      case "firstName":
        setEnteredFirstName(enteredValue);
        break;
      case "lastName":
        setEnteredLastName(enteredValue);
        break;
    }
  };

  const submitHandler = () => {
    onSubmit({
      email: enteredEmail,
      password: enteredPassword,
      phoneNumber: enteredPhoneNumber,
      firstName: enteredFirstName,
      lastName: enteredLastName,
    });
  };

  // Type '"form"' is not assignable to type 'AccessibilityRole'.ts(2769)
  // This is a known issue from React-Native/Typescript

  return (
    <View style={styles.form}>
      <View {...Platform.select({ ios: { accessibilityRole: "form" } })}>
        <Input
          label="Email"
          onUpdateValue={(value) => updateInputValueHandler("email", value)}
          value={enteredEmail}
          keyboardType="email-address"
          isInvalid={emailIsInvalid}
        />
        {errors?.email?.map((message) => (
          <Text style={styles.errorLabel}>{message}</Text>
        ))}

        <Input
          label="Parola"
          onUpdateValue={(value) => updateInputValueHandler("password", value)}
          secure
          value={enteredPassword}
          isInvalid={passwordIsInvalid}
        />
        {errors?.password?.map((message) => (
          <Text style={styles.errorLabel}>{message}</Text>
        ))}
        {!isLogin && (
          <>
            <PhoneNumberInput
              label="Telefon"
              onUpdateValue={(value) =>
                updateInputValueHandler("phoneNumber", value)
              }
              value={enteredPhoneNumber}
              isInvalid={phoneNumberIsValid}
            />
            {errors?.phoneNumber?.map((message) => (
              <Text style={styles.errorLabel}>{message}</Text>
            ))}
            <Input
              label="Prenume"
              onUpdateValue={(value) =>
                updateInputValueHandler("firstName", value)
              }
              value={enteredFirstName}
              isInvalid={firstNameIsInvalid}
            />
            {errors?.firstName?.map((message) => (
              <Text style={styles.errorLabel}>{message}</Text>
            ))}
            <Input
              label="Nume"
              onUpdateValue={(value) =>
                updateInputValueHandler("lastName", value)
              }
              value={enteredLastName}
              isInvalid={lastNameIsInvalid}
            />
            {errors?.lastName?.map((message) => (
              <Text style={styles.errorLabel}>{message}</Text>
            ))}
          </>
        )}
        <View style={styles.buttons}>
          <DECButton
            onPress={submitHandler}
            isLoading={loadingState === "pending"}
            isSuccess={false}
          >
            {isLogin ? "Autentificare" : "Creeaza cont"}
          </DECButton>
        </View>
      </View>
    </View>
  );
}

export default AuthForm;

const styles = StyleSheet.create({
  form: { flex: 1, width: "100%", justifyContent: "center" },
  buttons: {
    marginTop: 12,
  },
  errorLabel: {
    width: "100%",
    textAlign: "center",
    color: Colors.error,
  },
});
