import { Colors } from "./colors";

export const DECDarkTheme = {
  dark: true,
  colors: {
    primary: Colors.accent,
    background: Colors.backgroundPrimary,
    card: Colors.backgroundSecondary,
    text: Colors.darkNeutral,
    border: Colors.primary800,
    notification: Colors.error,
  },
};
