export const quotes = [
  {
    content:
      "Educația este cea mai puternică armă pe care o poți folosi pentru a schimba lumea.",
    author: "Nelson Mandela",
  },
  {
    content:
      "Învățarea este ca și înotul împotriva curentului: de îndată ce încetezi, te duci înapoi.",
    author: "Proverb chinezesc adaptat",
  },
  { content: "Repetiția este mama învățăturii.", author: "Proverb latin" },
  {
    content:
      "Armele nu aduc pacea, ci respectul pentru învățătură și înțelegerea dintre oameni.",
    author: "Anonim",
  },
  {
    content: "În arsenalul minții, educația este cea mai puternică armă.",
    author: "Nelson Mandela, adaptat",
  },
  {
    content:
      "Munițiile adevăratei cunoașteri sunt întrebările, nu răspunsurile.",
    author: "Anonim",
  },

  {
    content: "Fiecare carte este un depozit de muniție pentru lupta ideilor.",
    author: "Anonim",
  },

  {
    content:
      "Învățătura este un proces continuu, la fel ca încărcarea repetată a armelor pentru a fi pregătit în orice moment.",
    author: "Anonim",
  },

  {
    content: "Armele construiesc imperii, dar numai educația le poate menține.",
    author: "Anonim",
  },

  {
    content:
      "Repetiția în învățare este ca poligonul de tragere pentru un soldat: esențială pentru precizie.",
    author: "Anonim",
  },

  {
    content:
      "Adevărata forță nu vine din arme sau muniții, ci din puterea cunoașterii și înțelepciunea aplicării ei.",
    author: "Anonim",
  },
  {
    content:
      "Învățarea este nu o pregătire pentru viață; învățarea este viața însăși.",
    author: "John Dewey",
  },
  {
    content: "O minte pregătită este cea mai bună armă.",
    author: "Proverb anonim",
  },
  {
    content: "Repetiția este cheia memorării.",
    author: "Anonim",
  },
  {
    content: "Cunoașterea este muniția minții, iar cartea este teaca ei.",
    author: "Edward Counsel",
  },
  {
    content:
      "Învățătura fără gândire este muncă pierdută; gândirea fără învățătură este periculoasă.",
    author: "Confucius",
  },
  {
    content: "O viață fără examinare nu merită trăită.",
    author: "Socrate",
  },
  {
    content: "Educația este descoperirea treptată a propriei ignoranțe.",
    author: "Will Durant",
  },
  {
    content:
      "Cea mai mare armă împotriva stresului este capacitatea noastră de a alege un gând peste altul.",
    author: "William James",
  },
  {
    content:
      "Libertatea fără educație este întotdeauna un pericol; educația fără libertate este zadarnică.",
    author: "John F. Kennedy",
  },
  {
    content:
      "Pentru a învăța să știi, să înveți să faci, să înveți să trăiești împreună și să înveți să fii.",
    author: "UNESCO",
  },
  {
    content:
      "Eșecul este doar oportunitatea de a începe din nou, de data aceasta mai înțelept.",
    author: "Henry Ford",
  },
  {
    content:
      "Nu este suficient să ai o minte bună; principalul este să o folosești bine.",
    author: "René Descartes",
  },
  {
    content:
      "Educația este pasaportul spre viitor, căci mâine aparține celor care se pregătesc pentru el astăzi.",
    author: "Malcolm X",
  },
  {
    content: "O carte închisă este un prieten care așteaptă.",
    author: "Proverb anonim",
  },
  {
    content:
      "Învățarea este ca remarea împotriva curentului: dacă nu avansezi, te duci înapoi.",
    author: "Proverb chinezesc",
  },
  {
    content: "Educația este arta de a face pe om etic.",
    author: "Georg Wilhelm Friedrich Hegel",
  },
  {
    content:
      "Omul învățat este cel care știe unde să găsească ceea ce nu știe.",
    author: "Georg Simmel",
  },
  {
    content: "Armele îi protejează pe cei liberi.",
    author: "Proverb anonim",
  },
  {
    content: "Cunoașterea, odată dobândită, nu poate fi niciodată pierdută.",
    author: "Proverb anonim",
  },
  {
    content:
      "Nu există armă mai puternică în arsenalul umanității decât voința.",
    author: "Anonim",
  },
  {
    content:
      "Lumea este o carte, și cei care nu călătoresc citesc doar o pagină.",
    author: "Sfântul Augustin",
  },
  {
    content: "Învață ca și cum ai trăi pentru totdeauna.",
    author: "Mahatma Gandhi",
  },
  {
    content: "Orice om este un discipol în școala vieții.",
    author: "Proverb anonim",
  },
  {
    content:
      "Educația este singura soluție. Educația este prima. Educația este esența progresului.",
    author: "Tom Brokaw",
  },
  {
    content:
      "Înțelepciunea nu este produsul școlarizării, ci al încercării de-a lungul vieții de a o dobândi.",
    author: "Albert Einstein",
  },
  {
    content: "Repetiția este tatăl învățării.",
    author: "Proverb latin",
  },
  {
    content: "Armele distrug dușmanii, educația distruge inamicul din noi.",
    author: "Anonim",
  },
];
