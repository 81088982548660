import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { EmployeeStackParamList } from "@navigation/types";
import { Colors } from "@config/colors";
import SideMenu from "@components/UI/SideMenu/SideMenu";
import { UI } from "@config/constants";
import AccessCodesList from "@components/AccessCodes/AccessCodesList";
import { dummyData } from "@store/dummyData";
import { useGetAccessCodesQuery } from "@services/api/accessCodes/accessCodesApi";
import ErrorNotification from "@Core/UI/components/Feedback/ErrorNotification";
import LoadingOverlay from "@Core/Auth/components/UI/LoadingOverlay";

type Props = NativeStackScreenProps<EmployeeStackParamList, "AccessCodes">;

const AccessCodesScreen: React.FC<Props> = (props) => {
  const { navigation } = props;
  const {
    data: accessCodes,
    isLoading,
    isError,
    isFetching,
  } = useGetAccessCodesQuery();

  if (isError) {
    return <ErrorNotification />;
  }

  if (isLoading || isFetching) {
    return <LoadingOverlay />;
  }

  return (
    <View style={styles.screen}>
      <View style={styles.content}>
        <View style={[styles.GLDR1, styles.borderRed]}>
          <SideMenu />
        </View>
        <View style={[styles.GLDR2, styles.borderRed]}>
          <AccessCodesList accessCodes={accessCodes} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Colors.backgroundPrimary,
  },
  content: {
    margin: UI.sizes.margin / 2,
    flex: 1,
    flexDirection: "row",
    // borderColor: "red", // TODO delete
    // borderWidth: 1, // TODO delete
  },
  borderRed: {
    // borderColor: "red", // TODO delete
    // borderWidth: 1, // TODO delete
  },
  GLDR1: { flex: 1 },
  GLDR2: { flex: 1.618 + 2.618, margin: UI.sizes.margin / 2 },
});

export default AccessCodesScreen;
