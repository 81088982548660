import React, { useState } from "react";
import { View, Text, StyleSheet, Pressable, Image } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { AntDesign, MaterialIcons } from "@expo/vector-icons";

import { EmployeeStackParamList } from "@navigation/types";
import { UI } from "@config/constants";
import { Colors } from "@config/colors";

// type DashboardScreenNavigationProp = NativeStackNavigationProp<
//   EmployeeStackParamList,
//   "Dashboard"
// >;

type IndexGroupsScreenNavigationProp = NativeStackNavigationProp<
  EmployeeStackParamList,
  "GroupsIndex"
>;

type Props = {
  groupId?: number;
};

const fields = ["Nume", "Telefon", "Grupa", "Teste"];

const StudentListHeader: React.FC<Props> = (props) => {
  const { groupId } = props;
  const [isHoveredFields, setIsHoveredFields] = useState(
    new Array(fields.length).fill(false)
  );

  const handleHoverIn = (index: number) => {
    const array = new Array(fields.length).fill(false);
    array[index] = true;
    setIsHoveredFields(array);
  };

  const handleHoverOut = (index: number) => {
    const array = new Array(fields.length).fill(false);
    setIsHoveredFields(array);
  };

  return (
    <View style={styles.component}>
      <View style={styles.content}>
        {fields.map((field, index) => {
          if (groupId && field === "Grupa") return;

          return (
            <Pressable
              style={styles.fieldTextContainer}
              onHoverIn={() => handleHoverIn(index)}
              onHoverOut={() => handleHoverOut(index)}
              key={field}
            >
              <Text
                style={[
                  styles.text,
                  isHoveredFields[index] && styles.highlightedTextL,
                ]}
                key={field}
              >
                {field.charAt(0).toUpperCase() + field.slice(1)}
              </Text>
            </Pressable>
          );
        })}
        <View style={{ width: 18 }}></View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  component: {
    width: "100%",
    backgroundColor: Colors.backgroundPrimary,
    borderTopRightRadius: UI.sizes.borderRadius,
    borderTopLeftRadius: UI.sizes.borderRadius,
    borderColor: "rgba(255, 255, 255, 0.3)",
    borderBottomWidth: 1,
  },
  lastItem: {
    borderBottomRightRadius: UI.sizes.borderRadius,
    borderBottomLeftRadius: UI.sizes.borderRadius,
  },
  content: {
    flex: 1,
    flexDirection: "row",
    // justifyContent: "space-between",
    paddingVertical: UI.sizes.padding,
    marginHorizontal: UI.sizes.margin,
    borderBottomWidth: 1,
    borderColor: "rgba(255, 255, 255, 0.1)",
  },
  centeredContainer: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  fieldTextContainer: {
    flex: 1,
  },
  text: {
    flex: 1,
    color: Colors.darkNeutral,
    textAlignVertical: "center",
    marginRight: UI.sizes.margin,
  },
  highlightedTextL: {
    color: Colors.accent,
  },
});

export default StudentListHeader;
