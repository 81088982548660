import React, { useLayoutEffect, useMemo } from "react";
import { StyleSheet, View, Text } from "react-native";
import { FlashList } from "@shopify/flash-list";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { LearningMediumsStackParamList } from "@navigation/types";

import { useGetStudentCategoryProgressesQuery } from "@services/api/studentCategoryProgresses/studentCategoryProgressesApi";
import { useGetQuestionsQuery } from "@services/api/questions/questionsApi";

import LoadingOverlay from "@Core/Auth/components/UI/LoadingOverlay";
import LearningMediumButton from "./LearningMediumButton";

import { UI } from "@config/constants";
import { Colors } from "@config/colors";
import { useGetQuestionCategoriesQuery } from "@services/api/questionCategories/questionCategoriesApi";

type Props = NativeStackScreenProps<
  LearningMediumsStackParamList,
  "LearningMediumsIndex"
>;

const LearningMediumIndexScreen: React.FC<Props> = (props) => {
  const { navigation } = props;

  const {
    data: studentCategoryProgresses,
    isLoading: isLoadingStudentCategoryProgresses,
    isError: isErrorStudentCategoryProgresses,
  } = useGetStudentCategoryProgressesQuery();

  const {
    data: questionCategories,
    isLoading: isLoadingQuestionCategories,
    isError: isErrorQuestionCategories,
  } = useGetQuestionCategoriesQuery();

  const {
    data: questions,
    isLoading: isLoadingQuestions,
    isError: isErrorQuestions,
  } = useGetQuestionsQuery();

  const isLoading =
    isLoadingStudentCategoryProgresses ||
    isLoadingQuestions ||
    isLoadingQuestionCategories;
  const isError =
    isErrorStudentCategoryProgresses ||
    isErrorQuestions ||
    isErrorQuestionCategories;

  // Memoize processed data
  const processedData = useMemo(() => {
    if (isLoading || isError) return [];

    return studentCategoryProgresses.map((studentCategoryProgress) => {
      const questionsFromCategory = questions.filter(
        (q) => q.category.id === studentCategoryProgress.questionCategoryId
      );

      const correctlyAnsweredQuestions =
        studentCategoryProgress.answeredQuestions.filter(
          (q) => q.answeredCorrectly
        );

      let progress;
      if (questionsFromCategory.length === 0) {
        progress = 0;
      } else {
        progress =
          correctlyAnsweredQuestions.length / questionsFromCategory.length;
      }

      const label = questionCategories.find(
        (questionCategory) =>
          questionCategory.id === studentCategoryProgress.questionCategoryId
      ).name;

      return {
        ...studentCategoryProgress,
        progress,
        label,
        totalQuestionsCount: questionsFromCategory.length,
        correctlyAnsweredQuestionsCount: correctlyAnsweredQuestions.length,
      };
    });
  }, [studentCategoryProgresses, questions]);

  if (isLoading) {
    return <LoadingOverlay />;
  }

  if (isError) {
    return <Text style={styles.errorText}>Failed to load data.</Text>;
  }

  return (
    <View style={styles.screen}>
      <FlashList
        data={processedData}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item }) => (
          <LearningMediumButton
            disabled={item.progress === 1}
            studentCategoryProgressId={item.id}
            progress={item.progress}
            label={item.label}
            totalQuestionsCount={item.totalQuestionsCount}
            correctlyAnsweredQuestionsCount={
              item.correctlyAnsweredQuestionsCount
            }
          />
        )}
        estimatedItemSize={50}
        contentOffset={{ x: 0, y: -32 }}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingTop: UI.sizes.padding }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: Colors.backgroundPrimary,
    // paddingHorizontal: UI.sizes.padding,
  },
  errorText: {
    color: "red",
    textAlign: "center",
    marginTop: 20,
  },
});

export default LearningMediumIndexScreen;
