import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { useAppSelector } from "@store/hooks";
import { isAuthDataValid } from "../Core/Auth/utils/checkAuthDataValidity";
import EmployeeNavigator from "./employee/EmployeeNavigator";
import UnauthenticatedNavigator from "./UnauthenticatedNavigator";
import StudentNavigator from "./student/StudentNavigator";

import { DECDarkTheme } from "@config/themes";

const MainNavigator: React.FC<{}> = (props) => {
  const role = useAppSelector((state) => state.role.role);
  const isAuthenticated = useAppSelector((state) =>
    isAuthDataValid(state.auth.auth)
  );
  const userData = useAppSelector((state) => state.auth.user);
  if (!isAuthenticated) {
    return (
      <NavigationContainer>
        <UnauthenticatedNavigator />
      </NavigationContainer>
    );
  }
  return (
    <NavigationContainer theme={DECDarkTheme}>
      {/* {userData.role === "none" && <NoRoleNavigator />} */}
      {["employee", "pending_employee"].includes(userData.role) ? (
        <EmployeeNavigator />
      ) : (
        <StudentNavigator />
      )}
    </NavigationContainer>
  );
};

export default MainNavigator;
