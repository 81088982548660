// api.ts

import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  ApiResponse,
  AuthRequestBody,
  AuthData,
  AuthenticatedRequestProps,
  RawAuthResponse,
  SmsAuthRequestBody,
  SignupRequestProps,
  VerifyTokenResponse,
} from "../../types";
import { transformKeysToCamelCase } from "../../../utils/transfomObjectKeys";
import { removeStoredAuthData, storeAuthData } from "./authLocal";
import { API_BASE_URL } from "@config/constants";

const AUTH_API_BASE_URL = `${API_BASE_URL}/auth/`;

const refreshTokenRequest = async (refreshToken: string) => {
  const rawResponse = await fetch(AUTH_API_BASE_URL + "refresh_token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ refresh_token: refreshToken }),
  });

  if (!rawResponse.ok) {
    throw new Error("Refresh token request failed");
  }

  const parsedResponse: RawAuthResponse = await rawResponse.json();
  const response: ApiResponse<AuthData> = {
    data: {
      auth: transformKeysToCamelCase(parsedResponse.auth),
      user: transformKeysToCamelCase(parsedResponse.user),
    },
  };

  storeAuthData(response.data);
  return response;
};

const request = async <T>({
  endpoint,
  tokens,
  method = "GET",
  data = null,
}: AuthenticatedRequestProps): Promise<ApiResponse<T>> => {
  const options: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${tokens.token}`,
    },
  };
  if (data) {
    options.body = JSON.stringify(data);
  }

  // try {
  let response = await fetch(API_BASE_URL + endpoint, options);

  if (response.status === 401) {
    // await refreshTokenRequest();
    // Retry the original request with new access token
    options.headers["Authorization"] = `${tokens.token}`;
    response = await fetch(API_BASE_URL + endpoint, options);
  }

  if (!response.ok) {
    const error: ApiResponse<T> = await response.json();
    throw new Error(`Error: ${error.data}`);
  }

  const responseData: any = transformKeysToCamelCase(await response.json());
  return { data: responseData, status: response.status };
  // } catch (error) {
  //   return { error: error.message };
  // }
};

const authenticationRequest = async (
  email: string,
  password: string,
  authMethod: "signup" | "login" = "login",
  firstName?: string,
  lastName?: string,
  phoneNumber?: string,
  role?: string
) => {
  const requestBody: AuthRequestBody = {
    email: email,
    password: password,
    first_name: firstName,
    last_name: lastName,
    phone_number: phoneNumber?.replace(" ", ""),
    role: role,
  };
  const rawResponse = await fetch(AUTH_API_BASE_URL + authMethod, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user: requestBody }),
  });

  if (!rawResponse.ok) {
    const error: ApiResponse<AuthData> = await rawResponse.json();
    console.log(
      'Response NOT OK in "src/Core/Auth/services/auth/authApi.ts"',
      error.errors
    );
    if (error.errors) {
      return error;
    } else {
      throw new Error("Something went wrong");
    }
  }
  const parsedResponse: RawAuthResponse = await rawResponse.json();
  const response: ApiResponse<AuthData> = {
    data: {
      auth: transformKeysToCamelCase(parsedResponse.auth),
      user: transformKeysToCamelCase(parsedResponse.user),
    },
  };

  storeAuthData(response.data);
  return response;
};

const otpAuthenticationRequest = async (
  otpCode: string,
  phoneNumber: string,
  role?: string
) => {
  const requestBody: SmsAuthRequestBody = {
    otp_code: otpCode,
    phone_number: phoneNumber.replace(/\s+/g, ""),
    role: role,
  };
  const rawResponse = await fetch(AUTH_API_BASE_URL + "login_with_sms", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  if (!rawResponse.ok) {
    const error: ApiResponse<AuthData> = await rawResponse.json();
    console.log(
      'Response NOT OK in "src/Core/Auth/services/auth/authApi.ts"',
      error.errors
    );
    if (error.errors && error.errors.length > 0) {
      return error;
    } else {
      throw new Error("Something went wrong");
    }
  }
  const parsedResponse: RawAuthResponse = await rawResponse.json();
  const response: ApiResponse<AuthData> = {
    data: {
      auth: transformKeysToCamelCase(parsedResponse.auth),
      user: transformKeysToCamelCase(parsedResponse.user),
    },
  };

  storeAuthData(response.data);
  return response;
};

const loginRequest = async (email: string, password: string) => {
  return authenticationRequest(email, password);
};

const signupRequest = async (props: SignupRequestProps) => {
  return authenticationRequest(
    props.email,
    props.password,
    "signup",
    props.firstName,
    props.lastName,
    props.phoneNumber,
    props.role
  );
};

const logout = async (token: string) => {
  const response = await fetch(AUTH_API_BASE_URL + "logout", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  if (!response.ok) {
    throw new Error("Something has happened");
  }

  removeStoredAuthData();
  return true;
};

const verifyToken = async ({
  token,
  phoneNumber,
  email,
}: {
  token: string;
  phoneNumber: string;
  email: string;
}) => {
  const response = await fetch(AUTH_API_BASE_URL + "verify_token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      token: token,
      phone_number: phoneNumber,
      email: email,
    }),
  });

  if (!response.ok) {
    throw new Error("Something has happened");
  }

  const responseData: VerifyTokenResponse = await response.json();

  return responseData.valid;
};

const authApi = {
  request,
  loginRequest,
  otpAuthenticationRequest,
  signupRequest,
  refreshTokenRequest,
  logout,
  verifyToken,
};
export default authApi;
