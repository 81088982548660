import { Colors } from "@config/colors";
import { EmployeeStackParamList } from "@navigation/types";
import { useNavigation } from "@react-navigation/native";
import {
  NativeStackNavigationProp,
  NativeStackScreenProps,
} from "@react-navigation/native-stack";
import React, { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  Image,
  TextStyle,
} from "react-native";

type DashboardScreenNavigationProp = NativeStackNavigationProp<
  EmployeeStackParamList,
  "Home"
>;
type Props = {
  navigation?: DashboardScreenNavigationProp;
  onPress: () => void;
  text: string;
};

const NewFormLink: React.FC<Props> = ({ onPress, text }) => {
  const [isHovered, setIsHovered] = useState(false);

  const dynamicStyleText: TextStyle = {
    textDecorationLine: isHovered ? "underline" : "none",
  };

  return (
    <Pressable
      onPress={onPress}
      style={styles.container}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
    >
      <Image
        source={require("@assets/icons/plus.png")}
        style={{ width: 20, height: 20 }}
      />
      <Text style={[styles.addButtonText, dynamicStyleText]}>{text}</Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "flex-end",
    flexDirection: "row",
  },
  addButtonText: {
    fontSize: 16,
    color: Colors.darkNeutral,
    opacity: 0.6,
    marginLeft: 4,
  },
});

export default NewFormLink;
