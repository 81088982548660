import shuffle from "@Core/utils/shuffleArray";
import AnswerButton from "@components/Test/AnswerButton";
import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import { Question } from "@services/api/questions/types";
import React, { useMemo } from "react";
import { View, Text, StyleSheet, ScrollView } from "react-native";

type Props = {
  question: Question;
  questionIndex: number | null;
  currentAnswerChoice: number;
  showCurrentAnswer: boolean;
  onAnswerPress: (id: number) => void;
};

const QuestionAnswersForm: React.FC<Props> = (props) => {
  const { question, questionIndex, currentAnswerChoice, showCurrentAnswer } =
    props;

  console.log("question.answers: ", question.answers);
  console.log("shuffle(question.answers): ", shuffle(question.answers));

  const shuffledAnswers = useMemo(() => shuffle(question.answers), [question]);

  return (
    <ScrollView
      style={styles.content}
      contentContainerStyle={{ paddingBottom: 80 }}
    >
      <View style={styles.questionContainer}>
        <View style={styles.questionCard}>
          <Text style={styles.questionText}>
            {typeof questionIndex !== "undefined" && `${questionIndex + 1}. `}
            {question.prompt}
          </Text>
        </View>
      </View>
      <View style={styles.answersContainer}>
        {shuffledAnswers.map((answer, index) => (
          <AnswerButton
            id={answer.id}
            key={answer.id}
            body={answer.body}
            isCorrect={answer.isCorrect}
            index={index}
            isSelected={currentAnswerChoice === answer.id}
            isShowingIfCorrect={
              showCurrentAnswer &&
              (currentAnswerChoice === answer.id ||
                (answer.isCorrect && !!currentAnswerChoice))
            }
            onPress={props.onAnswerPress}
          />
        ))}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    padding: UI.sizes.padding,
    width: "100%",
    maxWidth: 1290,
  },
  questionContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  questionCard: {
    width: "100%",
    borderRadius: UI.sizes.borderRadius,
    padding: UI.sizes.padding,
    backgroundColor: Colors.accent,
    // marginTop: UI.sizes.margin * 4,
    marginBottom: UI.sizes.margin * 4,
    shadowColor: "black",
    shadowOffset: UI.sizes.shadowOffset,
    shadowOpacity: 0.2,
    shadowRadius: UI.sizes.borderRadius,
  },
  questionText: {
    color: Colors.backgroundSecondary,
  },
  answersContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
});

export default QuestionAnswersForm;
