import LoadingOverlay from "@Core/Auth/components/UI/LoadingOverlay";
import { Colors } from "@config/colors";
import { UI } from "@config/constants";
import { useGetQuestionCategoriesQuery } from "@services/api/questionCategories/questionCategoriesApi";
import { Question } from "@services/api/questions/types";
import React from "react";
import { View, Text, StyleSheet } from "react-native";

type Props = {
  question: Question;
  isVisible: boolean;
};

const QuestionListItemBody: React.FC<Props> = ({ question, isVisible }) => {
  const {
    data: questionCategories,
    isLoading,
    isError,
  } = useGetQuestionCategoriesQuery();

  if (isLoading || isError) {
    return <></>;
  }

  return (
    <>
      <View style={styles.container}>
        <View style={styles.categoryContainer}>
          <Text style={[styles.boldText, styles.borderBottom]}>
            Categoria:{" "}
            <Text style={styles.lightText}>{question.category.label}</Text>
          </Text>
        </View>

        {/* <Text style={styles.boldText}>Raspunsuri: </Text> */}
        {question.answers
          ?.toSorted((a, b) => (b.isCorrect ? 1 : 0) - (a.isCorrect ? 1 : 0))
          .map((answer) => (
            <Text
              key={answer.id}
              style={[
                styles.answer,
                answer.isCorrect && { borderLeftColor: Colors.success },
              ]}
            >
              {answer.body}
            </Text>
          ))}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    marginLeft: UI.sizes.margin,
    paddingLeft: UI.sizes.padding,
    marginTop: 10,
    borderLeftWidth: 1,
    borderLeftColor: "rgba(255, 255, 255, 0.3)",
  },
  categoryContainer: {
    flexDirection: "row",
    width: "100%",
    padding: UI.sizes.padding / 2,
    marginBottom: UI.sizes.margin,
  },
  boldText: {
    color: Colors.darkNeutral,
    fontWeight: UI.font.weight.extraBold,
  },
  lightText: {
    color: Colors.darkNeutral,
    fontWeight: UI.font.weight.light,
  },
  answer: {
    flex: 1,
    textAlignVertical: "center",
    fontSize: 16,
    borderLeftWidth: 4,
    borderColor: Colors.darkNeutral,
    color: Colors.darkNeutral,
    paddingLeft: 6,
    marginBottom: (10 * 2.618) / 1.618,
    flexWrap: "wrap",
  },
  borderBottom: {
    paddingBottom: UI.sizes.padding,
    borderBottomColor: "rgba(255, 255, 255, 0.3)",
    borderBottomWidth: 1,
  },
});

export default QuestionListItemBody;
