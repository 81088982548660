import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { UI } from "@config/constants";
import { Colors } from "@config/colors";

type Props = {
  passed: number;
  failed: number;
};

const StudentTestsCount: React.FC<Props> = ({ passed, failed }) => (
  <Text style={styles.slashtext}>
    <Text style={[{ color: Colors.success }, styles.bold]}>{passed}</Text>
    {" / "}
    <Text style={[{ color: Colors.error }, styles.bold]}>{failed}</Text>
  </Text>
);

const styles = StyleSheet.create({
  bold: {
    fontWeight: UI.font.weight.bold,
  },
  slashtext: {
    fontWeight: UI.font.weight.extraLight,
    color: Colors.darkNeutral,
  },
});

export default StudentTestsCount;
